import { useState, useEffect, useRef } from "react";
import {
  Dialog,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";
import { CustomSelect } from "../ui";
import APIS from "../../Pages/APIS";
import { useAuthContext } from "../AuthContextProvider";

const ApproveUserDialog = ({ selectedUser, onSuccess }) => {
  const initialData = {
    accountId: "",
    role: "",
  };
  const menuItems = [
    { value: "admin", label: "Admin" },
    { value: "user", label: "User" },
  ];
  const { hideModal } = useGlobalModalContext();
  const [formData, setFormData] = useState(initialData);
  const [formError, setFormError] = useState({
    accountId: "",
    role: "",
  });
  const [status, setStatus] = useState({
    isLoading: false,
    isFormChanged: false,
  });
  const [companys, setCompanys] = useState([]);
  const { token } = useAuthContext();
  const isInitialMount = useRef(true);

  useEffect(() => {
    const hasChanged = Object.keys(formData).some(
      (key) => formData[key] !== initialData[key]
    );
    setStatus({ ...status, isFormChanged: hasChanged });
  }, [formData]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchCompany();
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const fetchCompany = async () => {
    try {
      const response = await APIS.getMyAccountNameList(token);
      const data = response.data?.map((element) => ({
        value: element.id,
        label: element.account_name,
      }));
      setCompanys(data);
    } catch (error) {
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      setStatus({ ...status, isLoading: true });
      const response = await APIS.changeUserStatus(
        { ...formData, email: selectedUser.email, status: "Active" },
        token
      );
      if (response.code == 200) {
        hideModal();
        onSuccess();
      }
      setStatus({ ...status, isLoading: false });
    } else {
      setFormError(newErrors);
    }

    // hideModal();
    // onSuccess();
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.role) {
      newErrors.role = "This field is required.";
    }

    if (formData.role != "admin" && !formData.accountId) {
      newErrors.accountId = "This field is required.";
    }

    return newErrors;
  };
  return (
    <Dialog open onClose={hideModal} maxWidth="sx">
      <Grid p={3}>
        <form onSubmit={handleSubmit} noValidate>
          <Typography variant="h5">Approve User</Typography>
          <Grid container spacing={2} sx={12} mt={1}>
            <Grid item xs={12}>
              <CustomSelect
                fullWidth
                size={"small"}
                label="Role"
                required
                id={"role"}
                value={formData.role}
                options={menuItems}
                name={"role"}
                error={!!formError.role}
                placeholder={"Role"}
                helperText={formError.role}
                onChange={handleChange}
              />
            </Grid>

            {formData.role != "admin" && (
              <Grid item xs={12}>
                <CustomSelect
                  fullWidth
                  size={"small"}
                  label="Company"
                  required
                  id={"accountId"}
                  name={"accountId"}
                  value={formData.accountId}
                  options={companys}
                  onChange={handleChange}
                  error={!!formError.accountId}
                  placeholder={"Company"}
                  helperText={formError.accountId}
                />
              </Grid>
            )}

            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                <Button fullWidth onClick={hideModal} variant="contained">
                  CANCEL
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={!!!status.isFormChanged || status.isLoading}
                >
                  {status.isLoading ? <CircularProgress /> : "CONFIRM"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Dialog>
  );
};

export default ApproveUserDialog;
