import CustomTable from "../ui/Table/CustomTable";
import { formatDate } from "../../helper/util";
import { Tooltip } from "@mui/material";

const UserRoleUserTable = ({
  count,
  data,
  rowPerPage,
  page,
  handlePageChange,
  handleChangeRowsPerPage,
}) => {
  const getStatusDot = (status) => {
    const dotStyle = {
      marginRight: "5px",
    };

    if (status?.toLocaleLowerCase() === "active") {
      return <span style={{ ...dotStyle, color: "green" }}>●</span>;
    } else if (status?.toLocaleLowerCase() === "inactive") {
      return <span style={{ ...dotStyle, color: "yellow" }}>●</span>;
    } else if (status?.toLocaleLowerCase() === "expired") {
      return <span style={{ ...dotStyle, color: "red" }}>●</span>;
    }
  };
  const columns = [
    {
      name: "No.",
      id: "index",
    },
    {
      name: "Package Name",
      id: "package_name",
    },
    {
      name: "Duration",
      id: "duration", // calculate
      element: (row) => (row.duration ? row.duration + " Day(s)" : ""),
    },
    {
      name: "Start Date",
      id: "package_start_date",
      element: (row) => `${formatDate(row.package_start_date?.value)}`,
    },
    {
      name: "Expiry Date",
      id: "package_end_date",
      element: (row) => `${formatDate(row.package_end_date?.value)}`,
    },
    {
      name: "Number of Audience per push",
      id: "audience_size_per_export",
    },
    {
      name: "Export Count",
      id: "export_actual",
    },

    {
      name: "Actual Usage",
      id: "export_utilized",
    },
    {
      name: "Quota Left",
      id: "export_remaining",
    },
    {
      name: "Status",
      id: "status",
      element: (row) => (
        <>
          {getStatusDot(row.status)}
          {`${row.status}`}
        </>
      ),
    },
  ];

  return (
    <CustomTable
      columns={columns}
      data={data}
      count={count}
      rowPerPage={rowPerPage}
      page={page}
      handlePageChange={handlePageChange}
      handleRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default UserRoleUserTable;
