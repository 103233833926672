import React, { useState, createContext, useContext } from "react";

const initalState = {
  registerData: {},
  forgotPasswordData: {},
};

const RegisterContext = createContext(initalState);

export const RegisterProvider = ({ children }) => {
  const [registerData, setRegisterData] = useState({});
  const [forgotPasswordData, setForgotPasswordData] = useState({});
  return (
    <RegisterContext.Provider
      value={{
        registerData,
        setRegisterData,
        forgotPasswordData,
        setForgotPasswordData,
      }}
    >
      {/* {renderComponent()} */}
      {children}
    </RegisterContext.Provider>
  );
};

export const useRegisterContext = () => useContext(RegisterContext);
