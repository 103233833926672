/** PostData = (url = ``, data = {}) - method is used to send JSON data with post method to server */
const PostData = (url = ``, data = {}, token) => {
      const headers = {
            'Content-Type': "application/json; charset=utf-8"
        };
    
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

    return fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          headers: headers,
          dataType: "json",
          body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
          .then(response => response.json()) // parses response to JSON
          .catch(error => console.error(`Fetch Error =\n`, error));
};

export default PostData;