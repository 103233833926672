// export const mobileRegx = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const mobileRegx = /^(0[2689]{1})+([0-9]{7,8})+$/;

export const emailRegx =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const mobileAndEmailRegx = /^(\d{10}|\S+@\S+\.\S+)$/;

export const passwordRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/;

export const validStringRegex = /^[a-zA-Z._-]{1,255}$/;

//export const incompleteUrlRegex = /^[a-z_-]+\.[a-z_-]+$/;

export const incompleteUrlRegex = /^[a-z_-]+(\.[a-z_-]+){1,2}$/;

export const doesNotStartWithRegex = /^(?!www\.|http:\/\/|https:\/\/|http:\/\/ftp:\/\/|http:\/\/file:\/\/|mailto:|tel:).*/;

export const noDecimal = /^\d*$/;

export const decimalWith15digits = /^-?\d+(\.\d{1,15})?$/;

export const nameRegEx = /^[a-zA-Z\u0E00-\u0E7F\u00C0-\u017F'’\s-]+$/;


