import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useGlobalModalContext } from "./GlobalModalContext/GlobalModalContext";

const DeleteAudienceDialog = ({ id, onSuccess }) => {
  const { hideModal, hideAllModal } = useGlobalModalContext();
  const [acccount, setAccount] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    hideModal();
    onSuccess();
  };
  return (
    <Dialog
      open
      onClose={hideModal}
      className="modal-delete"
      sx={{ padding: "32px, 24px, 24px, 24px" }}
    >
      <DialogTitle className="modal-lg-text">
        Do you want to delete this Audience?
      </DialogTitle>
      <DialogContent className="modal-sm-text">
        Deleting this audience segment will remove it permanently from your
        list. This action cannot be undone
      </DialogContent>

      <DialogActions>
        <Button onClick={hideModal} sx={{ fontSize: "12px" }}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{ color: "#FFFFFF", backgroundColor: "#D92D20 !important" }}
        >
          Delete Audience
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAudienceDialog;
