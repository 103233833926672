import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import { Search, CalendarTodayOutlined } from "@mui/icons-material";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import APIS from "./APIS";
import { useAuthContext } from "../Components/AuthContextProvider";
import AccountTable from "../Components/MyAccount/AccountTable";
import AccountDialog from "../Components/MyAccount/AccountDialog";
import { useGlobalModalContext } from "../Components/GlobalModalContext/GlobalModalContext";
import ReactDatePicker from "react-datepicker";

const MyAccountList = () => {
  const { showModal } = useGlobalModalContext();
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 10,
    totalCount: 0,
  });
  const [query, setQuery] = useState({
    accountName: "",
    // startDate: "",
    // endDate: "",
  });
  const [myAccountData, setMyAccountData] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const { token } = useAuthContext();
  const isInitialMount = useRef(true);

  useEffect(() => {
    // Fetch data from API endpoint
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchMyAccountData();
    }
  }, [pagination.page, pagination.limit]);

  const fetchMyAccountData = async () => {
    try {
      setLoading(true);
      const urlParams = new URLSearchParams({
        ...query,
        page: pagination.page + 1,
        limit: pagination.limit,
      });
      const queryString = urlParams.toString();
      const response = await APIS.getMyAccountList(token, queryString);
      response.data?.forEach((element, index) => {
        element["index"] = pagination.page * pagination.limit + (index + 1);
      });
      setMyAccountData(response.data);
      setPagination({ ...pagination, totalCount: response.totalCount });
      setLoading(false); // Set loading to false after fetching data
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      limit: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleDateChange = (dateRange) => {
    setQuery({ ...query, startDate: dateRange[0], endDate: dateRange[1] });
  };

  return (
    <Grid item>
      <Grid className="search-card" mt={2}>
        <Box
          sx={{
            width: "60%",
            display: "inline-flex",
          }}
          columnGap={1}
        >
          <TextField
            fullWidth
            value={query.accountName}
            onChange={(e) => {
              setQuery({ ...query, accountName: e.target.value });
            }}
            sx={{ width: "40%" }}
            size="small"
            placeholder="Search by company name"
            InputProps={{
              startAdornment: <Search />,
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              if (pagination.page > 0) {
                setPagination({ ...pagination, page: 0 });
              } else {
                fetchMyAccountData();
              }
            }}
          >
            Search
          </Button>
        </Box>
        <Box
          sx={{
            width: "40%",
            display: "inline-flex",
          }}
        >
          <Button
            variant="contained"
            onClick={() =>
              showModal(<AccountDialog onSuccess={fetchMyAccountData} />)
            }
          >
            CREATE NEW COMPANY
          </Button>
        </Box>
      </Grid>

      <AccountTable
        data={myAccountData}
        count={pagination.totalCount}
        rowPerPage={pagination.limit}
        page={pagination.page}
        handlePageChange={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        onSuccess={fetchMyAccountData}
        loading={loading}
      />
    </Grid>
  );
};

export default MyAccountList;
