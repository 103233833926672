import React, { useEffect, useState } from "react";
import AppLogo from "../Components/AppLogo";
import DOMPurify from "dompurify";
import SwitchLangauage from "../Components/SwitchLangauage";
import useLanguage from "../helper/customhook";

function About() {
  const [lng, changeLanguage] = useLanguage();
  const [about, setAbout] = useState({});

  useEffect(() => {
    getContent();
  }, [lng]);

  const getContent = async () => {
    fetch(`/content/${lng}/about.json`)
      .then((response) => response.json())
      .then((jsonData) => setAbout(jsonData))
      .catch((error) => console.error("Error fetching the JSON data:", error));
  };

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
    <div className="login-card privacy-card">
      <AppLogo />
      <SwitchLangauage currentLang={lng} onLanguageChange={changeLanguage} />
      <div className="font-md">
        <h2 dangerouslySetInnerHTML={createMarkup(about.title)} />
        {about?.paragraphs?.map((paragraph, index) => (
          <div key={`paragraph-${index}`}>
            <div dangerouslySetInnerHTML={createMarkup(paragraph)} />
            <br />
          </div>
        ))}
        {about?.subcontents?.map((content, contentIndex) => (
          <div key={`content-${contentIndex}`}>
            <h3 dangerouslySetInnerHTML={createMarkup(content.title)} />
            {content?.paragraphs?.map((paragraph, paragraphIndex) => (
              <div key={`content-${contentIndex}-paragraph-${paragraphIndex}`}>
                <div dangerouslySetInnerHTML={createMarkup(paragraph)} />
                <br />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default About;
