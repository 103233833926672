import { FormControl, FormLabel, TextField, Grid } from "@mui/material";
import { Error } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

const CustomInputField = ({
  error,
  required,
  label,
  id,
  name,
  type = "text",
  onChange,
  size = "small",
  placeholder,
  value,
  fullWidth = true,
  helperText,
  className,
  disabled = false,
  autoFocus = false,
  FormHelperTextProps,
  color,
  inputProps,
  variant = "outlined",
  onClickMouseDownPassword,
  onBlur,
  onKeyDown,
  maxLength,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (maxLength && value.length > maxLength) {
      return;
    }
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      size={size}
      required={required}
      error={error}
      className={className}
    >
      <FormLabel style={{ fontSize: "14px" }}>
        {error && <Error fontSize="14px" style={{ marginBottom: "-2px" }} />}
        {label}
      </FormLabel>

      <TextField
        onBlur={onBlur}
        disabled={disabled}
        required={required}
        error={error}
        type={showPassword ? "text" : type}
        id={id}
        color={color}
        name={name}
        value={value}
        onChange={handleChange}
        size={size}
        autoFocus={autoFocus}
        placeholder={placeholder}
        variant={variant}
        inputProps={{
          ...inputProps,
          maxLength,
        }}
        FormHelperTextProps={{ error, ...FormHelperTextProps }}
        helperText={helperText}
        onKeyDown={onKeyDown}
        re
        InputProps={
          type === "password"
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={onClickMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                size: "small",
              }
            : {}
        }
      />
    </FormControl>
  );
};

export default CustomInputField;
