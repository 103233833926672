import CustomTable from "../ui/Table/CustomTable";
import { formatDate } from "../../helper/util";
import { useState } from "react";
import { Button, Menu, MenuItem, Link } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import AccountDialog from "./AccountDialog";
import AssignPackageDialog from "./AssignPackageDialog";
import PackageMappingDialog from "./PackageMappingDialog";
import PackageSummaryDialog from "./PackageSummaryDialog";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";

const AccountTable = ({
  count,
  data,
  rowPerPage,
  page,
  handlePageChange,
  handleChangeRowsPerPage,
  onSuccess,
  loading,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { showModal } = useGlobalModalContext();
  const [selectedRow, setSelectedRow] = useState("");

  const columns = [
    {
      name: "No.",
      id: "index",
      minWidth: "100",
    },
    {
      name: "Company Name",
      id: "account_name",
      element: (row) => (
        <Link
          href="#"
          onClick={() =>
            showModal(<PackageSummaryDialog selectedAccount={row} />)
          }
        >
          {row.account_name}
        </Link>
      ),
      minWidth: 300,
    },
    {
      name: "Contact Name",
      id: "contact_name",
    },
    {
      name: "Contact Email",
      id: "contact_email",
    },
    {
      name: "Contact Number",
      id: "contact_mobile_no",
    },
    {
      name: "Added Date",
      id: "created_at",
      element: (row) => `${formatDate(row.created_at)}`,
    },
    {
      id: "id",
      minWidth: 50,
      width: 50,
      element: (row) => {
        const buttonId = "basic-button";
        const menuId = "basic-menu";
        const open = Boolean(anchorEl);
        return (
          <>
            <Button
              id={buttonId}
              aria-describedby={buttonId}
              aria-controls={open ? menuId : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setSelectedRow(row);
              }}
            >
              <MoreVert />
            </Button>

            <Menu
              id={menuId}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": buttonId,
              }}
            >
              <MenuItem
                onClick={() =>
                  showModal(
                    <AccountDialog
                      onSuccess={() => {
                        onSuccess();
                        setAnchorEl(null);
                      }}
                      selectedAccount={selectedRow}
                    />
                  )
                }
              >
                Rename Company
              </MenuItem>
              <MenuItem
                onClick={() =>
                  showModal(
                    <AssignPackageDialog
                      onSuccess={() => {
                        onSuccess();
                        setAnchorEl(null);
                      }}
                      selectedAccount={selectedRow}
                    />
                  )
                }
              >
                Assign Package
              </MenuItem>
              <MenuItem
                onClick={() =>
                  showModal(
                    <PackageMappingDialog selectedAccount={selectedRow} />
                  )
                }
              >
                Package History
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];
  return (
    <CustomTable
      columns={columns}
      data={data}
      count={count}
      rowPerPage={rowPerPage}
      page={page}
      isLoading={loading}
      handlePageChange={handlePageChange}
      handleRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default AccountTable;
