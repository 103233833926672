import { Grid, Box } from "@mui/material";
import Navbar from "../Components/navbar";
import Sidebar from "../Components/sidebar";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { List, ListItem, Snackbar, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import APIS from "./APIS";
import CustomInputField from "../Components/ui/CustomInputField";
import { Close } from "@mui/icons-material";
import { useAuthContext } from "../Components/AuthContextProvider";
import GlobalLoading from "./GlobalLoading";

const ChangePassword = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const { email, token } = useAuthContext();
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
    email: email,
  });
  const navigate = useNavigate();
  const [globalLoading, setGlobalLoading] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setPasswordData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    setValidationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleContinue = async (e) => {
    // Reset validation errors
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length != 0) {
      setValidationErrors(errors);
      return;
    }

    const formData = {
      email: passwordData.email,
      oldPassword: passwordData.oldPassword,
      newPassword: passwordData.password,
    };
    try {
      setGlobalLoading(true);
      const response = await APIS.ChangePassword(formData, token);
      if (response.code == 200) {
        setGlobalLoading(false);
        setErrorSnackbar(response.message);
        setTimeout(() => {
          navigate("/MyAccount", { state: response.message });
        }, 3000);
      } else if (response.code == "403") {
        setGlobalLoading(false);
        setValidationErrors({
          ...validationErrors,
          oldPassword: "The old password you entered is incorrect.",
        });
      } else if (response.code == "409") {
        setGlobalLoading(false);
        setValidationErrors({
          ...validationErrors,
          password: "The password you entered is duplicate.",
        });
      } else {
        setGlobalLoading(false);
        setErrorSnackbar(response.message || "Something went wrong!");
      }
    } catch (error) {
      setGlobalLoading(false);
      console.error("Error during login:", error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const complexityRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/;

    if (!passwordData.oldPassword) {
      newErrors.oldPassword = "Old Password is required.";
    }
    if (!passwordData.password) {
      newErrors.password = "New Password is required.";
    } else if (
      !complexityRegex.test(passwordData.password) ||
      passwordData.password.length < 8
    ) {
      newErrors.password =
        "Password must contain at least 1 alphabet, 1 digit, and 1 special character.";
    }

    if (!passwordData.confirmPassword) {
      newErrors.confirmPassword = "Confirm New Password is required.";
    } else if (passwordData.password !== passwordData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }
    return newErrors;
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="inner-wrapper">
      {globalLoading && <GlobalLoading />}
      <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "10000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <Grid container>
        <Grid item xs={12}>
          <div className="inner-wrapper">
            <Grid container>
              <Grid item xs={12}>
                <Navbar />
              </Grid>
              <Grid item xs={1}>
                <Sidebar />
              </Grid>
              <Grid item xs={11}>
                <div className="content-wrapper">
                  <Grid
                    container
                    mt={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <h2>Change Password</h2>

                      <div className="sub-title">
                        To change password by user
                      </div>
                    </Grid>

                    <Grid item columnGap={2} display={"flex"}>
                      <Button
                        onClick={() => {
                          navigate("/MyAccount");
                        }}
                      >
                        CANCEL
                      </Button>

                      <Button variant="contained" onClick={handleContinue}>
                        UPDATE PASSWORD
                      </Button>
                    </Grid>
                  </Grid>
                  <div className="change-password-container">
                    <Box
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { m: 1, width: "50ch" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <div>
                        <CustomInputField
                          label="Old Password"
                          placeholder="Enter old password"
                          name="oldPassword"
                          type="password"
                          required
                          onChange={handleChange}
                          error={!!validationErrors.oldPassword}
                          helperText={validationErrors.oldPassword}
                          onClickMouseDownPassword={handleMouseDownPassword}
                        />
                        <CustomInputField
                          label="New Password"
                          placeholder="Enter new password"
                          name="password"
                          type="password"
                          required
                          onChange={handleChange}
                          error={!!validationErrors.password}
                          helperText={validationErrors.password}
                          onClickMouseDownPassword={handleMouseDownPassword}
                        />
                        <div className="info-sec">
                          Please make sure your password contains at least 1
                          character from all of the following
                          <List
                            sx={{
                              listStyleType: "disc",
                              listStylePosition: "inside",
                              p: 0,
                              m: 0,
                            }}
                          >
                            <ListItem sx={{ display: "list-item" }}>
                              Alphabet
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                              Digit
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                              Lower and upper case
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                              Special character
                            </ListItem>
                          </List>
                          <div className="info-sec">Must be at least 8 characters</div>
                        </div>
                        <div>
                          <CustomInputField
                            label="Confirm New Password"
                            placeholder="Enter confirm new password"
                            name="confirmPassword"
                            required
                            type="password"
                            onChange={handleChange}
                            error={!!validationErrors.confirmPassword}
                            helperText={validationErrors.confirmPassword}
                            onClickMouseDownPassword={handleMouseDownPassword}
                          />
                        </div>
                      </div>
                    </Box>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChangePassword;
