import React from "react";
import { Grid, IconButton } from "@mui/material";

const LanguageSwitcher = ({ currentLang, onLanguageChange }) => {
  const changeLanguage = (language) => {
    onLanguageChange(language);
  };

  return (
    <Grid container className="language label-with-tooltip" ml={3}>
      <Grid item>
        <IconButton
          size="small"
          onClick={() => changeLanguage("en")}
          sx={{
            margin: "0px!important",
            color: currentLang === "en" ? "red" : "inherit",
            fontSize: "14px",
          }}
        >
          <b>EN</b>
        </IconButton>
      </Grid>
      <div className="font-md c-black">
        <b>|</b>
      </div>
      <Grid item>
        <IconButton
          size="small"
          onClick={() => changeLanguage("th")}
          sx={{
            color: currentLang === "th" ? "red" : "inherit",
            fontSize: "14px",
          }}
        >
          <b>TH</b>
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default LanguageSwitcher;
