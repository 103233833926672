import { useEffect, useState } from "react";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Button, Menu, MenuItem, Box } from "@mui/material";
import { Close, MoreVert } from "@mui/icons-material";
import APIS from "../../Pages/APIS";
import { formatDate, getStatusStyle } from "../../helper/util";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";
import DeactivatePackageDialog from "./DeactivatePackageDialog";
import CustomTable from "../ui/Table/CustomTable";

const TopupPackageDialog = ({ selectedAudience }) => {
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const [rowPerPage, setRowsPerPage] = useState(10);
  const { showModal } = useGlobalModalContext();
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const [topupData, setTopupData] = useState({});
  const { hideModal } = useGlobalModalContext();
  const [rowData, setDataRow] = useState("");
  useEffect(() => {
    getTopupPackages();
  }, []);

  const getTopupPackages = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const data = {
        status: "success",
        topupPackages: [
          {
            name: "Test Topup Package 2",
            description: "This is the test topup package 2",
            price: "12 THB",
            duration: 10,
            export_count: 2,
            create_date: {
              value: "2024-05-31T10:01:55.758368",
            },
            id: "7a0de6fc-6251-4175-8179-680ba747c32c",
            status: "Active",
          },
          {
            name: "Test Topup Package 1",
            description: "This is the test topup package 1",
            price: "12 THB",
            duration: 10,
            export_count: 2,
            create_date: {
              value: "2024-05-31T09:59:58.181257",
            },
            id: "85ceb2e7-ff8d-467b-9b43-02cab0350fba",
            status: "Active",
          },
        ],
      };

      setTopupData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw new Error("Fetching Audience Push History Error", error);
    }
  };

  const columns = [
    {
      name: "ID",
      id: "id",
      minWidth: "100",
    },
    {
      name: "Package Name",
      id: "name",
      minWidth: "100",
    },
    {
      name: "Description",
      id: "description",
    },
    {
      name: "price",
      id: "price",
    },
    {
      name: "duration",
      id: "duration",
    },
    {
      name: "Export Count",
      id: "export_count",
    },
    {
      name: "Create Date",
      id: "create_date",
      element: (row) => `${formatDate(row.create_date?.value)}`,
    },
    {
      name: "Status",
      id: "status",
      element: (row) => getStatusStyle(row.status),
    },
    {
      id: "id",
      minWidth: 50,
      width: 50,
      element: (row) => {
        const buttonId = "basic-button";
        const menuId = "basic-menu";
        const open = Boolean(anchorEl);
        return (
          <>
            <Button
              id={buttonId}
              aria-controls={open ? menuId : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setDataRow(row);
              }}
            >
              <MoreVert />
            </Button>

            <Menu
              id={menuId}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": buttonId,
              }}
            >
              <MenuItem
                onClick={() =>
                  showModal(
                    <DeactivatePackageDialog
                      onSuccess={() => {
                        handleDeactivatePackage(row.id);
                        setAnchorEl(null);
                      }}
                      title="Deactivate this topup package?"
                      description="Are you sure you want to Deactivate this topup package?"
                    />
                  )
                }
              >
                Deactivate Package
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeactivatePackage = async (audId) => {
    setErrorSnackbar("to be implemented");
  };

  return (
    <Dialog
      open
      maxWidth={"lg"}
      onClose={hideModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid display={"grid"} margin={3} rowGap={2}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {selectedAudience?.audienceName ?? ""}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={hideModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        {loading ? ( // Show loader if loading is true
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <CustomTable
            columns={columns}
            data={topupData?.topupPackages || []}
            count={topupData?.topupPackages?.length || 0}
            rowPerPage={rowPerPage}
            page={page}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Grid>
    </Dialog>
  );
};

export default TopupPackageDialog;
