import React, { useState, createContext, useContext } from "react";

const initalState = {
  showModal: (modal) => {
    return;
  },
  hideModal: () => {
    return;
  },
  hideAllModal: () => {
    return;
  },
  store: {},
};

const GlobalModalContext = createContext(initalState);

export const GlobalModalProvider = ({ children }) => {
  const [store, setStore] = useState([]);

  const showModal = (modal) => {
    setStore((store) => [...store, modal]);
    document.body.classList.add("overflow-hidden");
  };

  const hideModal = () => {
    const newStore = store.slice();
    newStore.pop();
    setStore((store) => [...store].slice(0, -1));
    document.body.classList.remove("overflow-hidden");
  };

  const hideAllModal = () => {
    const newStore = store.slice();
    newStore.pop();
    setStore(() => []);
    document.body.classList.remove("overflow-hidden");
  };

  const renderComponent = () => {
    if (!store.length) return;

    return (
      <>
        {store.map((item, idx) => {
          return <React.Fragment key={idx}>{item}</React.Fragment>;
        })}
      </>
    );
  };

  return (
    <GlobalModalContext.Provider
      value={{ store, showModal, hideModal, hideAllModal }}
    >
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};

export const useGlobalModalContext = () => useContext(GlobalModalContext);
