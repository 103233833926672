// ProtectedRoute.js
import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAuthContext } from "./AuthContextProvider";
import { useGlobalModalContext } from "./GlobalModalContext/GlobalModalContext";
import { debounce } from "../helper/util";

const ProtectedRoute = ({ roles }) => {
  let { role, logout } = useAuthContext();
  const { hideAllModal } = useGlobalModalContext();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    let lastActivityTime = localStorage.getItem("lastActivityTime");
    const currentTime = new Date().getTime();
    const visitingTime = currentTime - lastActivityTime;
    const expireInTime = 30 * 60000;
    const events = ["keydown", "scroll", "click"];

    const logoutAndRedirect = () => {
      logout();
      hideAllModal();
    };

    if (!token || (token && lastActivityTime && visitingTime > expireInTime)) {
      logoutAndRedirect();
    }

    const updateActivityTime = debounce(() => {
      lastActivityTime = new Date().getTime();
      localStorage.setItem("lastActivityTime", lastActivityTime);
    }, 1000);

    const checkInactivity = () => {
      const currentTime = new Date().getTime();
      if (currentTime - lastActivityTime > expireInTime) {
        logoutAndRedirect();
      }
    };

    events.forEach((event) =>
      window.addEventListener(event, updateActivityTime)
    );

    const interval = setInterval(checkInactivity, 60000);

    return () => {
      clearInterval(interval);
      events.forEach((event) =>
        window.removeEventListener(event, updateActivityTime)
      );
    };
  }, [navigate, logout, hideAllModal]);

  if (!role) {
    // If the user is not authenticated, redirect to the login page
    return <Navigate to="/login" />;
  }

  if (!roles.includes(role)) {
    // If the user's role is not authorized, redirect to the unauthorized page
    return <h2>unauthorized</h2>;
  }

  // If the user is authenticated and authorized, render the component
  return <Outlet />;
};

export default ProtectedRoute;
