import React, { useState } from "react";
import { Button, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { MoreVert, Close } from "@mui/icons-material";
import { formatDate, getStatusStyle, formatCurrency } from "../../helper/util";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";
import TopupPackageDialog from "./TopupPackageDialog";
import CustomTable from "../ui/Table/CustomTable";
import { useNavigate } from "react-router-dom";
import DeactivatePackageDialog from "./DeactivatePackageDialog";
import GlobalLoading from "../../Pages/GlobalLoading";

const MainPackageTable = ({
  count,
  data,
  rowPerPage,
  page,
  handlePageChange,
  handleRowsPerPageChange,
  handleDeactivatePackage,
  isLoading,
  type,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [id, setId] = useState("");
  const [rowData, setDataRow] = useState("");
  const { showModal } = useGlobalModalContext();
  const [globalLoading, setGlobalLoading] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const columns = [
    {
      name: "No.",
      id: "number",
    },
    {
      name: "Package Name",
      id: "name",
      minWidth: "100",
    },
    { name: "Package Type", id: "package_type" },
    {
      name: "Description",
      id: "description",
    },
    {
      name: "Price",
      id: "price",
      element: (row) => `${formatCurrency(row.price)}`,
      minWidth: "80px",
    },
    {
      name: "Duration",
      id: "duration",
      element: (row) => `${row.duration} Day(s)`,
    },
    {
      name: "Export Count",
      id: "export_count",
    },
    {
      name: "Create Date",
      id: "create_date",
      minWidth: 100,
      element: (row) => `${formatDate(row.create_date?.value)}`,
    },
    {
      name: "Status",
      id: "status",
      element: (row) => getStatusStyle(row.status),
    },
    {
      id: "id",
      minWidth: 50,
      width: 50,
      element: (row) => {
        const buttonId = "basic-button";
        const menuId = "basic-menu";
        const open = Boolean(anchorEl);
        return (
          <>
            {row.status.toLowerCase() === "active" && (
              <>
                <Button
                  id={buttonId}
                  aria-controls={open ? menuId : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setDataRow(row);
                  }}
                >
                  <MoreVert />
                </Button>
                <Menu
                  id={menuId}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    "aria-labelledby": buttonId,
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      showModal(
                        <DeactivatePackageDialog
                          onSuccess={() => {
                            handleDeactivatePackage(rowData.id, rowData.package_type);
                            setAnchorEl(null);
                          }}
                        />
                      )
                    }
                  >
                    Deactivate Package
                  </MenuItem>
                </Menu>
              </>
            )}
          </>
        );
      },
    },
  ];

  const additionalColumns = [
    {
      name: "Audience Size Per Export",
      id: "audience_size_per_export",
    },
    {
      name: "Connectors Count",
      id: "connectors_count",
    },
  ];

  let baseColumns = [...columns];
  if (type != "Topup") {
    const exportCountIndex = columns.findIndex(
      (col) => col.id === "export_count"
    );
    baseColumns = [
      ...columns.slice(0, exportCountIndex + 1),
      ...additionalColumns,
      ...columns.slice(exportCountIndex + 1),
    ];
  }

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      {globalLoading && <GlobalLoading />}
      <CustomTable
        isLoading={isLoading}
        columns={baseColumns}
        data={data}
        count={count}
        rowPerPage={rowPerPage}
        page={page}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
      <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "10000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </>
  );
};

export default MainPackageTable;
