import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useGlobalModalContext } from "./GlobalModalContext/GlobalModalContext";

const DeleteEnhancedAudDialog = ({ id, message, onSuccess }) => {
  const { hideModal, hideAllModal } = useGlobalModalContext();
  const handleSubmit = (e) => {
    e.preventDefault();

    hideModal();
    onSuccess();
  };
  return (
    <Dialog
      open
      onClose={hideModal}
      className="modal-delete"
      sx={{ padding: "32px, 24px, 24px, 24px" }}
    >
      <DialogTitle className="modal-lg-text-left">
        Do you want to delete this Audience?
      </DialogTitle>
      <DialogContent className="modal-sm-text-left">
        <div>
          The segment {message?.segmentName} is associated with the following
          segments:
        </div>
        <br />

        {message?.audiences?.map((item) => (
          <div key={item}>{item}</div>
        ))}

        <br />
        <div>
          Deleting this segment will also impact all the associated segments
          listed above.
        </div>
        <br />
        <div>
          Are you sure you want to delete {message?.segmentName} and all its
          associated segments?
        </div>
        <br />
        <div>This action cannot be undone.</div>
      </DialogContent>

      <DialogActions>
        <Button onClick={hideModal} sx={{ fontSize: "12px" }}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{ color: "#FFFFFF", backgroundColor: "#D92D20 !important" }}
        >
          Delete Audience
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteEnhancedAudDialog;
