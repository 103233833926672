import { useState } from "react";
import {
  Dialog,
  Typography,
  Grid,
  FormLabel,
  FormControl,
  Button,
  TextField,
} from "@mui/material";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";
import CustomSelect from "../ui/CustomSelect";
import { CustomInputField } from "../ui";
import ConfirmCreationDialog from "./ConfirmCreationDialog";
import { noDecimal } from "../../helper/constant";

const AddNewPackageDialog = ({ createMainPackage, createTopupPackage }) => {
  const menuItems = [
    { value: "Main Package", label: "Main Package" },
    { value: "Topup Package", label: "Topup Package" },
  ];

  const connectors = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    //{ value: "4", label: "4" },
  ];
  const { hideModal, hideAllModal, showModal } = useGlobalModalContext();
  const [packageType, setPackageType] = useState("");
  const [packageTypeDisplay, setPackageTypeDisplay] = useState("");
  const [connectorsCount, setConnectorsCount] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: 0,
    duration: 0,
    exportCount: 0,
    maxAudienceSize: 0,
    connectorsCount: "",
  });
  const [formError, setFormError] = useState({});
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isPackageTypeSubmit, setIsPackageTypeSubmit] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!packageType) {
      setIsPackageTypeSubmit(true);
      return;
    }

    const errors = validateForm();
    if (Object.keys(errors).length != 0) {
      setFormError(errors);
      return;
    }

    setIsFormSubmit(true);
    onSuccess();
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Please enter package name.";
    }

    if (!formData.description) {
      newErrors.description = "Please enter description.";
    }

    if (!formData.price) {
      newErrors.price = "Please enter Price.";
    } else if (!noDecimal.test(formData.price) || formData.price < 0) {
      newErrors.price = "Please enter a whole number.";
    } else if(formData.price == 0) {
      newErrors.price = "Price cannot be 0.";
    }

    if (!formData.duration) {
      newErrors.duration = "Please enter Duration.";
    } else if (!noDecimal.test(formData.duration) || formData.duration < 0) {
      newErrors.duration = "Please enter a whole number.";
    } else if (formData.duration == 0 && packageType === "main") {
      newErrors.duration = "Duration cannot be 0.";
    }

    if (!formData.exportCount) {
      newErrors.exportCount = "Please enter No. of exports.";
    } else if (
      !noDecimal.test(formData.exportCount) ||
      formData.exportCount < 0
    ) {
      newErrors.exportCount = "Please enter a whole number.";
    } else if (formData.exportCount == 0 && packageType === "main") {
      newErrors.exportCount = "No. of exports. cannot be 0";
    } 

    if (
      formData.exportCount == 0 &&
      formData.duration == 0 &&
      packageType === "topup"
    ) {
      newErrors.exportCount = "Duration and No of exports cannot be 0.";
      newErrors.duration = "Duration and No of exports cannot be 0.";
    } 

    if (packageType === "main") {
      if (!formData.maxAudienceSize) {
        newErrors.maxAudienceSize =
          "Please enter Max Audience size per export.";
      } else if (
        !noDecimal.test(formData.maxAudienceSize) ||
        formData.maxAudienceSize < 0
      ) {
        newErrors.maxAudienceSize = "Please enter a whole number.";
      } else if(formData.maxAudienceSize == 0) {
        newErrors.maxAudienceSize = "Max Audience size per export cannot be 0";
      }

      if (!formData.connectorsCount) {
        newErrors.connectorsCount = "Please enter No. of connectors.";
      }
    }

    return newErrors;
  };

  const onSuccess = () => {
    showModal(
      <ConfirmCreationDialog handleCreatePackage={handleCreatePackage} />
    );
  };

  const handleCreatePackage = () => {
    if (packageType == "main") {
      createMainPackage(formData);
    } else {
      createTopupPackage(formData);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setFormError({
      ...formError,
      [e.target.name]: "",
    });
  };

  return (
    <Dialog open onClose={hideModal} maxWidth="sm">
      <Grid>
        <form onSubmit={handleSubmit} noValidate>
          <Typography variant="h5">Add New Package</Typography>
          <Grid container spacing={2} item xs={12} mt={1}>
            <Grid item xs={12}>
              <CustomSelect
                id={"packageType"}
                required
                label={"Package Type"}
                error={!packageType && isPackageTypeSubmit}
                helperText={
                  !packageType && isPackageTypeSubmit
                    ? "Please enter package type."
                    : ""
                }
                placeholder="Package Type"
                value={packageTypeDisplay}
                options={menuItems}
                onChange={(e) => {
                  setPackageTypeDisplay(e.target.value);
                  if (e.target.value == "Main Package") {
                    setPackageType("main");
                  } else {
                    setPackageType("topup");
                  }
                }}
              />
            </Grid>
            {packageType && (
              <Grid item xs={6}>
                <CustomInputField
                  label="Package Name"
                  name="name"
                  placeholder="Enter Package Name"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleInputChange}
                  required={true}
                  value={formData.name || ""}
                  error={!!formError.name}
                  helperText={formError.name}
                />
              </Grid>
            )}
            {packageType && (
              <Grid item xs={6}>
                <CustomInputField
                  label="Description"
                  name="description"
                  placeholder="Enter Description"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleInputChange}
                  required={true}
                  value={formData.description || ""}
                  error={!!formError.description}
                  helperText={formError.description}
                />
              </Grid>
            )}

            {packageType && (
              <Grid item xs={6}>
                <CustomInputField
                  label="Price"
                  name="price"
                  placeholder="THB"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleInputChange}
                  required={true}
                  value={formData.price || ""}
                  error={!!formError.price}
                  helperText={formError.price}
                />
              </Grid>
            )}
            {packageType && (
              <Grid item xs={6}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  <CustomInputField
                    label="Duration"
                    name="duration"
                    type="number"
                    placeholder="Duration in days..."
                    InputLabelProps={{ shrink: true }}
                    onChange={handleInputChange}
                    required={true}
                    value={formData.duration || ""}
                    error={!!formError.duration}
                    helperText={formError.duration}
                  />
                  <span style={{ paddingTop: "16px" }}>Day(s)</span>
                </div>
              </Grid>
            )}

            {packageType && (
              <Grid item xs={12}>
                <CustomInputField
                  label="No. of exports"
                  name="exportCount"
                  type="number"
                  placeholder="No. of exports..."
                  InputLabelProps={{ shrink: true }}
                  onChange={handleInputChange}
                  required={true}
                  value={formData.exportCount || ""}
                  error={!!formError.exportCount}
                  helperText={formError.exportCount}
                />
              </Grid>
            )}
            {packageType === "main" && (
              <Grid item xs={12}>
                <CustomInputField
                  label="Max Audience size per export"
                  name="maxAudienceSize"
                  type="number"
                  placeholder="Max Audience size per export..."
                  InputLabelProps={{ shrink: true }}
                  onChange={handleInputChange}
                  required={packageType === "main"}
                  value={formData.maxAudienceSize || ""}
                  error={!!formError.maxAudienceSize}
                  helperText={formError.maxAudienceSize}
                />
              </Grid>
            )}
            {packageType === "main" && (
              <Grid item xs={12}>
                <CustomSelect
                  id={"connectorsCount"}
                  required
                  label={"No. of connectors"}
                  error={!!formError.connectorsCount}
                  helperText={formError.connectorsCount}
                  placeholder="Select No. of connectors..."
                  value={connectorsCount}
                  options={connectors}
                  onChange={(e) => {
                    setConnectorsCount(e.target.value);
                    setFormData({
                      ...formData,
                      connectorsCount: e.target.value,
                    });
                    setFormError({
                      ...formError,
                      connectorsCount: "",
                    });
                  }}
                />
              </Grid>
            )}
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                <Button fullWidth onClick={hideModal} variant="contained">
                  CANCEL
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth variant="contained" type="submit">
                  CONFIRM
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Dialog>
  );
};

export default AddNewPackageDialog;
