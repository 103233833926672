import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Select from "../Components/MultiSelectDropdown";
import AudienceFooter from "./AudienceFooter";
import APIS from "./APIS";
import { IconButton, Snackbar } from "@mui/material";
import Close from "@mui/icons-material/Close";

const CommercialRelevant = ({
  commRelevantData,
  commRelevantselectedFilters,
  setCommRelevantselectedFilters,
  handleNextTab,
  handleTotalCount,
  audTotalCount,
  handleClearAll,
  audTotalCategories,
  handleCalculateTotalCategories,
  handleAddCondition
}) => {
  const [errorSnackbar, setErrorSnackbar] = useState("");

  useEffect(() => {
    calculateCount();
  }, [commRelevantselectedFilters]);

  const calculateCount = () => {
    // let selectedCount = Object.keys(commRelevantselectedFilters)
    //   .map((filterType) => commRelevantselectedFilters[filterType]?.length || 0)
    //   .reduce((acc, count) => acc + count, 0);

    // let totalSelectionCount = selectedCount;

    // setAudTotalCategories(totalSelectionCount);
    handleCalculateTotalCategories()
  };

  const isActive = (type, value) => {
    if (
      commRelevantselectedFilters[type]?.some(
        (element) => element.value == value
      )
    ) {
      return true;
    }
    return false;
  };

  const handleOptionChange = (filterType, selectedValues, isBtn) => {
    if (isBtn) {
      setCommRelevantselectedFilters((prevOptions) => {
        return {
          ...prevOptions,
          [filterType]: btnTypeSelection(filterType, selectedValues),
        };
      });
    } else {
      setCommRelevantselectedFilters((prevOptions) => {
        if (selectedValues.length === 0) {
          const { [filterType]: _, ...rest } = prevOptions;
          return rest;
        } else {
          return {
            ...prevOptions,
            [filterType]: selectedValues.map((value) => value),
          };
        }
      });
    }
  };

  const btnTypeSelection = (filterType, selectedValues) => {
    if (!commRelevantselectedFilters[filterType]) {
      return commRelevantData[filterType]?.filters?.filter(
        (item) => item.value === selectedValues[0]
      );
    }

    if (
      commRelevantselectedFilters[filterType]?.some(
        (item) => item.value === selectedValues[0]
      )
    ) {
      return commRelevantselectedFilters[filterType]?.filter(
        (item) => item.value !== selectedValues[0]
      );
    } else {
      return [
        ...commRelevantselectedFilters[filterType],
        ...commRelevantData[filterType]?.filters?.filter(
          (item) => item.value === selectedValues[0]
        ),
      ];
    }
  };

  const renderFilter = (filterType, options) => {
    return (
      <Grid item xs={6} key={filterType}>
        <label className="audience-label">
          {convertToCamelCase(filterType)}
        </label>
        <br />
        {filterType === "TDG_Customer_Group" ? (
          options.map((option) => (
            <Button
              className={`form-btn ${filterType} ${
                isActive(filterType, option.value) ? "active" : ""
              }`}
              key={option.value}
              variant="outlined"
              sx={{ mr: 0.5, minWidth: "140px", height: 32 }}
              onClick={(event) =>
                handleOptionChange(filterType, [option.value], true)
              }
            >
              {option.value == 1 ? "TDG Customer" : "Non-TDG Customer"}
            </Button>
          ))
        ) : ["Android_Box_MAU", "Foreigner", "True_ID_MAU"].includes(
            filterType
          ) ? (
          options.map((option) => (
            <Button
              className={`form-btn ${filterType} ${
                isActive(filterType, option.value) ? "active" : ""
              }`}
              key={option.value}
              variant="outlined"
              sx={{ mr: 0.5, width: "140px", height: 32 }}
              onClick={(event) =>
                handleOptionChange(filterType, [option.value], true)
              }
            >
              {option.value == 1 ? "True" : "False"}
            </Button>
          ))
        ) : ["Rail_BTS_Rider_Trip_Frequency", "Subs_Type"].includes(
            filterType
          ) ? (
          options.map((option) => (
            <Button
              className={`form-btn ${filterType} ${
                isActive(filterType, option.value) ? "active" : ""
              }`}
              key={option.value}
              variant="outlined"
              sx={{ mr: 0.5, width: "140px", height: 32 }}
              onClick={(event) =>
                handleOptionChange(filterType, [option.value], true)
              }
            >
              {option.value}
            </Button>
          ))
        ) : (
          <Select
            onOptionChange={(selectedValues, event) =>
              handleOptionChange(filterType, selectedValues, false)
            }
            colourOptions={options
              .filter((filter) => filter.value !== null)
              .map((filter) => ({
                value: filter.value,
                label: filter.value,
                sublabel: convertCount(filter.count),
              }))}
            optionSelected={commRelevantselectedFilters[filterType] || []}
          />
        )}
      </Grid>
    );
  };

  const convertToCamelCase = (inputString) => {
    return inputString
      .replace(/_/g, " ")
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
        index === 0 ? word.toLowerCase() : word.toUpperCase()
      );
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "5000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <div className="sec-title">Commercial Relevant</div>
      <Grid container spacing={2} className="content-container">
        {Object.entries(commRelevantData).map(([filterType, filterData]) =>
          renderFilter(filterType, filterData.filters)
        )}
      </Grid>
      <div>
        <AudienceFooter
          count={audTotalCount}
          onCalculate={handleTotalCount}
          totalSlectionCount={audTotalCategories}
          onChildClick={handleClearAll}
          onNextClick={handleNextTab}
          handleAddCondition={handleAddCondition}
        />
      </div>
    </div>
  );

  function convertCount(count) {
    let convertedCount = count;
    if (count >= 1000000) {
      convertedCount = (count / 1000000).toFixed(2) + "M";
    } else if (count >= 1000) {
      convertedCount = (count / 1000).toFixed(2) + "K";
    } else {
      convertedCount = count;
    }
    return convertedCount;
  }
};

export default CommercialRelevant;
