import * as React from "react";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const CustomDialog = ({
  dialogTitle,
  dialogContent,
  leftButtonLabel,
  rightButtonLabel,
  openDialog,
  handleCloseDialog,
  image,
  leftButtonAction,
  rightButtonAction,
  noActions = false,
}) => {
  // Enhanced handleCloseDialog to handle potential event or reason parameters
  // const enhancedHandleCloseDialog = (event, reason) => {
  //   if (reason !== "backdropClick" && handleCloseDialog) {
  //     handleCloseDialog(event, reason);
  //   }
  // };
  const enhancedHandleCloseDialog = (event, reason) => {
    if (handleCloseDialog) {
      handleCloseDialog(event, reason);
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={enhancedHandleCloseDialog} // Use the enhanced handler
      className="modal-delete"
      sx={{ padding: "32px, 24px, 24px, 24px" }}
    >
      <img src={image} width="56" height="56" style={{ margin: "auto" }} />
      <DialogTitle className="modal-lg-text">{dialogTitle}</DialogTitle>
      <DialogContent className="modal-sm-text">{dialogContent}</DialogContent>
      {noActions && (
        <Button onClick={leftButtonAction} variant="contained">
          {leftButtonLabel}
        </Button>
      )}

      {!noActions && (
        <DialogActions>
          <Button onClick={leftButtonAction} sx={{ width: "100%", fontWeight: '700' }}>
            {leftButtonLabel}
          </Button>
          <Button
            onClick={rightButtonAction}
            variant="contained"
            color="primary"
            sx={{ fontSize: "16px", fontWeight: '700' }}
          >
            {rightButtonLabel}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CustomDialog;
