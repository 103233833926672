import React, { useState } from "react";
import { Button, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { MoreVert, Close } from "@mui/icons-material";
import { formatDate, getStatusStyle, formatCurrency } from "../../helper/util";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";
import TopupPackageDialog from "./TopupPackageDialog";
import CustomTable from "../ui/Table/CustomTable";
import { useNavigate } from "react-router-dom";
import DeactivatePackageDialog from "./DeactivatePackageDialog";
import GlobalLoading from "../../Pages/GlobalLoading";
import APIS from "../../Pages/APIS";

const TopupPackageTable = ({
  count,
  data,
  rowPerPage,
  page,
  handlePageChange,
  handleRowsPerPageChange,
  handleDeactivatePackage,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [id, setId] = useState("");
  const [rowData, setDataRow] = useState("");
  const { showModal } = useGlobalModalContext();
  const [globalLoading, setGlobalLoading] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const columns = [
    {
      name: "No.",
      id: "number",
    },
    {
      name: "Package Name",
      id: "name",
      minWidth: "100",
    },
    { name: "Package Type", element: (row) => "Topup" },
    {
      name: "Description",
      id: "description",
    },
    {
      name: "Price",
      id: "price",
      element: (row) => `${formatCurrency(row.price)}`,
      minWidth: "80px",
    },
    {
      name: "Duration",
      id: "duration",
      element: (row) => `${row.duration} Day(s)`,
    },
    {
      name: "Export Count",
      id: "export_count",
    },
    {
      name: "Create Date",
      id: "create_date",
      element: (row) => `${formatDate(row.create_date?.value)}`,
    },
    {
      name: "Status",
      id: "status",
      element: (row) => getStatusStyle(row.status),
    },
    {
      id: "id",
      minWidth: 50,
      width: 50,
      element: (row) => {
        const buttonId = "basic-button";
        const menuId = "basic-menu";
        const open = Boolean(anchorEl);
        return (
          <>
            {row.status.toLowerCase() == "active" && (
              <>
                <Button
                  id={buttonId}
                  aria-controls={open ? menuId : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setDataRow(row);
                  }}
                >
                  <MoreVert />
                </Button>

                <Menu
                  id={menuId}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    "aria-labelledby": buttonId,
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      showModal(
                        <DeactivatePackageDialog
                          onSuccess={() => {
                            handleDeactivatePackage(rowData.id);
                            setAnchorEl(null);
                          }}
                          title="Deactivate this topup package?"
                          description="Are you sure you want to Deactivate this topup package?"
                        />
                      )
                    }
                  >
                    Deactivate Package
                  </MenuItem>
                </Menu>
              </>
            )}
          </>
        );
      },
    },
  ];

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      {globalLoading && <GlobalLoading />}
      <CustomTable
        columns={columns}
        data={data}
        count={count}
        rowPerPage={rowPerPage}
        page={page}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
      <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "10000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </>
  );
};

export default TopupPackageTable;
