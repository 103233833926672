import React, { useEffect, useState } from "react";
import { Button, Divider } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import APIS from "./APIS";
import DOMPurify from "dompurify";
import { CustomSwitch } from "../Components/ui";
import { useRegisterContext } from "../Components/RegisterContext";
import { Typography } from "@mui/material";
import useLanguage from "../helper/customhook";

function MarketingConsent() {
  const navigate = useNavigate();
  const [about, setAbout] = useState({});

  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    fetch(`/content/marketing.json`)
      .then((response) => response.json())
      .then((jsonData) => setAbout(jsonData))
      .catch((error) => console.error("Error fetching the JSON data:", error));
  };

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const [registrationStatus, setRegistrationStatus] = useState({
    success: null,
    error: null,
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedToggles, setSelectedToggles] = useState(0); // Count of selected toggles

  const location = useLocation();
  const receivedData = location.state;
  const { setRegisterData } = useRegisterContext();

  // Function to handle toggle changes
  const handleToggleChange = (toggle) => {
    const newSelectedToggles = selectedToggles + (toggle ? 1 : -1); // Increment or decrement count based on toggle state
    setSelectedToggles(newSelectedToggles);
  };

  const checkIsButtonDisabled = () => {
    if (isButtonDisabled || selectedToggles != 2) return true;
    return false;
  };

  const handleclick = async () => {
    try {
      setIsButtonDisabled(true);

      const response = await APIS.Registration({
        company: DOMPurify.sanitize(receivedData.company) ,
        contact: DOMPurify.sanitize(receivedData.contact),
        email: DOMPurify.sanitize(receivedData.email),
        firstName: DOMPurify.sanitize(receivedData.firstName),
        jobTitle: DOMPurify.sanitize(receivedData.jobTitle),
        lastName: DOMPurify.sanitize(receivedData.lastName),
        mobileNo: DOMPurify.sanitize(receivedData.mobileNo),
        role: DOMPurify.sanitize(receivedData.role),
        userType: DOMPurify.sanitize(receivedData.userType),
      });
      if (response.code == 200) {
        setRegisterData({});
        const displaydata = {
          text1: "VERIFY YOUR EMAIL ADDRESS",
          text2:
            "To complete the registration process, please verify your email address by clicking the link we've sent to your inbox.",
          email: receivedData.email,
        };

        navigate("/PageInfoModalBox", { state: displaydata });
      } else {
        setRegistrationStatus({ success: null, error: response.message });
      }
    } catch (error) {
      setRegistrationStatus({ success: null, error: error });
    } finally {
      setIsButtonDisabled(false);
    }
  };

  return (
    <div className="login-card privacy-card">
      {registrationStatus.error && (
        <div className="error-message">{registrationStatus.error}</div>
      )}

      <div className="font-md">
        <h2 dangerouslySetInnerHTML={createMarkup(about.title)} />
        <h2 dangerouslySetInnerHTML={createMarkup(about.titleTH)} />
        {about?.paragraphs?.map((paragraph, index) => (
          <div key={`paragraph-${index}`}>
            <div dangerouslySetInnerHTML={createMarkup(paragraph)} />
            <br />
          </div>
        ))}
        {about?.subcontents?.map((content, contentIndex) => (
          <div key={`content-${contentIndex}`}>
            <h3 dangerouslySetInnerHTML={createMarkup(content.title)} />
            {content?.paragraphs?.map((paragraph, paragraphIndex) => (
              <div key={`content-${contentIndex}-paragraph-${paragraphIndex}`}>
                <div dangerouslySetInnerHTML={createMarkup(paragraph)} />
                <br />
              </div>
            ))}
          </div>
        ))}
      </div>

      <div></div>
      <br></br>

      <div dangerouslySetInnerHTML={createMarkup(about.toggle1?.EN)} />

      <div dangerouslySetInnerHTML={createMarkup(about.toggle1?.TH)} />
      <br></br>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <b></b>
        <CustomSwitch onChange={(e) => handleToggleChange(e.target.checked)} />
      </div>
      <br></br>

      <div dangerouslySetInnerHTML={createMarkup(about.toggle2?.EN)} />

      <div dangerouslySetInnerHTML={createMarkup(about.toggle2?.TH)} />

      <br></br>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <b></b>
        <CustomSwitch onChange={(e) => handleToggleChange(e.target.checked)} />
      </div>
      <br></br>
      <br></br>
      <Divider />

      <Button
        variant={checkIsButtonDisabled() ? "text" : "contained"}
        size="medium"
        color="error"
        onClick={handleclick}
        disabled={checkIsButtonDisabled()}
      >
        CONTINUE
      </Button>
    </div>
  );
}

export default MarketingConsent;
