import React from "react";
import { HelpOutlineOutlined } from "@mui/icons-material";
import {
    Box,
    Tooltip,
    Typography,
  } from "@mui/material";

const LabelWithTooltip = ({ label, tooltip, size }) => (
  <Box className="label-with-tooltip">
  <Typography component="span">{label}</Typography>
  <Tooltip title={tooltip}>
    <HelpOutlineOutlined sx={{ fontSize: size }} style={{ marginLeft: 4 }} />
  </Tooltip>
</Box>
  );

export default LabelWithTooltip;