import { useEffect, useRef, useState } from "react";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import APIS from "../../Pages/APIS";
import { formatDate } from "../../helper/util";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";
import { useAuthContext } from "../AuthContextProvider";
import { CustomTable } from "../ui";

const PackageSummaryDialog = ({ selectedAccount }) => {
  const [packageSummary, setPackageSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const { hideModal } = useGlobalModalContext();
  const { token } = useAuthContext();
  const isInitialMount = useRef(true);

  const columns = [
    {
      name: "Package Name",
      id: "package_name",
    },
    {
      name: "Start Date",
      id: "package_start_date",
      minWidth: 100,
      element: (row) => `${formatDate(row.package_start_date?.value)}`,
    },
    {
      name: "End Date",
      id: "package_end_date",
      minWidth: 100,
      element: (row) => `${formatDate(row.package_end_date?.value)}`,
    },
    {
      name: "Export Count",
      id: "export_actual",
    },
    {
      name: "Actual Usage",
      id: "export_utilized",
    },
    {
      name: "Quota Left",
      id: "export_remaining",
    },
    { name: "Number of Audience per push", id: "audience_size_per_export" },
    { name: "Connector Count", id: "connectors_count" },
  ];

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      getPackageSummary();
    }
  }, []);

  const getPackageSummary = async () => {
    try {
      setLoading(true);

      const history = await APIS.getAccountPackageSummary(
        token,
        selectedAccount.id
      );

      setPackageSummary(history.packageSummary);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw new Error("Fetching Audience Push History Error", error);
    }
  };

  return (
    <Dialog
      open
      maxWidth={"lg"}
      onClose={hideModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid display={"grid"} margin={3} rowGap={2}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {selectedAccount?.account_name ?? ""}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={hideModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>

        <CustomTable
          columns={columns}
          data={packageSummary}
          hidePagination
          isLoading={loading}
        />
      </Grid>
    </Dialog>
  );
};

export default PackageSummaryDialog;
