import React from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import TabPanel from "../Components/TabPanel";
import MyAccountList from "./MyAccountList";
import Navbar from "../Components/navbar";
import Sidebar from "../Components/sidebar";
import UserAccountList from "./UserAccountList";
import UserAccountListUserRole from "./UserAccountListUserRole";
import { useAuthContext } from "../Components/AuthContextProvider";
import PackageHistoryTable from "../Components/MyAccount/PackageHistoryTable";

const MyAccount = () => {
  const [tab, setTab] = React.useState("user");
  const { isAdmin } = useAuthContext();

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <div className="inner-wrapper">
      <Grid container>
        <Grid item xs={12}>
          <div className="inner-wrapper">
            <Grid container>
              <Grid item xs={12}>
                <Navbar />
              </Grid>
              <Grid item xs={1}>
                <Sidebar />
              </Grid>
              <Grid item xs={11}>
                {isAdmin() ? (
                  <div className="content-wrapper">
                    <h2>Admin Page</h2>
                    <div className="sub-title">
                      To set Users and Company Groups
                    </div>

                    <Tabs value={tab} onChange={handleTabChange}>
                      <Tab label="User Management" value={"user"}></Tab>
                      <Tab label="Company Group" value={"account"} />
                    </Tabs>
                    <TabPanel value={tab} index="user">
                      <UserAccountList />
                    </TabPanel>
                    <TabPanel value={tab} index="account">
                      <MyAccountList />
                    </TabPanel>
                  </div>
                ) : (
                  <div className="content-wrapper">
                    <h2>My Package</h2>
                    <div className="sub-title">To show package information</div>
                    <UserAccountListUserRole />
                    <PackageHistoryTable />
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MyAccount;
