import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Dialog } from "@mui/material";
import { List, ListItem } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import APIS from "./APIS";
import { CustomInputField } from "../Components/ui";
import AppLogo from "../Components/AppLogo";
import { useGlobalModalContext } from "../Components/GlobalModalContext/GlobalModalContext";
import { Error } from "@mui/icons-material";
import { useAuthContext } from "../Components/AuthContextProvider";

const CreatePassword = () => {
  const navigate = useNavigate();
  const { showModal, hideAllModal } = useGlobalModalContext();
  const { login } = useAuthContext();
  const [formStatus, setFormStatus] = useState({
    success: null,
    error: null,
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");

  useEffect(() => {
    if (code) {
      verifyOtp();
    }
  }, []);
  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
    code: code,
  });

  const [validationErrors, setValidationErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      [e.target.name]: "",
    });
  };

  const verifyOtp = async () => {
    const response = await APIS.VerifyOTP({ code });

    if (response.code != 200) {
      showModal(failDialog(response.message));
    }
  };

  const handleContinue = async (e) => {
    // Reset validation errors

    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length != 0) {
      setValidationErrors(errors);
      return;
    }

    try {
      setIsButtonDisabled(true); // Disable the button on click
      setFormStatus({
        success: null,
        error: null,
      });

      const response = await APIS.CreatePassword(passwordData);
      if (response.code == 200) {
        login(response);
      } else {
        setFormStatus({ success: null, error: response.message });
      }
    } catch (error) {
      setFormStatus({ success: null, error: error });
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const complexityRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/;
    if (!passwordData.password) {
      newErrors.password = "New Password is required.";
    } else if (!complexityRegex.test(passwordData.password)) {
      newErrors.password =
        "Password must contain at least 1 alphabet, 1 digit, and 1 special character.";
    } else if (passwordData?.password?.length < 8) {
      newErrors.password =
        "The password doesn't meet the required criteria. Please try again.";
    }

    if (!passwordData.confirmPassword) {
      newErrors.confirmPassword = "Confirm New Password is required.";
    } else if (passwordData.password !== passwordData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }
    return newErrors;
  };

  const showPrivacyPolicy = () => {
    navigate("/PrivacyPolicy");
  };

  const showTerms = () => {
    navigate("/Terms");
  };

  const handleOnClose = () => {
    hideAllModal();
    navigate("/Login");
  };

  const failDialog = (text) => {
    return (
      <Dialog
        open
        onClose={handleOnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid display={"grid"} justifyContent={"center"} rowGap={2}>
          <Grid display={"grid"} justifyContent={"center"}>
            <Error color="warning" fontSize="large" />
          </Grid>

          <Grid display={"grid"} alignItems={"center"}>
            {text}
          </Grid>

          <Grid item>
            <Button variant="contained" onClick={handleOnClose} fullWidth>
              BACK TO LOGIN
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  };

  return (
    <div className="login-card">
      <AppLogo />
      <h2>Create Password</h2>

      {formStatus.success && (
        <div className="success-message">{formStatus.success}</div>
      )}

      {formStatus.error && (
        <div className="error-message">{formStatus.error}</div>
      )}

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        onSubmit={handleContinue}
        noValidate
        autoComplete="off"
      >
        <div>
          <CustomInputField
            label="New Password"
            placeholder="Enter new password"
            InputLabelProps={{ shrink: true }}
            name="password"
            type="password"
            onChange={handleChange}
            required
            pwdField
            error={!!validationErrors.password}
            helperText={validationErrors.password}
          />
          <div className="info-sec">
            Please make sure your password contains at least 1 character from
            all of the following
            <List
              sx={{
                listStyleType: "disc",
                listStylePosition: "inside",
                p: 0,
                m: 0,
              }}
            >
              <ListItem sx={{ display: "list-item" }}>Alphabet</ListItem>
              <ListItem sx={{ display: "list-item" }}>Digit</ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Lower and upper case
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                              Special character
                            </ListItem>
                          </List>
                          <div className="info-sec">Must be at least 8 characters</div>
          </div>
          <div>
            <CustomInputField
              pwdField
              label="Confirm New Password"
              placeholder="Enter confirm new password"
              InputLabelProps={{ shrink: true }}
              name="confirmPassword"
              type="password"
              onChange={handleChange}
              required
              error={!!validationErrors.confirmPassword}
              helperText={validationErrors.confirmPassword}
            />
          </div>

          <Button
            variant="contained"
            size="medium"
            type="submit"
            disabled={isButtonDisabled}
          >
            CONTINUE
          </Button>
          <div className="center" style={{ marginTop: 10 }}>
            <span className="font-md c-black link" onClick={showTerms}>
              Terms of Use
            </span>
            <span> and </span>
            <span className="font-md c-black link" onClick={showPrivacyPolicy}>
              Privacy Policy
            </span>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default CreatePassword;
