import React, { useEffect, useState, useTransition } from "react";
import Grid from "@mui/material/Grid";
import Navbar from "../Components/navbar";
import Sidebar from "../Components/sidebar";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  IconButton,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  NavigateNext,
  Check,
  Close,
  MoodRounded,
  MoreVert,
} from "@mui/icons-material";
import ChannelLogoLable from "../Components/ChannelLogoLable";
import APIS from "./APIS";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomTable from "../Components/ui/Table/CustomTable";
import { useAuthContext } from "../Components/AuthContextProvider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ChannelSetupList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false); // Add loading state
  const [channelConnectStatus1, setChannelConnectStatus] = useState({});
  const [channelAccessStatus, setChannelAccessStatus] = useState({});
  const [channels, setChannels] = useState([
    { id: 1, name: "Google", status: "Inactive" },
    //{ id: 2, name: "Meta", status: "Inactive" },
    //{ id: 3, name: "Line", status: "Inactive" },
    { id: 4, name: "Tiktok", status: "Inactive" },
  ]);
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const [metaChannelData, setMetaChannelData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const { token } = useAuthContext();
  const navigate = useNavigate();

  const getMetaStatus = (status) => {
    const dotStyle = {
      marginRight: "5px",
    };

    if (status == 1) {
      return (
        <>
          <span style={{ ...dotStyle, color: "green" }}>●</span>
          <span>Active</span>
        </>
      );
    } else {
      return (
        <>
          <span style={{ ...dotStyle, color: "red" }}>●</span>
          <span>Inactive</span>
        </>
      );
    }
  };

  const buttonCss = {
    backgroundColor: "#202529",
    color: "#FFFFFF !important",
    fontSize: "10px",
    minWidth: "80px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#F24822",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
    "&:disabled": {
      backgroundColor: "#EBEBEB",
      color: "#C3C3C3 !important",
      fontSize: "10px",
    },
  };

  const getStatusDot = (status) => {
    const dotStyle = {
      marginRight: "5px",
    };

    if (status?.toLocaleLowerCase() === "active") {
      return <span style={{ ...dotStyle, color: "green" }}>●</span>;
    } else if (status?.toLocaleLowerCase() === "not started") {
      return <span style={{ ...dotStyle, color: "yellow" }}>●</span>;
    } else if (status?.toLocaleLowerCase() === "inactive") {
      return <span style={{ ...dotStyle, color: "red" }}>●</span>;
    }
  };

  useEffect(() => {
    // Fetch data from API endpoint
    handleConnectChannel();
    fetchMetaAccounts();
  }, []);

  const handleConnectChannel = async () => {
    let userId = localStorage.getItem("userId");
    try {
      setLoading(true); // Set loading to true when fetching data
      let token = localStorage.getItem("token");
      const response = await APIS.getChannelSetup(token, userId);
      if (response.status == "success") {
        setChannelConnectStatus(response?.channelConnectivityStatus);
        setChannelAccessStatus(response?.channelAccessStatus);
        handleChannelStatus(response?.channelConnectivityStatus);
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
      }
      setLoading(false); // Set loading to false after fetching data
    } catch (error) {
      console.error("Error fetching projects:", error);
      setLoading(false); // Set loading to false in case of error
    }
  };

  const fetchMetaAccounts = async () => {
    let userId = localStorage.getItem("userId");
    try {
      setLoading(true); // Set loading to true when fetching data
      let token = localStorage.getItem("token");
      const response = await APIS.getMetaAccounts(token);
      if (response.statusCode == 200) {
        setMetaChannelData(response.accounts || []);
      }
      setLoading(false); // Set loading to false after fetching data
    } catch (error) {
      console.error("Error fetching projects:", error);
      setLoading(false); // Set loading to false in case of error
    }
  };

  const handleChannelStatus = (channelConnectStatus) => {
    const updatedChannels = channels.map((channel) => {
      if (channelConnectStatus[channel.name.toLowerCase()]) {
        return { ...channel, status: "Active" };
      }
      return channel;
    });

    setChannels(updatedChannels);
  };

  const handleChannelClick = (name) => {
    if (name == "Google") {
      return handleGoogleGetOauthUrl;
    } else if (name == "Meta") {
      return handleMetaGetOauthUrl;
    } else if (name == "Tiktok") {
      return handleTiktokGetOauthUrl;
    }
  };

  const handleGoogleGetOauthUrl = async () => {
    try {
      setLoading(true);
      localStorage.setItem("channelName", "google");
      let token = localStorage.getItem("token");
      const response = await APIS.getGoogleOAuth(
        token,
        "http://localhost:3000/ChannelStatic"
      );
      const redirectUrl = response.authorization_url;
      //const redirectUrl = "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords&response_type=code&state=state_parameter_passthrough_value&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2FChannelStatic&client_id=499356762111-scglhmmc8588qthijqc32npbf59hh3va.apps.googleusercontent.com";
      window.location.href = redirectUrl;
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleMetaGetOauthUrl = async () => {
    try {
      setLoading(true);
      localStorage.setItem("channelName", "meta");
      let token = localStorage.getItem("token");
      const response = await APIS.getMetaOAuth(token);
      const redirectUrl = response.authorization_url;
      //const redirectUrl = 'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords&response_type=code&state=state_parameter_passthrough_value&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2FChannelStatic&client_id=499356762111-scglhmmc8588qthijqc32npbf59hh3va.apps.googleusercontent.com'
      window.location.href = redirectUrl;
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleTiktokGetOauthUrl = async () => {
    try {
      setLoading(true);
      localStorage.setItem("channelName", "tiktok");
      let token = localStorage.getItem("token");
      const response = await APIS.getTiktokOAuth(token);
      const redirectUrl = response.authorization_url;
      //const redirectUrl = 'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords&response_type=code&state=state_parameter_passthrough_value&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2FChannelStatic&client_id=499356762111-scglhmmc8588qthijqc32npbf59hh3va.apps.googleusercontent.com'
      window.location.href = redirectUrl;
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleDisconnectChannel = async () => {
    setLoading(true);
    const type = selectedChannel?.name?.toLocaleLowerCase();
    const payload = { type };

    if (type === "meta") {
      payload.adsAccount = selectedChannel.adsAccount;
    }
    const response = await APIS.disconnectChannel(payload, token);
    if (response.status == "Channel disconnected") {
      if (type === "meta") {
        fetchMetaAccounts();
      } else {
        navigate(0);
      }
    } else {
      setErrorSnackbar(response?.message || "Some Error Occurred");
    }
    setAnchorEl(null);
    setLoading(false);
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "10000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <div className="inner-wrapper">
        <Grid container>
          <Grid item xs={12}>
            <Navbar />
          </Grid>
          <Grid item xs={1}>
            <Sidebar />
          </Grid>
          <Grid item xs={11}>
            <div className="content-wrapper">
              <h2>Channel Setup</h2>
              <div className="sub-title">
                Manage your social channel integrations. Add, update or delete
                your social channels to ensure your audience explorer service is
                always connected and up-to-date.
              </div>
              {loading ? ( // Show loader if loading is true
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          >
                            Channel
                          </TableCell>
                          {/* <TableCell></TableCell> */}
                          {/* <TableCell
                        sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
                      >
                        Ads Account
                      </TableCell> */}
                          <TableCell
                            sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          >
                            Ads Account
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          >
                            Status
                          </TableCell>
                          <TableCell width={100}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {channels.map((channel) => (
                          <TableRow key={channel.id}>
                            <TableCell sx={{ fontSize: "0.8rem" }}>
                              <ChannelLogoLable channelName={channel?.name} />
                            </TableCell>
                            <TableCell sx={{ fontSize: "0.8rem" }}>
                              <Button
                                sx={buttonCss}
                                disabled={
                                  channelAccessStatus[
                                    channel?.name.toLocaleLowerCase()
                                  ]
                                    ? channel.status === "Active"
                                      ? true
                                      : false
                                    : true
                                }
                                //</TableCell>channel.status === "Active" ? true : false

                                onClick={handleChannelClick(channel?.name)}
                                endIcon={
                                  channel.status === "Active" ? (
                                    <Check />
                                  ) : (
                                    <NavigateNext />
                                  )
                                }
                              >
                                {channelAccessStatus[
                                  channel?.name.toLocaleLowerCase()
                                ]
                                  ? channel.status === "Active"
                                    ? "Connected"
                                    : "Connect"
                                  : "Connect"}
                                {/* {channel.status === "Active"
                                  ? "Connected"
                                  : "Connect"} */}
                              </Button>
                            </TableCell>
                            <TableCell sx={{ fontSize: "0.8rem" }}>
                              {getStatusDot(channel?.status)}
                              {channel?.status}
                            </TableCell>
                            <TableCell>
                              {channelAccessStatus[
                                channel?.name.toLocaleLowerCase()
                              ] ? (
                                channel.status == "Active" ? (
                                  <Button
                                    id={"basic-button"}
                                    aria-controls={
                                      Boolean(anchorEl)
                                        ? "disconnect-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      Boolean(anchorEl) ? "true" : undefined
                                    }
                                    onClick={(event) => {
                                      setAnchorEl(event.currentTarget);
                                      setSelectedChannel(channel);
                                    }}
                                  >
                                    <MoreVert />
                                  </Button>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <></>
                              )}
                              <Menu
                                id={"basic-menu"}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                              >
                                <MenuItem
                                  disabled={selectedChannel?.status != "Active"}
                                  onClick={handleDisconnectChannel}
                                >
                                  Disconnect
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <ChannelLogoLable channelName={"Meta"} />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Button
                            sx={buttonCss}
                            onClick={handleChannelClick("Meta")}
                            disabled={!channelAccessStatus["meta"]}
                          >
                            Add Ads Account
                          </Button>
                        </Grid>
                        {!!metaChannelData.length && (
                          <Grid item xs={12}>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      sx={{
                                        fontSize: "0.8rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Business Manager ID
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontSize: "0.8rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Ads Account ID
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontSize: "0.8rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Status
                                    </TableCell>
                                    <TableCell width={100}></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {metaChannelData.map((account) =>
                                    account?.account_ids?.map(
                                      (accountDetail, index) => (
                                        <TableRow
                                          key={
                                            accountDetail.ads_account_id + index
                                          }
                                        >
                                          <TableCell
                                            sx={{ fontSize: "0.8rem" }}
                                          >
                                            {account.business_manager_id}
                                          </TableCell>
                                          <TableCell
                                            sx={{ fontSize: "0.8rem" }}
                                          >
                                            {accountDetail.ads_account_id}
                                          </TableCell>
                                          <TableCell
                                            sx={{ fontSize: "0.8rem" }}
                                          >
                                            {getMetaStatus(
                                              accountDetail?.status
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            <Button
                                              id={"basic-button"}
                                              aria-controls={
                                                Boolean(anchorEl)
                                                  ? "disconnect-menu"
                                                  : undefined
                                              }
                                              aria-haspopup="true"
                                              aria-expanded={
                                                Boolean(anchorEl)
                                                  ? "true"
                                                  : undefined
                                              }
                                              onClick={(event) => {
                                                setAnchorEl(
                                                  event.currentTarget
                                                );
                                                setSelectedChannel({
                                                  name: "Meta",
                                                  adsAccount:
                                                    accountDetail.ads_account_id,
                                                });
                                              }}
                                            >
                                              <MoreVert />
                                            </Button>
                                            <Menu
                                              id={"basic-menu"}
                                              anchorEl={anchorEl}
                                              open={Boolean(anchorEl)}
                                              onClose={() => setAnchorEl(null)}
                                              MenuListProps={{
                                                "aria-labelledby":
                                                  "basic-button",
                                              }}
                                            >
                                              <MenuItem
                                                onClick={
                                                  handleDisconnectChannel
                                                }
                                              >
                                                Disconnect
                                              </MenuItem>
                                            </Menu>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ChannelSetupList;
