// QuillEditor.js
import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const QuillEditor = ({ value, onChange, readlyOnly }) => {
  const [editorValue, setEditorValue] = useState(value);
  const isInitialRender = useRef(true);

  const handleChange = (content) => {
    setEditorValue(content);
    if (!isInitialRender.current) {
      onChange(content);
    }
  };

  useEffect(() => {
    isInitialRender.current = false;
  }, []);

  return (
    <ReactQuill
      readOnly={readlyOnly}
      className={readlyOnly && "disabled-quill"}
      value={editorValue}
      onChange={handleChange}
      modules={QuillEditor.modules}
      formats={QuillEditor.formats}
      theme="snow"
    />
  );
};

// Modules and formats configuration
QuillEditor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ align: [] }],
    ["link"],
    ["clean"],
  ],
};

QuillEditor.formats = [
  "header",
  "font",
  "list",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "align",
  "link",
];

export default QuillEditor;
