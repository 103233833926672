import React from "react";
import { Grid } from "@mui/material";
import Navbar from "../Components/navbar";
import Sidebar from "../Components/sidebar";
import ReportList from "../Components/Report/ReportList";

const Report = () => {
  return (
    <div className="inner-wrapper">
      <Grid container>
        <Grid item xs={12}>
          <div className="inner-wrapper">
            <Grid container>
              <Grid item xs={12}>
                <Navbar />
              </Grid>
              <Grid item xs={1}>
                <Sidebar />
              </Grid>
              <Grid item xs={11}>
                <div className="content-wrapper">
                  <h2>Report</h2>
                  <div className="sub-title">
                  Choose the report to view its activity details and statuses
                  </div>

                  <ReportList />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Report;
