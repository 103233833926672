import { useState, useEffect } from "react";
import { Dialog, Grid, Typography, Button } from "@mui/material";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";
import LocationCharts from "../../Pages/LocationCharts";
import { useAuthContext } from "../AuthContextProvider";
import { useAudienceContext } from "../AudienceContext/AudienceContext";

const InsightsDialog = ({insightsChartInfo, insightsMapInfo}) => {
  const { hideModal } = useGlobalModalContext();
  const { audienceEnhancement, setAudienceEnhancement } = useAudienceContext();
  const { token } = useAuthContext();

  useEffect(() => {
    //console.log('from loc', insightsMapInfo)
  }, []);

  return (
    <>
      <Dialog
        open
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modal-add-condition"
      >
        <Grid display={"grid"} rowGap={2} padding={3}>
          <Typography
            display={"grid"}
            variant="h5"
            justifyContent={"flex-start"}
            fontWeight={"bold"}
          >
            Audience Insights
          </Typography>
          <Grid display={"grid"} rowGap={2}>
            {/* Charts Section */}
            <Grid item xs={12} padding={2}>
              <LocationCharts insightsChartData={insightsChartInfo} insightsMapData={insightsMapInfo}/>
            </Grid>
          </Grid>

          <Grid display={"flex"} justifyContent={"space-between"} columnGap={2}>
            <div></div>
            <Button variant="contained" onClick={hideModal}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default InsightsDialog;
