import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const PageInfoModalBox = () => {
  const location = useLocation();
  const state = location.state;

  // Check if state exists and extract the data
  const { text1, text2 } = state || {};

  return (
    <div className="login-card">
      <div className="modal-box">
        <div className="modal-box-content">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="28" cy="28" r="28" fill="#FFFAEB" />
            <circle cx="28" cy="28" r="20" fill="#FEF0C7" />
            <path
              d="M21.1 32.05C20.7333 31.4167 20.4583 30.7667 20.275 30.1C20.0917 29.4333 20 28.75 20 28.05C20 25.8167 20.775 23.9167 22.325 22.35C23.875 20.7833 25.7667 20 28 20H28.175L26.575 18.4L27.975 17L31.975 21L27.975 25L26.575 23.6L28.175 22H28C26.3333 22 24.9167 22.5875 23.75 23.7625C22.5833 24.9375 22 26.3667 22 28.05C22 28.4833 22.05 28.9083 22.15 29.325C22.25 29.7417 22.4 30.15 22.6 30.55L21.1 32.05ZM28.025 39L24.025 35L28.025 31L29.425 32.4L27.825 34H28C29.6667 34 31.0833 33.4125 32.25 32.2375C33.4167 31.0625 34 29.6333 34 27.95C34 27.5167 33.95 27.0917 33.85 26.675C33.75 26.2583 33.6 25.85 33.4 25.45L34.9 23.95C35.2667 24.5833 35.5417 25.2333 35.725 25.9C35.9083 26.5667 36 27.25 36 27.95C36 30.1833 35.225 32.0833 33.675 33.65C32.125 35.2167 30.2333 36 28 36H27.825L29.425 37.6L28.025 39Z"
              fill="#F8961E"
            />
          </svg>

          <h3 style={{ fontWeight: "bold", fontSize: "24px" }}>{text1}</h3>
          <div className="modal-box-subtext">{text2}</div>
        </div>
      </div>
    </div>
  );
};

export default PageInfoModalBox;
