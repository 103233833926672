import React, { useEffect, useState } from "react";
import AppLogo from "../Components/AppLogo";
import DOMPurify from "dompurify";
import SwitchLangauage from "../Components/SwitchLangauage";
import useLanguage from "../helper/customhook";

function PrivacyPolicy() {
  const [lng, changeLanguage] = useLanguage();
  const [privacy, setPrivacy] = useState({});

  useEffect(() => {
    getContent();
  }, [lng]);

  const getContent = async () => {
    fetch(`/content/${lng}/privacy.json`)
      .then((response) => response.json())
      .then((jsonData) => setPrivacy(jsonData))
      .catch((error) => console.error("Error fetching the JSON data:", error));
  };

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html, { ADD_ATTR: ['target'] }) };
  };

  return (
    <div className="login-card privacy-card">
      <AppLogo />
      <SwitchLangauage currentLang={lng} onLanguageChange={changeLanguage} />
      <div className="font-md">
        <h2 dangerouslySetInnerHTML={createMarkup(privacy.title)} />
        {privacy?.paragraphs?.map((paragraph, index) => (
          <div key={`paragraph-${index}`}>
            <div dangerouslySetInnerHTML={createMarkup(paragraph)} />
            <br />
          </div>
        ))}
        {privacy?.subcontents?.map((content, contentIndex) => (
          <div key={`content-${contentIndex}`}>
            <h3 dangerouslySetInnerHTML={createMarkup(content.title)} />
            {content?.paragraphs?.map((paragraph, paragraphIndex) => (
              <p
                key={`content-${contentIndex}-paragraph-${paragraphIndex}`}
                dangerouslySetInnerHTML={createMarkup(paragraph)}
              />
            ))}
            <ul>
              {content?.listItems?.map((item, itemIndex) => (
                <li
                  key={`content-${contentIndex}-item-${itemIndex}`}
                  dangerouslySetInnerHTML={createMarkup(item)}
                />
              ))}
            </ul>
            <ol>
              {content?.orderedListItems?.map((item, itemIndex) => (
                <li
                  key={`content-${contentIndex}-item-${itemIndex}`}
                  dangerouslySetInnerHTML={createMarkup(item)}
                />
              ))}
            </ol>
            <table style={{ borderCollapse: 'separate', borderSpacing: '16px' }}>
              {content?.table?.map((item, itemIndex) => (
                <tr key={`content-${contentIndex}-item-${itemIndex}`}>
                  <td
                    dangerouslySetInnerHTML={createMarkup(item.content)}
                  />
                  <td
                    dangerouslySetInnerHTML={createMarkup(item.description)}
                  />
                </tr>
              ))}
            </table>
            {content?.paragraphsBottom?.map((paragraph, paragraphIndex) => (
              <p
                key={`content-${contentIndex}-paragraph-${paragraphIndex}`}
                dangerouslySetInnerHTML={createMarkup(paragraph)}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default PrivacyPolicy;
