import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Checkbox,
  CircularProgress,
} from "@mui/material";

const CustomTable = ({
  columns,
  data,
  page,
  count,
  rowPerPage,
  handlePageChange,
  handleRowsPerPageChange,
  handleRowClick,
  hover = false,
  showCheckBox = false,
  handleCheckBox,
  selected = [],
  hidePagination = false,
  isLoading = false,
}) => {
  const isSelected = (id) => selected.indexOf(id) !== -1;
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {showCheckBox && <TableCell></TableCell>}
              {columns?.map((col, index) => (
                <TableCell
                  key={index}
                  align={col.align ?? "center"}
                  style={{ minWidth: col.minWidth, width: col.width }}
                >
                  <b>{col.name ?? ""}</b>
                </TableCell>
              ))}
              {/* <TableCell>Testing Head</TableCell> */}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <TableBody>
              {data?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    key={index}
                    hover={hover}
                    onClick={(event) =>
                      handleRowClick ? handleRowClick(event, row) : ""
                    }
                  >
                    {showCheckBox && (
                      <TableCell padding="checkbox" key={index}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onChange={(event) =>
                            handleCheckBox ?? handleCheckBox(event, row)
                          }
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                    )}
                    {columns?.map((col, index) => (
                      <TableCell key={index} align={col.align ?? "center"}>
                        {col.element ? col.element(row) : row[col.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!hidePagination && (
        <TablePagination
          component="div"
          count={count}
          page={page}
          rowsPerPage={rowPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </>
  );
};

export default CustomTable;
