import {
  Grid,
  Button,
  Snackbar,
  Typography,
  FormHelperText,
} from "@mui/material";
import Navbar from "../Components/navbar";
import Sidebar from "../Components/sidebar";
import { useEffect, useState, useRef } from "react";
import { useAuthContext } from "../Components/AuthContextProvider";
import APIS from "./APIS";
import GlobalLoading from "./GlobalLoading";
import QuillEditor from "../Components/ui/QuillEditor";
import { CustomInputField } from "../Components/ui";
import { extractVariables } from "../helper/util";
import DOMPurify from 'dompurify';

const EmailTemplate = () => {
  const [disabledForm, setDisabledForm] = useState(true);
  const [formData, setFormData] = useState([]);
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const { token } = useAuthContext();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      getEmailTemplates();
    }
  }, []);

  const getEmailTemplates = async () => {
    setLoading(true);
    try {
      const response = await APIS.getEmailTemplates(token);

      if (response.code == 200) {
        setFormData(response.template);
      } else {
        setFormError({
          ...formError,
          apiError: response.meesage ?? "Fetch Email Template API failed!",
        });
      }
      setLoading(false);
    } catch (error) {}
  };

  const handleEmailBodyChange = (data, type) => {
    const sanitizedData = DOMPurify.sanitize(data);
    let errors = formError;
    const templates = formData.map((template) => {
      if (template?.email_type === type) {
        errors[type + "_html"] = "";
        return { ...template, html: sanitizedData };
      }
      return template;
    });
  
    setFormData(templates);
  };

  const handleEmailSubjectChange = (e, type) => {
    const { value, name } = e.target;
    let errors = formError;
    const templates = formData.map((template) => {
      if (template?.email_type == type) {
        errors[type] = "";
        return { ...template, subject: value };
      }
      return template;
    });
    setFormError(errors);
    setFormData(templates);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (disabledForm) {
      setDisabledForm(false);
      return;
    }
    const errors = validateForm();
    if (Object.keys(errors).length != 0) {
      setFormError(errors);
      return;
    }

    setLoading(true);
    try {
      const payload = formData.map((data) => {
        const { variable, email_type, ...restData } = data;
        return { ...restData, emailType: email_type };
      });
      const response = await APIS.updateEmailTemplates(
        { template: payload },
        token
      );

      if (response.status == "success") {
        setFormError({ apiError: response.meesage });
        setLoading(false);
        setDisabledForm(true);
        getEmailTemplates();
      } else {
        setFormError({ apiError: response.meesage ?? "Updated API failed!" });
        setLoading(false);
      }
    } catch (error) {}
    setLoading(false);
  };

  const validateForm = () => {
    const newErrors = {};

    for (const template of formData) {
      if (!template?.subject) {
        newErrors[template?.email_type] = "This field is required!";
      }

      const variables = extractVariables(template.html);
      const missingValues = variables?.filter(
        (value) => !template?.variable?.includes(value)
      );

      if (missingValues.length > 0) {
        newErrors[template?.email_type + "_html"] = ` ${missingValues.join(
          ","
        )} do not exist!`;
      }
    }

    return newErrors;
  };

  const handleCancel = () => {
    setDisabledForm(true);
    getEmailTemplates();
    setFormError({});
  };
  return (
    <div className="inner-wrapper">
      {loading && <GlobalLoading />}
      <Grid container>
        <Grid item xs={12}>
          <div className="inner-wrapper">
            <Grid container>
              <Grid item xs={12}>
                <Navbar />
              </Grid>
              <Grid item xs={1}>
                <Sidebar />
              </Grid>
              <Grid item xs={11}>
                <form onSubmit={handleSubmit} noValidate>
                  <div className="content-wrapper">
                    <Grid
                      container
                      mt={2}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <h2>Email Templates</h2>

                        <div className="sub-title">
                          To customize email templates for different
                          account-related notifications.
                        </div>
                      </Grid>

                      <Grid item columnGap={2} display={"flex"}>
                        <Button onClick={handleCancel} disabled={disabledForm}>
                          CANCEL
                        </Button>

                        <Button
                          variant="contained"
                          type="submit"
                          disabled={loading}
                        >
                          {disabledForm ? " EDIT TEMPLATES" : "SAVE CHANGES"}
                        </Button>
                      </Grid>
                    </Grid>
                    {formData?.map((template) => (
                      <Grid mb={5} key={template?.email_type}>
                        <Typography my={2} variant="h5">
                          {template.email_type} e-mail
                        </Typography>
                        <Grid my={2}>
                          <CustomInputField
                            label={"Subject"}
                            required
                            disabled={disabledForm}
                            name={template?.email_type}
                            value={template?.subject}
                            onChange={(e) =>
                              handleEmailSubjectChange(e, template?.email_type)
                            }
                            error={!!formError[template?.email_type]}
                            helperText={formError[template?.email_type]}
                          />
                        </Grid>
                        <Grid my={2}>
                          <Typography variant="body1">Variables</Typography>
                          <Typography variant="body2">
                            {template?.variable?.join(", ")}
                          </Typography>
                        </Grid>
                        <QuillEditor
                          readlyOnly={disabledForm}
                          value={template?.html}
                          onChange={(data) => {
                            handleEmailBodyChange(data, template?.email_type);
                          }}
                        />
                        <FormHelperText error>
                          {formError[template?.email_type + "_html"]}
                        </FormHelperText>
                      </Grid>
                    ))}
                  </div>
                </form>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Snackbar
          open={Boolean(formError.apiError)}
          autoHideDuration={6000}
          onClose={() => setFormError({ ...formError, apiError: "" })}
          message={formError.apiError}
        />
      </Grid>
    </div>
  );
};

export default EmailTemplate;
