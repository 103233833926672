import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { List, ListItem } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import APIS from "./APIS";
import AppLogo from "../Components/AppLogo";
import CustomInputField from "../Components/ui/CustomInputField";
import GlobalLoading from "./GlobalLoading";

const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [globalLoading, setGlobalLoading] = useState(false);

  const [formStatus, setFormStatus] = useState({
    success: null,
    error: null,
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const code = queryParams.get("code");

  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
    email: email,
  });

  const [otpData, setOtpData] = useState({
    code: code,
  });

  const [validationErrors, setValidationErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    // setPasswordData({
    //   ...passwordData,
    //   [e.target.name]: e.target.value,
    // });

    setPasswordData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleContinue = (e) => {
    e.preventDefault();
    setValidationErrors({
      password: "",
      confirmPassword: "",
    });

    // Validate required fields
    if (!passwordData.password) {
      setValidationErrors({
        ...validationErrors,
        password: "New Password is required.",
      });
      return;
    }

    // Validate password complexity
    const complexityRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/;

    if (
      !complexityRegex.test(passwordData.password) ||
      passwordData.password.length < 8
    ) {
      setValidationErrors({
        ...validationErrors,
        password:
          "The password doesn't meet the required criteria. Please try again.",
      });
      return;
    }

    if (!passwordData.confirmPassword) {
      setValidationErrors({
        ...validationErrors,
        confirmPassword: "Confirm New Password is required.",
      });
      return;
    }

    // Validate password matching
    if (passwordData.password !== passwordData.confirmPassword) {
      setValidationErrors({
        ...validationErrors,
        confirmPassword: "Password does not match.",
      });
      return;
    }

    setIsButtonDisabled(true); // Disable the button on click

    // First, verify the OTP
    setGlobalLoading(true);
    APIS.VerifyOTP(otpData)
      .then((verifyOtpResponse) => {
        if (verifyOtpResponse.code == 200) {
          // If OTP verification is successful, proceed to reset the password
          const token = verifyOtpResponse.token;
          // Include the token in the password data
          const updatedPasswordData = {
            //...passwordData,
            password: passwordData.password,
            confirmPassword: passwordData.confirmPassword,
            token: token,
          };
          return APIS.ResetPassword(updatedPasswordData);
        } else {
          // OTP verification failed
          setGlobalLoading(false);
          throw new Error(
            verifyOtpResponse.message || "OTP verification failed."
          );
        }
      })
      .then((resetPasswordResponse) => {
        if (resetPasswordResponse.code == 200) {
          // Password reset successful
          setGlobalLoading(false);
          setFormStatus({
            success: resetPasswordResponse.message,
            error: null,
          });
          navigate("/Login");
        } else {
          setGlobalLoading(false);
          // Password reset failed
          throw new Error(
            resetPasswordResponse.message || "Password reset failed."
          );
        }
      })
      .catch((error) => {
        // Error occurred during the process
        setFormStatus({
          success: null,
          error: error.message || "An error occurred.",
        });
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <>
      {globalLoading && <GlobalLoading />}

      <div className="login-container">
        <AppLogo />
        <h3>Reset Password</h3>

        {formStatus.success && (
          <div className="success-message">{formStatus.success}</div>
        )}

        {formStatus.error && (
          <div className="error-message">{formStatus.error}</div>
        )}

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          onSubmit={handleContinue}
          noValidate
          autoComplete="off"
        >
          <div>
            <CustomInputField
              label="New Password"
              placeholder="Enter new password"
              name="password"
              type="password"
              required
              onChange={handleChange}
              error={!!validationErrors.password}
              helperText={validationErrors.password}
              onClickMouseDownPassword={handleMouseDownPassword}
            />
            <div className="info-sec">
              Please make sure your password contains at least 1 character from
              all of the following
              <List
                sx={{
                  listStyleType: "disc",
                  listStylePosition: "inside",
                  p: 0,
                  m: 0,
                }}
              >
                <ListItem sx={{ display: "list-item" }}>Alphabet</ListItem>
                <ListItem sx={{ display: "list-item" }}>Digit</ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  Lower and upper case
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  Special character
                </ListItem>
              </List>
              <div className="info-sec">Must be at least 8 characters</div>
            </div>
            <div>
              <CustomInputField
                label="Confirm New Password"
                placeholder="Enter confirm new password"
                name="confirmPassword"
                required
                type="password"
                onChange={handleChange}
                error={!!validationErrors.confirmPassword}
                helperText={validationErrors.confirmPassword}
                onClickMouseDownPassword={handleMouseDownPassword}
              />
            </div>

            <Button
              variant="contained"
              size="medium"
              type="submit"
              disabled={isButtonDisabled}
            >
              Confirm New Password
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
};

export default ForgotPassword;
