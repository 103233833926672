import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import AudienceFooter from "./AudienceFooter";
import Button from "@mui/material/Button";
import APIS from "./APIS";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Snackbar } from "@mui/material";
import Close from "@mui/icons-material/Close";
import CustomMap from "../Components/CustomMap";
import CustomInputField from "../Components/ui/CustomInputField";
import { decimalWith15digits } from "../helper/constant";

const TabLocationTraffic = ({
  locationTrafficData,
  setLocationTrafficData,
  navigateToAudienceSummary,
  handleTotalCount,
  audTotalCount,
  positions,
  setPositions,
  handleClearAll,
  selectedPeriodLoc,
  setSelectedPeriodLoc,
  audTotalCategories,
  handleCalculateTotalCategories,
  handleAddCondition
}) => {
  const [home_lat, setLat] = useState("");
  const [home_long, setLong] = useState("");
  const [radius, setRadius] = useState("");
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const [isRadiusValid, setIsRadiusValid] = useState(true);
  const [locTrafficError, setLocTrafficError] = useState({
    latError: "",
    longError: "",
  });

  useEffect(() => {
    calculateCount();
  }, [locationTrafficData]);

  const calculateCount = () => {
    //setTotalSelectionCount(locationTrafficData.length);
    handleCalculateTotalCategories()
  };

  const handleLatChange = (event) => {
    setLat(event.target.value);
    setLocTrafficError({ ...locTrafficError, latError: "" });
  };

  const handleLongChange = (event) => {
    setLong(event.target.value);
    setLocTrafficError({ ...locTrafficError, longError: "" });
  };

  const handleRadiusChange = (event) => {
    setRadius(event.target.value);
    setIsRadiusValid(event.target.value !== "" && event.target.value !== "0");
  };

  const handleAddRow = (event) => {
    event.preventDefault();
  
    const newErrors = validateForm();
  
    if (Object.keys(newErrors).length > 0) {
      setLocTrafficError(newErrors);
      return;
    } else {
      const isDuplicateLocation = locationTrafficData.some(
        (location) => location.home_lat === home_lat && location.home_long === home_long
      );
  
      if (isDuplicateLocation) {
        setErrorSnackbar("Location already exist.");
        return;
      }
  
      if (home_lat && home_long && locationTrafficData.length < 25) {
        setLocationTrafficData([
          ...locationTrafficData,
          { home_lat, home_long, radius: 500 },
        ]);
        setLat("");
        setLong("");
        setRadius("");
        setIsRadiusValid(true);
        setLocTrafficError({
          latError: "",
          longError: "",
        });
      } else {
        setIsRadiusValid(false);
        setErrorSnackbar("Cannot add more than 25 locations");
      }
    }
  };
  

  const handleDelete = (index) => {
    const updatedData = [...locationTrafficData];
    updatedData.splice(index, 1);
    setLocationTrafficData(updatedData);
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const validateForm = () => {
    const newErrors = {};
    const isFormatErrorLat = isNaN(home_lat) || home_lat < -90 || home_lat > 90;
    const isFormatErrorLong =
      isNaN(home_long) || home_long < -180 || home_long > 180;

    if (!home_lat) {
      newErrors.latError = "Please enter Latitude.";
    } else if (isFormatErrorLat) {
      newErrors.latError =
        "The latitude value is incorrect. Please enter a valid value.";
    } else if (!decimalWith15digits.test(home_lat)) {
      newErrors.latError = "Please enter 15 decimal numbers.";
    }
    if (!home_long) {
      newErrors.longError = "Please enter Longitude.";
    } else if (isFormatErrorLong) {
      newErrors.longError =
        "The longitude value is incorrect. Please enter a valid value.";
    } else if (!decimalWith15digits.test(home_long)) {
      newErrors.longError = "Please enter 15 decimal numbers.";
    }

    return newErrors;
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleRemovePosition = (index) => {
    setPositions((currentPositions) =>
      currentPositions.filter((_, idx) => idx !== index)
    );
  };

  const isFormValid = locationTrafficData.every(
    (rowData) => rowData.radius && rowData.radius !== "0"
  );
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleClear = () => {
    setLat("");
    setLong("");
    handleClearAll();
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriodLoc(event.target.value);
  };

  return (
    <>
      <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "5000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <Grid container spacing={2} className="content-container">
        <Grid item xs={6}>
          <CustomMap
            locationTrafficData={locationTrafficData}
            setLocationTrafficData={setLocationTrafficData}
            positions={positions}
            setPositions={setPositions}
            handleRemovePosition={handleRemovePosition}
            setErrorSnackbar={setErrorSnackbar}
          />
        </Grid>
        <Grid item xs={6}>
          <div className="content-card3">
            <div className="content-card">
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingBottom: "16px",
                }}
              >
                <label style={{ fontSize: 12, marginRight: 10 }}>
                  <b>Period:</b>
                </label>
                <select
                  className="website-select-period"
                  value={selectedPeriodLoc}
                  onChange={handlePeriodChange}
                >
                  <option value="1">1 month</option>
                  <option value="2">2 month</option>
                  <option value="3">3 month</option>
                  <option value="4">4 month</option>
                  <option value="5">5 month</option>
                  <option value="6">6 month</option>
                </select>
              </div> */}
              <div>
                <CustomInputField
                  label="Latitude"
                  id="lat"
                  value={home_lat}
                  //type="number"
                  required
                  onChange={handleLatChange}
                  error={!!locTrafficError.latError}
                  size="small"
                  placeholder="Enter Lat e.g -90 to 90"
                  helperText={locTrafficError.latError}
                />
              </div>
              <div>
                <br />
                <CustomInputField
                  label="Longitude"
                  id="long"
                  value={home_long}
                  //type="number"
                  required
                  onChange={handleLongChange}
                  error={!!locTrafficError.longError}
                  size="small"
                  placeholder="Enter Long e.g -180 to 180"
                  helperText={locTrafficError.longError}
                />
              </div>
              <br></br>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={(event) => handleAddRow(event)}
                >
                  ADD
                </Button>
              </div>
            </div>
            <br />

            <div className="sec-title loc-traffic-title">
              Location Traffic ({locationTrafficData.length})
            </div>
            <div className="scrollable-list">
              <List>
                {locationTrafficData.map((rowData, index) => (
                  <ListItem
                    key={index}
                    className="loc-traffic-list"
                    sx={{
                      //border: "1px solid #EAECF0",
                      borderRadius: "8px",
                      marginBottom: "8px",
                      padding: "0px 8px",
                    }}
                  >
                    <div className="loc-traffic-list-div">
                      <div style={{ textWrap: "wrap", width: "20%" }}>
                        <Typography component="span" sx={{ fontSize: "12px" }}>
                          Latitude: <b>{rowData.home_lat}</b>
                        </Typography>
                      </div>
                      <div style={{ textWrap: "wrap", width: "20%" }}>
                        <Typography
                          component="span"
                          sx={{ fontSize: "12px", margin: "none" }}
                        >
                          Longitude: <b>{rowData.home_long}</b>
                        </Typography>
                      </div>
                      <div style={{ textWrap: "wrap", width: "30%" }}>
                        <Typography
                          component="span"
                          sx={{ fontSize: "12px", margin: "none" }}
                        >
                          Radius:{" "}
                          <input
                            type="number"
                            style={{ width: "50px" }}
                            value={rowData.radius}
                            onChange={(e) => {
                              rowData.radius = e.target.value;
                              setLocationTrafficData([...locationTrafficData]);
                              if (!Number.isInteger(Number(e.target.value)) || e.target.value.includes('.')) {
                                setIsRadiusValid(false);
                              } else {
                                setIsRadiusValid(
                                  e.target.value !== "" &&
                                    e.target.value >= 500 &&
                                    e.target.value <= 5000
                                );
                              }
                            }}
                            onKeyDown={handleKeyDown}
                          />{" "}
                          <b>metre</b>
                        </Typography>
                        <div
                          style={{ fontSize: "10px", margin: "none" }}
                          className="c-red"
                        >
                          {rowData.radius === "" && "Please enter radius"}
                          {(rowData.radius < 500 || rowData.radius > 5000) &&
                            rowData.radius !== "" &&
                            !String(rowData.radius).includes(".") &&
                            "Radius should be between 500 to 5000."}
                          {String(rowData.radius).includes('.') &&
                            "Radius cannot have decimal."}
                        </div>
                      </div>
                      <div>
                        <IconButton
                          edge="end"
                          onClick={() => {
                            handleDelete(index);
                            handleRemovePosition(index);
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </div>
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </Grid>
        <AudienceFooter
          onNextClick={!isRadiusValid ? () => {} : navigateToAudienceSummary}
          count={audTotalCount}
          onCalculate={!isRadiusValid ? () => {} : handleTotalCount}
          totalSlectionCount={audTotalCategories}
          onChildClick={handleClear}
          disableButtons={!isFormValid}
          handleAddCondition={handleAddCondition}
        />
      </Grid>
    </>
  );
};

export default TabLocationTraffic;
