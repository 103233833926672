import { useState } from "react";
import {
  Dialog,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";
import SuccessLogo from "../../Assets/success.svg";

const ConfirmCreationDialog = ({ id, onSuccess, title, description, handleCreatePackage }) => {
  const { hideModal, hideAllModal } = useGlobalModalContext();
  const [acccount, setAccount] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    hideModal();
    onSuccess();
  };
  return (
    <Dialog
    open
    onClose={hideModal}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth={"xs"}
  >
    <Grid
      display={"grid"}
      padding={"24px"}
      justifyContent={"center"}
      rowGap={2}
    >
      <Grid display={"grid"} justifyContent={"center"}>
        <img src={SuccessLogo} />
      </Grid>

      <Typography
        display={"grid"}
        variantMapping={{ h2: 'h2' }}
        justifyContent={"center"}
        fontWeight={"bold"}
      >
        Confirm Package Creation
      </Typography>
      <Grid display={"grid"} justifyContent={"center"}>
        Do you want to proceed?
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button variant="outlined" fullWidth onClick={hideModal}>
            CANCEL
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              hideModal();
              hideAllModal();
              handleCreatePackage();
            }}
          >
            CONFIRM
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </Dialog>
  );
};

export default ConfirmCreationDialog;
