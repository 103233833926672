import React, { useEffect, useState } from "react";
import AppLogo from "../Components/AppLogo";
import DOMPurify from "dompurify";
import SwitchLangauage from "../Components/SwitchLangauage";
import useLanguage from "../helper/customhook";

function Terms() {
  const [lng, changeLanguage] = useLanguage();
  const [terms, setTerms] = useState({});

  useEffect(() => {
    getContent();
  }, [lng]);

  const getContent = async () => {
    fetch(`/content/${lng}/terms.json`)
      .then((response) => response.json())
      .then((jsonData) => setTerms(jsonData))
      .catch((error) => console.error("Error fetching the JSON data:", error));
  };

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
    <div className="login-card privacy-card">
      <AppLogo />
      <SwitchLangauage currentLang={lng} onLanguageChange={changeLanguage} />
      <div className="font-md">
        <h2 dangerouslySetInnerHTML={createMarkup(terms.title)} />
        {terms?.paragraphs?.map((paragraph, index) => (
          <div key={`paragraph-${index}`}>
            <div dangerouslySetInnerHTML={createMarkup(paragraph)} />
            <br />
          </div>
        ))}
        {terms?.subcontents?.map((content, contentIndex) => (
          <div key={`content-${contentIndex}`}>
            <h3 dangerouslySetInnerHTML={createMarkup(content.title)} />
            {content?.paragraphs?.map((paragraph, paragraphIndex) => (
              <div
                key={`content-${contentIndex}-paragraph-${paragraphIndex}`}
                dangerouslySetInnerHTML={createMarkup(paragraph)}
              />
            ))}
            <ul>
              {content?.listItems?.map((item, itemIndex) => (
                <li
                  key={`content-${contentIndex}-listItem-${itemIndex}`}
                  dangerouslySetInnerHTML={createMarkup(item)}
                />
              ))}
            </ul>
            {content?.nestedList && (
              <ul>
                {content.nestedList.map((item, itemIndex) => (
                  <li
                    key={`content-${contentIndex}-nestedItem-${itemIndex}`}
                    // Only use dangerouslySetInnerHTML if there's no subList
                    {...(item.subList ? {} : { dangerouslySetInnerHTML: createMarkup(item.item) })}
                  >
                    {/* Render the nested sublist only if subList exists */}
                    {item.subList && (
                      <>
                        <span dangerouslySetInnerHTML={createMarkup(item.item)} />
                        <ul>
                          {item.subList.map((subItem, subItemIndex) => (
                            <li
                              key={`content-${contentIndex}-nestedItem-${itemIndex}-subItem-${subItemIndex}`}
                              dangerouslySetInnerHTML={createMarkup(subItem)}
                            />
                          ))}
                        </ul>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Terms;
