import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Circle,
  Tooltip,
  ZoomControl,
  useMap,
} from "react-leaflet";
import GlobalLoading from "../Pages/GlobalLoading";
import L from "leaflet";

const TrafficMap = ({ color, locations = [], search }) => {
  const radius = 5000; // 5 km radius
  const defaultCenter = { lat: 13.7563, lng: 100.5018 }; // Default center
  const [locationsCoordinates, setLocationsCoordinates] = useState([]);
  const [globalLoading, setGlobalLoading] = useState(false);

  useEffect(() => {
    handleSetCoordinates();
  }, [locations]);

  const getRadiusByCount = (count) => {
    if (count >= 100000) return 3500; // size 7
    if (count >= 50000) return 3000; // size 6
    if (count >= 25000) return 2500; // size 5
    if (count >= 10000) return 2000; // size 4
    if (count >= 5000) return 1500; // size 3
    if (count >= 1000) return 1000; // size 2
    return 500; // size 1
  };

  const handleSetCoordinates = async () => {
    const resolvedLocations = locations?.map((item) => ({
      lat: item.lat,
      lng: item.long,
      radius: item.radius || getRadiusByCount(item.count) || radius,
      count: item.count,
    }));

    setGlobalLoading(false);
    setLocationsCoordinates(resolvedLocations);
  };

  const DynamicMapView = () => {
    const map = useMap();

    useEffect(() => {
      if (locationsCoordinates.length > 0) {
        if (locationsCoordinates.length === 1) {
          // When there is only one location, set the map view with a default zoom level
          const singleLocation = locationsCoordinates[0];
          map.setView([singleLocation.lat, singleLocation.lng], 12); // Adjust the zoom level as needed
        } else {
          // When there are multiple locations, fit the map to the bounding box
          const bounds = L.latLngBounds(
            locationsCoordinates.map((loc) => [loc.lat, loc.lng])
          );
          map.fitBounds(bounds, { padding: [50, 50] });
        }
      }
    }, [locationsCoordinates, map]);

    return null;
  };

  return (
    <>
      {globalLoading && <GlobalLoading />}
      <div style={{ display: "flex" }}>
        <MapContainer
          center={[defaultCenter.lat, defaultCenter.lng]}
          zoom={8}
          maxZoom={18}
          zoomControl={false}
          scrollWheelZoom={true}
          style={{ height: "500px", width: "600px", margin: 10 }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ZoomControl position="bottomright" />
          <DynamicMapView />
          {locationsCoordinates?.map((location, idx) =>
            location.lat && location.lng ? (
              <Circle
                key={idx}
                center={[location.lat, location.lng]}
                radius={location.radius || radius}
                pathOptions={{ color: color }}
              >
                <Tooltip>{`Count: ${location?.count}`}</Tooltip>
              </Circle>
            ) : null
          )}
        </MapContainer>
      </div>
      {locations.length == 0 && (
        <div className="sub-title-sm" style={{ color: 'red'}}>The input filter(s) does not have complete location data</div>
      )}
    </>
  );
};

export default TrafficMap;
