import CustomTable from "../ui/Table/CustomTable";
import { formatDate } from "../../helper/util";
import { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import ApproveUserDialog from "./ApproveUserDialog";
import EditUserDialog from "./EditUserDialog";
import DeactivateUserDialog from "./DeactivateUserDialog";
import ReactivateUserDialog from "./ReactivateUserDialog";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";

const UserAccountTable = ({
  count,
  data,
  rowPerPage,
  page,
  handlePageChange,
  handleChangeRowsPerPage,
  onSuccess,
  loading,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { showModal } = useGlobalModalContext();
  const [selectedRow, setSelectedRow] = useState("");

  const getStatusDot = (status) => {
    const dotStyle = {
      marginRight: "5px",
    };

    if (status?.toLocaleLowerCase() === "active") {
      return <span style={{ ...dotStyle, color: "green" }}>●</span>;
    } else if (status?.toLocaleLowerCase() === "pending approval") {
      return <span style={{ ...dotStyle, color: "yellow" }}>●</span>;
    } else if (status?.toLocaleLowerCase() === "deactivate") {
      return <span style={{ ...dotStyle, color: "red" }}>●</span>;
    } else if (status?.toLocaleLowerCase() === "inactive") {
      return <span style={{ ...dotStyle, color: "red" }}>●</span>;
    }
  };
  const columns = [
    {
      name: "No.",
      id: "index",
    },
    {
      name: "User ID",
      id: "id",
    },
    {
      name: "Email",
      id: "email",
    },
    {
      name: "First-Last Name",
      id: "name",
    },
    {
      name: "Job Title",
      id: "job_title",
    },
    {
      name: "Contact No.",
      id: "contact",
    },
    {
      name: "Company",
      id: "account_name",
    },
    {
      name: "Role",
      id: "role",
    },
    {
      name: "Status",
      id: "status",
      element: (row) => (
        <>
          {getStatusDot(row.status)}
          {`${row.status}`}
        </>
      ),
    },
    {
      name: "Added Date",
      id: "create_date",
      minWidth: 100,
      element: (row) => `${formatDate(row?.create_date?.value)}`,
    },
    {
      id: "id",
      element: (row) => {
        const buttonId = "basic-button";
        const menuId = "basic-menu";
        const open = Boolean(anchorEl);
        return (
          <>
            <Button
              id={buttonId}
              aria-controls={open ? menuId : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setSelectedRow(row);
              }}
            >
              <MoreVert />
            </Button>

            <Menu
              id={menuId}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": buttonId,
              }}
            >
              {selectedRow.status?.toLocaleLowerCase() ==
                "pending approval" && (
                <MenuItem
                  onClick={() =>
                    showModal(
                      <ApproveUserDialog
                        onSuccess={() => {
                          onSuccess();
                          setAnchorEl(null);
                        }}
                        selectedUser={selectedRow}
                      />
                    )
                  }
                >
                  Approve User
                </MenuItem>
              )}
              <MenuItem
                onClick={() =>
                  showModal(
                    <EditUserDialog
                      onSuccess={() => {
                        onSuccess();
                        setAnchorEl(null);
                      }}
                      selectedUser={selectedRow}
                    />
                  )
                }
              >
                Edit User
              </MenuItem>
              {selectedRow.status?.toLocaleLowerCase() == "active" && (
                <MenuItem
                  onClick={() =>
                    showModal(
                      <DeactivateUserDialog
                        onSuccess={() => {
                          onSuccess();
                          setAnchorEl(null);
                        }}
                        email={selectedRow.email}
                      />
                    )
                  }
                >
                  Deactivate User
                </MenuItem>
              )}
              {selectedRow.status?.toLocaleLowerCase() == "inactive" && (
                <MenuItem
                  onClick={() =>
                    showModal(
                      <ReactivateUserDialog
                        onSuccess={() => {
                          onSuccess();
                          setAnchorEl(null);
                        }}
                        email={selectedRow.email}
                      />
                    )
                  }
                >
                  Reactivate User
                </MenuItem>
              )}
            </Menu>
          </>
        );
      },
    },
  ];
  return (
    <CustomTable
      columns={columns}
      data={data}
      count={count}
      rowPerPage={rowPerPage}
      isLoading={loading}
      page={page}
      handlePageChange={handlePageChange}
      handleRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default UserAccountTable;
