import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { removeUnderscore, capitalizeFirstLetter } from "../helper/util";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

// Function to transform the dataset to chart data
const transformData = (dataset) => {
  return {
    labels: dataset.map(item => item.category),
    datasets: [
      {
        label: "Percentage",
        data: dataset.map(item => parseFloat(item.percentage)),
        backgroundColor: [
          "#8B0000",
          "#A52A2A",
          "#B22222",
          "#CD5C5C",
          "#F08080"
        ],
      }
    ],
  };
};

export default function BarChartCustom({ data, label }) {
  // Check if data is available and not empty
  if (!data || data.length === 0) {
    return <div></div>;
  }

  // Calculate the maximum percentage value from the dataset
  const maxPercentage = Math.max(...data.map(item => parseFloat(item.percentage)));

  const stageOptions = {
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: label,
        font: {
          size: 16,
        },
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#000',
        font: {
          weight: 'bold',
        },
        clip: false,
        formatter: (value, context) => {
          return `${value}%`;
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: maxPercentage * 1.2,
        ticks: {
          display: true,
        },
        grid: {
          display: false,
        },
      },
      y: {
        type: 'category', // Set y-axis type to category
        labels: data.map(item => capitalizeFirstLetter(removeUnderscore(item.category))), // Use the formatted labels
        ticks: {
          display: true,
        },
        grid: {
          display: false,
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <Bar options={stageOptions} data={transformData(data)} />
  );
}





