const PutData = (url = ``, data = {}, token) => {
      const headers = {
          'Content-Type': "application/json; charset=utf-8"
      };
  
      if (token) {
          headers['Authorization'] = `Bearer ${token}`;
      }
  
      return fetch(url, {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(data),
      })
      .then(response => response.json())
      .catch(error => console.error(`Fetch Error =\n`, error));
  };
  
  export default PutData;
  