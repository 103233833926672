import deleteData from "../ServiceAPI/deleteData";
import postData from "../ServiceAPI/postData";
import PutData from "../ServiceAPI/putData";
import getData from "../ServiceAPI/getData";

//TODO: IP to be replaced by domain
// const BASE_URL = "http://35.244.254.38/api/v1";
// const BASE_URL_V2 = "http://35.244.254.38/api/v2";

const BASE_URL = "https://truemartech-dev.com/api/v1"; // UAT
//const BASE_URL = "https://b2badtech-sit.truemartech-dev.com/api/v1"; // SIT

const APIS = {
  Registration: async function (formData) {
    return await postData(`${BASE_URL}/user/register`, formData, "");
  },

  CreatePassword: async function (formData) {
    return await PutData(`${BASE_URL}/user/createPassword`, formData, "");
  },
  ConfirmEmailResponse: async function (formData) {
    return await postData(`${BASE_URL}/user/confirmEmail`, formData, "");
  },
  ChangeRegistrationUserStatus: async function (formData, token) {
    return await PutData(`${BASE_URL}/user/changeStatus`, formData, token);
  },
  Login: async function (formData) {
    return await postData(`${BASE_URL}/user/login`, formData, "");
  },
  ForgotPassword: async function (formData) {
    return await PutData(`${BASE_URL}/user/forgotPassword`, formData, "");
  },
  ResetPassword: async function (formData) {
    return await PutData(`${BASE_URL}/user/resetPassword`, formData, "");
  },
  VerifyOTP: async function (formData) {
    return await postData(`${BASE_URL}/user/verifyOtp`, formData, "");
  },
  CreateProject: async function (formData, token) {
    return await postData(`${BASE_URL}/project/createProject`, formData, token);
  },
  ViewProjectDetail: async function (formData, token) {
    return await postData(
      `${BASE_URL}/project/viewProjectDetails`,
      formData,
      token
    );
  },
  RenameProjectDetail: async function (formData, token) {
    return await PutData(`${BASE_URL}/project/renameProject`, formData, token);
  },
  DeleteProjectDetail: async function (formData, token) {
    return await deleteData(
      `${BASE_URL}/project/deleteProject`,
      formData,
      token
    );
  },
  getCustomerInterest: async function (token) {
    return await getData(`${BASE_URL}/audience/customerInterest`, token);
  },
  getCommercialRelevant: async function (token) {
    return await getData(`${BASE_URL}/audience/commercialRelevant`, token);
  },
  getDemographics: async function (token) {
    return await getData(`${BASE_URL}/audience/demographics`, token);
  },
  getGeographics: async function (token) {
    return await getData(`${BASE_URL}/audience/geographic`, token);
  },
  calculateCountCustomerInterest: async function (formData, token) {
    return await postData(
      `${BASE_URL}/audience/customerInterest/calculate`,
      formData,
      token
    );
  },
  calculateCountCommercialRelevant: async function (formData, token) {
    return await postData(
      `${BASE_URL}/audience/commercialRelevant/calculate`,
      formData,
      token
    );
  },
  calculateCountDemographics: async function (formData, token) {
    return await postData(
      `${BASE_URL}/audience/demographics/calculate`,
      formData,
      token
    );
  },
  calculateCountGeographics: async function (formData, token) {
    return await postData(
      `${BASE_URL}/audience/geographic/calculate`,
      formData,
      token
    );
  },
  calculateCountLocationTraffic: async function (formData, token) {
    return await postData(
      `${BASE_URL}/audience/locationTraffic/calculate`,
      formData,
      token
    );
  },
  calculateCountWebsiteTraffic: async function (formData, token) {
    return await postData(
      `${BASE_URL}/audience/websiteTraffic/calculate`,
      formData,
      token
    );
  },
  calculateTotalCount: async function (formData, token) {
    return await postData(`${BASE_URL}/audience/calculate`, formData, token);
  },
  createAudience: async function (formData, token) {
    return await postData(`${BASE_URL}/audience/create`, formData, token);
  },
  deleteAudience: async function (formData, token) {
    return await deleteData(`${BASE_URL}/audience/delete`, formData, token);
  },
  getAudienceSummary: async function (token, audienceId) {
    return await getData(
      `${BASE_URL}/audience/summary?audienceId=${audienceId}`,
      token
    );
  },
  getAudienceInsights: async function (token, audienceId) {
    return await getData(
      `${BASE_URL}/audience/insights?audienceId=${audienceId}`,
      token
    );
  },
  getAudienceLocInsights: async function (token, audienceId) {
    return await getData(
      `${BASE_URL}/audience/location/insights?audienceId=${audienceId}`,
      token
    );
  },
  getAudienceInsightsForSummary: async function (token, formData) {
    return await postData(
      `${BASE_URL}/audience/insights`,
      formData,
      token
    );
  },
  getAudienceLocInsightsForSummary: async function (token, formData) {
    return await postData(
      `${BASE_URL}/audience/location/insights`,
      formData,
      token
    );
  },
  getProjectList: async function (token) {
    return await getData(
      `${BASE_URL}/audience/getAudienceCustomerInterest`,
      token
    );
  },
  getAudienceList: async function (token, queryString) {
    return await getData(
      `${BASE_URL}/audience/audienceList?${queryString}`,
      token
    );
  },
  getMetaAdsAccounts: async function (token) {
    return await getData(`${BASE_URL}/meta/accounts`, token);
  },
  getMetaLoadAccounts: async function (token) {
    return await getData(`${BASE_URL}/meta/accounts/load`, token);
  },
  getGoogleAdsAccounts: async function (token) {
    return await getData(`${BASE_URL}/googleAds/accounts`, token);
  },
  getTikTokAdsAccounts: async function (token) {
    return await getData(`${BASE_URL}/tiktok/accounts`, token);
  },
  pushAudienceGoogle: async (token, data) => {
    return await postData(`${BASE_URL}/googleAds/audiencePush`, data, token);
  },
  pushAudienceMeta: async (token, data) => {
    return await postData(`${BASE_URL}/meta/audiencePush`, data, token);
  },
  pushAudienceTiktok: async (token, data) => {
    return await postData(`${BASE_URL}/tiktok/audiencePush`, data, token);
  },
  getMyAccount: async function (token, queryString) {
    return await getData(`${BASE_URL}/package/mappings?${queryString}`, token);
  },
  getChannelSetup: async function (token, userId) {
    return await getData(`${BASE_URL}/channel/setup?user_id=${userId}`, token);
  },
  getGoogleOAuth: async function (token, redirectUri) {
    return await getData(
      `${BASE_URL}/googleAds/oauth/url`,
      token
    );
  },
  postGoogleRefreshToken: async function (token, formData) {
    return await postData(
      `${BASE_URL}/googleAds/refreshtoken`,
      formData,
      token
    );
  },
  getMetaOAuth: async function (token) {
    return await getData(`${BASE_URL}/meta/oauth/url`, token);
  },
  postMetaRefreshToken: async function (token, formData) {
    return await postData(`${BASE_URL}/meta/accesstoken`, formData, token);
  },
  getTiktokOAuth: async function (token) {
    return await getData(`${BASE_URL}/tiktok/oauth/url`, token);
  },
  postTiktokRefreshToken: async function (token, formData) {
    return await postData(`${BASE_URL}/tiktok/accesstoken`, formData, token);
  },
  getAudiencePushHistory: async function (token, audienceId) {
    return await getData(
      `${BASE_URL}/audience/pushHistory?audienceId=${audienceId}`,
      token
    );
  },
  getMyAccountList: async function (token, query) {
    return await getData(`${BASE_URL}/account/list?${query}`, token);
  },
  getMyAccountNameList: async function (token) {
    return await getData(`${BASE_URL}/account/name`, token);
  },
  getUserList: async function (token, query) {
    return await getData(`${BASE_URL}/user/list?${query}`, token);
  },
  getAccountPackageHistory: async function (token, acountId) {
    return await getData(
      `${BASE_URL}/package/history?accountId=${acountId}`,
      token
    );
  },
  getPackageUserHistory: async function (token) {
    return await getData(`${BASE_URL}/package/user/history`, token);
  },
  getAccountPackageSummary: async function (token, acountId) {
    return await getData(
      `${BASE_URL}/package/summary?accountId=${acountId}`,
      token
    );
  },
  checkEmailDuplicate: async function (formData) {
    return await postData(`${BASE_URL}/user/email/checkDuplicate`, formData);
  },
  renameAccount: async function (formData, token) {
    return await PutData(`${BASE_URL}/account/renameAccount`, formData, token);
  },
  createAccount: async function (formData, token) {
    return await postData(`${BASE_URL}/account/createAccount`, formData, token);
  },
  changeUserStatus: async function (formData, token) {
    return await PutData(`${BASE_URL}/user/changeStatus`, formData, token);
  },
  updateUser: async function (formData, token) {
    return await PutData(`${BASE_URL}/user/updateUser`, formData, token);
  },
  deactivateUser: async function (formData, token) {
    return await postData(`${BASE_URL}/user/deactivateUser`, formData, token);
  },
  reactivateUser: async function (formData, token) {
    return await postData(`${BASE_URL}/user/reactivateUser`, formData, token);
  },
  getPackageList: async function (token, queryParams) {
    return await getData(`${BASE_URL}/package/list?${queryParams}`, token);
  },
  getMainPackageList: async function (token, queryParams) {
    return await getData(`${BASE_URL}/package/main?${queryParams}`, token);
  },
  getTopupPackageList: async function (token, queryParams) {
    return await getData(`${BASE_URL}/package/topup?${queryParams}`, token);
  },
  deactivatePackage: async function (formData, token) {
    return await postData(`${BASE_URL}/package/deactivate`, formData, token);
  },
  createMainPackage: async function (formData, token) {
    return await postData(`${BASE_URL}/package/main`, formData, token);
  },
  createTopupPackage: async function (formData, token) {
    return await postData(`${BASE_URL}/package/topup`, formData, token);
  },
  addMainPackage: async function (formData, token) {
    return await postData(`${BASE_URL}/mapPackage/main`, formData, token);
  },
  addTopupPackage: async function (formData, token) {
    return await postData(`${BASE_URL}/mapPackage/topup`, formData, token);
  },
  getPackageSummaryReport: async function (token, queryParams) {
    return await getData(
      `${BASE_URL}/report/getPackageSummaryReport?${queryParams}`,
      token
    );
  },
  getAudienceSummaryReport: async function (token, queryParams) {
    return await getData(
      `${BASE_URL}/report/getAudienceUsageSummaryReport?${queryParams}`,
      token
    );
  },
  getUserProfile: async function (token) {
    return await getData(`${BASE_URL}/user/getUserProfile`, token);
  },
  updateUserProfile: async function (data, token) {
    return await PutData(`${BASE_URL}/user/updateUserProfile`, data, token);
  },
  ChangePassword: async function (formData, token) {
    return await PutData(`${BASE_URL}/user/changePassword`, formData, token);
  },
  checkQuota: async function (formData, token) {
    return await postData(`${BASE_URL}/package/quotaCheck`, formData, token);
  },
  getMetaAccounts: async function (token) {
    return await getData(`${BASE_URL}/meta/accounts/load`, token);
  },
  getMaingPackageName: async function (token) {
    return await getData(`${BASE_URL}/package/main/name`, token);
  },
  getTopupPackageName: async function (token) {
    return await getData(`${BASE_URL}/package/topup/name`, token);
  },

  downloadReport: async function (token, queryParams) {
    try {
      const response = await fetch(
        `${BASE_URL}/report/summary?${queryParams}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return response.blob(); // parses response to Blob
    } catch (error) {
      console.error(`Fetch Error =\n`, error);
      throw error;
    }
  },
  disconnectChannel: async function (formData, token) {
    return await postData(`${BASE_URL}/channel/disconnect`, formData, token);
  },
  getEmailTemplates: async function (token) {
    return await getData(`${BASE_URL}/email/getEmailTemplate`, token);
  },
  updateEmailTemplates: async function (formData, token) {
    return await postData(`${BASE_URL}/email/template`, formData, token);
  },
  getAudienceName: async function (token) {
    return await getData(`${BASE_URL}/audience/audienceList/name`, token);
  },
};

export default APIS;
