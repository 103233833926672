import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Select from "../Components/MultiSelectDropdown";
import AudienceFooter from "./AudienceFooter";
import APIS from "./APIS";
import { IconButton, Snackbar } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { convertToCamelCase } from "../helper/util";

const Demographics = ({
  demographicsData,
  demographicsselectedFilters,
  setDemographicsselectedFilters,
  handleNextTab,
  handleTotalCount,
  audTotalCount,
  handleClearAll,
  audTotalCategories,
  handleCalculateTotalCategories,
  handleAddCondition
}) => {
  const [activeClass, setActiveClass] = useState({});
  const [errorSnackbar, setErrorSnackbar] = useState("");

  useEffect(() => {
    calculateCount();
  }, [demographicsselectedFilters]);

  const handleOptionChange = (filterType, selectedValues, event, isBtn) => {
    if (isBtn) {
      const elements = document.querySelectorAll("." + filterType);
      elements.forEach((element) => {
        if (element !== event.currentTarget) {
          element.classList.remove("active");
        }
      });

      if (event.currentTarget != undefined) {
        event.currentTarget.classList.toggle("active");
      }

      setDemographicsselectedFilters((prevOptions) => ({
        ...prevOptions,
        [filterType]: selectedValues.map((value) => value)
      }));
    } else {
      setDemographicsselectedFilters((prevOptions) => {
        if (selectedValues.length === 0) {
          const { [filterType]: _, ...rest } = prevOptions;
          return rest;
        } else {
          return {
            ...prevOptions,
            [filterType]: selectedValues.map((value) => value),
          };
        }
      });
    }
  };


  const calculateCount = () => {
    // let selectedCount = Object.keys(demographicsselectedFilters)
    //   .map((filterType) => demographicsselectedFilters[filterType]?.length || 0)
    //   .reduce((acc, count) => acc + count, 0);

    // let totalSelectionCount = selectedCount;

    // setAudTotalCategories(totalSelectionCount);
    handleCalculateTotalCategories()
  };

  const convertCount = (count) => {
    let convertedCount = count;
    if (count >= 1000000) {
      convertedCount = (count / 1000000).toFixed(2) + "M";
    } else if (count >= 1000) {
      convertedCount = (count / 1000).toFixed(2) + "K";
    } else {
      convertedCount = count;
    }
    return convertedCount;
  };

  const renderFilter = (filterType, options, index) => {
    return (
      <Grid item xs={6} key={filterType + index}>
        <label className="audience-label">
          {convertToCamelCase(filterType)}
        </label>
        <br />
        {filterType === "Gender" ? (
          options.map((option) => (
            <Button
              className={`form-btn ${activeClass[filterType] ? "active" : ""}`}
              key={option.value}
              variant="outlined"
              sx={{ mr: 0.5, width: "140px" }}
              onClick={(event) =>
                handleOptionChange(filterType, [option.value], event, true)
              }
            >
              {option.value}
            </Button>
          ))
        ) : (
          <Select
            onOptionChange={(selectedValues, event) =>
              handleOptionChange(filterType, selectedValues, event, false)
            }
            colourOptions={options
              .filter((filter) => filter.value !== null)
              .map((filter) => ({
                value: filter.value,
                label: filter.value,
                sublabel: convertCount(filter.count),
              }))}
            optionSelected={demographicsselectedFilters[filterType] || []}
          />
        )}
      </Grid>
    );
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <div className="sec-title">Demographics</div>
      <Grid container spacing={2} className="content-container">
        {Object.entries(demographicsData).map(([filterType, filterData]) =>
          renderFilter(filterType, filterData.filters)
        )}
      </Grid>
      <div>
        <AudienceFooter
          count={audTotalCount}
          onCalculate={handleTotalCount}
          totalSlectionCount={audTotalCategories}
          onChildClick={handleClearAll}
          onNextClick={handleNextTab}
          handleAddCondition={handleAddCondition}
        />
      </div>
      <Snackbar
            open={errorSnackbar ? true : false}
            autoHideDuration={6000}
            onClose={handleCloseSanckBar}
            message={errorSnackbar}
            action={action}
            sx={{ zIndex: "5000" }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          />
    </div>
  );
};

export default Demographics;
