import * as React from "react";
import Button from "@mui/material/Button";
import InterestsIcon from "@mui/icons-material/Interests";
import Footer from "../Components/Footer";
import { Grid } from "@mui/material";
import { Add } from "@mui/icons-material";

const AudienceFooter = ({
  totalSlectionCount,
  onChildClick,
  onCalculate,
  count,
  onNextClick,
  handleAddCondition,
}) => {
  const handleClearAll = () => {
    if (onChildClick) {
      onChildClick();
    }
  };

  const convertCount = (count) => {
    let convertedCount = count;
    if (count >= 1000000) {
      convertedCount = (count / 1000000).toFixed(2) + "M";
    } else if (count >= 1000) {
      convertedCount = (count / 1000).toFixed(2) + "K";
    } else {
      convertedCount = count;
    }
    return convertedCount;
  };

  return (
    <>
      <div className="demo-ft-card">
        <Grid
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <div className="first-item">
            <Button variant="text" onClick={handleClearAll}>
              Clear All
            </Button>
            <Button
              variant="text"
              onClick={handleAddCondition}
              disabled={totalSlectionCount < 1}
              sx={{
                color: totalSlectionCount > 0 ? "#000000 !important" : "",
                fontWeight: "700 !important",
              }}
            >
              <Add />
              Add Condition
            </Button>
          </div>
          <div className="center-item">
            <div>
              <b>Selected {totalSlectionCount} Categories</b>{" "}
            </div>
            <div>
              <b>Estimate Size {convertCount(count)}</b>
            </div>
          </div>
          <div className="last-item">
            <Button
              variant="contained"
              onClick={onCalculate}
              sx={{
                borderRadius: "4px !important",
                backgroundColor: "#EDEEF6 !important",
                color: "#4F5464 !important",
                fontWeight: "700 !important",
              }}
            >
              Calculate
            </Button>
            <Button
              variant="contained"
              onClick={onNextClick}
              sx={{
                borderRadius: "4px !important",
                fontWeight: "700 !important",
              }}
            >
              Audience Summary
            </Button>
          </div>
        </Grid>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};
export default AudienceFooter;
