import { useState, useEffect } from "react";
import {
  Dialog,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { CustomInputField } from "../ui";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";
import APIS from "../../Pages/APIS";
import { useAuthContext } from "../AuthContextProvider";
import { emailRegx, mobileRegx } from "../../helper/constant";

const AccountDialog = ({ selectedAccount, onSuccess }) => {
  const initialFormData = {
    accountName: selectedAccount ? selectedAccount.account_name : "",
    contactMobileNo: "",
    contactEmail: "",
    contactName: "",
  };
  const [formError, setFormError] = useState({
    accountName: "",
    contactMobileNo: "",
    contactEmail: "",
    contactName: "",
    apiError: "",
  });
  const { hideModal } = useGlobalModalContext();
  const [formData, setFormData] = useState(initialFormData);
  const [status, setStatus] = useState({
    isLoading: false,
    isFormChanged: false,
  });

  const { token } = useAuthContext();

  useEffect(() => {
    const hasChanged = Object.keys(formData).some(
      (key) => formData[key] !== initialFormData[key]
    );
    setStatus({ ...status, isFormChanged: hasChanged });
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      setStatus({ ...status, isLoading: true });
      if (selectedAccount) {
        const response = await APIS.renameAccount(
          { id: selectedAccount.id, accountName: formData.accountName },
          token
        );
        if (response.code == 200) {
          hideModal();
          onSuccess();
        } else {
          setFormError({
            ...formError,
            apiError: response?.message ?? "Rename Api Failed",
          });
        }
        setStatus({ ...status, isLoading: false });
      } else {
        const response = await APIS.createAccount(formData, token);
        if (response.status == "success") {
          hideModal();
          onSuccess();
        } else {
          setFormError({
            ...formError,
            apiError: response?.message ?? "Create Api Failed",
          });
        }
        setStatus({ ...status, isLoading: false });
      }
    } else {
      setFormError(newErrors);
    }

    // hideModal();
    // onSuccess();
  };

  const validateForm = () => {
    const newErrors = {};
    if (selectedAccount) {
      if (!formData.accountName) {
        newErrors.accountName = "This field is required.";
      }
    } else {
      if (!formData.accountName) {
        newErrors.accountName = "This field is required.";
      }
      if (!formData.contactEmail) {
        newErrors.contactEmail = "This field is required.";
      } else if (!emailRegx.test(formData.contactEmail)) {
        newErrors.contactEmail = "Email address is invalid.";
      }

      if (!formData.contactMobileNo) {
        newErrors.contactMobileNo = "This field is required.";
      } else if (!mobileRegx.test(formData.contactMobileNo)) {
        newErrors.contactMobileNo = "Contact is invalid.";
      }

      if (!formData.contactName) {
        newErrors.contactName = "This field is required.";
      }
    }

    return newErrors;
  };
  return (
    <Dialog open onClose={hideModal} maxWidth="xs">
      <Grid p={3}>
        <form onSubmit={handleSubmit} noValidate>
          <Typography variant="h5">
            {selectedAccount ? "Rename Company" : "Create New Company"}
          </Typography>
          <Grid container spacing={2} sx={12} mt={1} gap={1}>
            <Grid item xs={12}>
              <CustomInputField
                size={"small"}
                required
                label={"Company Name"}
                id="accountName"
                name="accountName"
                value={formData.accountName}
                maxLength={50}
                onChange={handleChange}
                placeholder="Account Name"
                error={!!formError.accountName}
                helperText={formError.accountName}
              />
              {!selectedAccount && (
                <Grid my={2}>
                  <CustomInputField
                    size={"small"}
                    required
                    label={"Contact No."}
                    id="contactMobileNo"
                    name="contactMobileNo"
                    onChange={handleChange}
                    placeholder="Contact"
                    error={!!formError.contactMobileNo}
                    helperText={formError.contactMobileNo}
                  />
                </Grid>
              )}
              {!selectedAccount && (
                <CustomInputField
                  size={"small"}
                  required
                  label={"Contact Email"}
                  id="contactEmail"
                  name="contactEmail"
                  onChange={handleChange}
                  placeholder="Contact Email"
                  error={!!formError.contactEmail}
                  helperText={formError.contactEmail}
                />
              )}
              {!selectedAccount && (
                <Grid my={2}>
                  <CustomInputField
                    size={"small"}
                    required
                    label={"Contact Name"}
                    id="contactName"
                    name="contactName"
                    onChange={handleChange}
                    placeholder="Contact Name"
                    error={!!formError.contactName}
                    helperText={formError.contactName}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                <Button fullWidth onClick={hideModal} variant="contained">
                  CANCEL
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={!!!status.isFormChanged || status.isLoading}
                >
                  {status.isLoading ? (
                    <CircularProgress />
                  ) : selectedAccount ? (
                    "RENAME"
                  ) : (
                    "CREATE"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Snackbar
        open={Boolean(formError.apiError)}
        autoHideDuration={6000}
        onClose={() => setFormError({ ...formError, apiError: "" })}
        message={formError.apiError}
      />
    </Dialog>
  );
};

export default AccountDialog;
