import {
  Select,
  MenuItem,
  Typography,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@mui/material";

const CustomSelect = ({
  id,
  fullWidth = true,
  options = [],
  label,
  value,
  size = "small",
  onChange,
  placeholder = "Placeholder",
  error = false,
  helperText,
  required = false,
  name,
  inputProps,
  disabled = false,
}) => {
  return (
    <FormControl
      fullWidth={fullWidth}
      required={required}
      size={size}
      error={error}
      disabled={disabled}
    >
      <FormLabel style={{ fontSize: "14px" }}>{label}</FormLabel>
      <Select
        id={id}
        fullWidth={fullWidth}
        required={required}
        displayEmpty
        value={value}
        onChange={onChange}
        size={size}
        inputProps={{ name, ...inputProps }}
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Typography fontSize={"13px"} color={"gray"}>
                {placeholder}
              </Typography>
            );
          }

          return options.find((data) => data.value === selected)?.label || '';
        }}
      >
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value} disabled={item.isDisabled}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomSelect;
