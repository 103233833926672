import React, { useState, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

const initalState = {
  token: "",
  setToken: () => {
    return;
  },
  removeToken: () => {
    return;
  },
  setRole: () => {
    return;
  },
  setEmail: () => {
    return;
  },
  removeRole: () => {
    return;
  },
  setUserId: () => {
    return;
  },
  isAdmin: () => {
    return;
  },
  login: () => {
    return;
  },
  logout: () => {
    return;
  },
  role: "",
  userId: "",
  isAuthenticated: false,
  email: "",
};

const AuthContext = createContext(initalState);

export const AuthContextProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [email, setEmail] = useState(localStorage.getItem("loogedIn_User"));
  const [accountId, setAccountId] = useState(localStorage.getItem("accountId"));
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const login = (response) => {
    const currentTime = new Date().getTime();
    localStorage.setItem("token", response.token);
    localStorage.setItem("loogedIn_User", response.data.email);
    localStorage.setItem("userId", response.data.id);
    localStorage.setItem("role", response.data.role);
    localStorage.setItem("accountId", response.data.account_id);
    localStorage.setItem("lastActivityTime", currentTime);
    localStorage.setItem("cookieConsent", true);
    setIsAuthenticated(true);
    setRole(response.data.role);
    setToken(response.token);
    setUserId(response.data.id);
    setEmail(response.data.email);
    setAccountId(response.data.account_id);
    navigate("/MyAccount");
  };

  const logout = () => {
    setRole("");
    setToken("");
    setUserId("");
    setEmail("");
    setAccountId("");
    setIsAuthenticated(false);
    localStorage.removeItem("loogedIn_User");
    localStorage.removeItem("user");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
    localStorage.removeItem("accountId");
    localStorage.removeItem("lastActivityTime");
    navigate("/Login");
  };

  const removeRole = () => {
    setRole("");
  };

  const removeToken = () => {
    setToken("");
  };

  const isAdmin = () => role === "admin";

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        userId,
        setUserId,
        role,
        setRole,
        removeRole,
        removeToken,
        isAdmin,
        email,
        setEmail,
        login,
        logout,
        isAuthenticated,
        accountId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
