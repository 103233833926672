import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const StackedBarChartCustom = ({ data }) => {
  const chartData = {
    labels: data?.map(item => item.age), // Use the 'age' field for labels
    datasets: [
      {
        label: 'Male',
        data: data?.map(item => item.gender.male),
        backgroundColor: "#2e86c1", // color for Male
      },
      {
        label: 'Female',
        data: data?.map(item => item.gender.female),
        backgroundColor: "#cb4335", // color for Female
      },
      {
        label: 'Nondisclosed',
        data: data?.map(item => item.gender.nondisclosed),
        backgroundColor:  "#d4ac0d", // color for Nondisclosed
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        onClick: () => {},
      },
      tooltip: {
        displayColors: false, // Removes the color box next to each label
        callbacks: {
          label: function (context) {
            const index = context.dataIndex;
            const dataset = context.chart.data.datasets;
            
            const maleCount = dataset[0].data[index];
            const femaleCount = dataset[1].data[index];
            const nondisclosedCount = dataset[2].data[index];

            return [
              `Male: ${maleCount}`,
              `Female: ${femaleCount}`,
              `Non Disclosed: ${nondisclosedCount}`
            ];
          },
        },
      },
      datalabels: {
        color: 'black', // Set datalabel color to black for better contrast on top
        font: {
          weight: 'bold',
        },
        formatter: (value, context) => {
          const dataset = context.chart.data.datasets;
          const index = context.dataIndex;

          const total = dataset.reduce((sum, dataset) => sum + dataset.data[index], 0);

          // Display the total value only for the last dataset (so it appears on top)
          if (context.datasetIndex === dataset.length - 1) {
            return total;
          }

          return ''; // Return empty string for other datasets
        },
        anchor: 'end',
        align: 'end',
      },
    },
    scales: {
      x: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          stepSize: 500, // Adjust this value to control tick intervals based on your data
        },
      },
      y: {
        stacked: true,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default StackedBarChartCustom;
