import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { useAuthContext } from "./AuthContextProvider";
import AppLogoImg from "../Assets/AdTech_B2B_Logo.svg";
import { Help } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function Navbar() {
  const user = localStorage.getItem("loogedIn_User");
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { logout } = useAuthContext();
  const { i18n } = useTranslation();
  const storedLanguage = localStorage.getItem("lng");

  useEffect(() => {
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }

    const handleStorageChange = (event) => {
      if (event.key === "lng") {
        i18n.changeLanguage(event.newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [storedLanguage, i18n]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const handleAvatar = () => {
    if (user) {
      return user?.substring(0, 2).toUpperCase();
    } else {
      return "";
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Set anchorEl to the clicked element
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the menu by setting anchorEl to null
  };

  const showHelp = () => {
    window.open("/Help", "_blank");
  };

  const showAbout = () => {
    window.open("/About", "_blank");
  };

  return (
    <div className="logo">
      <div className="navbar-logo">
        <img src={AppLogoImg} alt="Ad Tech" />
      </div>
      {user && (
        <div className="navbar-right">
          {/* <div className="font-md c-black link" onClick={showAbout}>
            <b>About</b>
          </div> */}
          {/* <div className="label-with-tooltip">
            <IconButton
              size="small"
              onClick={() => changeLanguage("en")}
              sx={{
                color: storedLanguage === "en" ? "red" : "inherit",
                fontSize: "14px",
              }}
            >
              <b>EN</b>
            </IconButton>
            <div className="font-md c-black">
              {" "}
              <b> | </b>{" "}
            </div>
            <IconButton
              size="small"
              onClick={() => changeLanguage("th")}
              sx={{
                color: storedLanguage === "th" ? "red" : "inherit",
                fontSize: "14px",
              }}
            >
              <b>TH</b>
            </IconButton>
          </div> */}
          <div className="avatar">
            <Avatar onClick={handleClick}>{handleAvatar()}</Avatar>
            <Menu
              id="avatar-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={showAbout}>
                About
              </MenuItem>{" "}
              <MenuItem onClick={() => navigate("/UserProfile")}>
                User Profile
              </MenuItem>
              <MenuItem onClick={logout}>Sign out</MenuItem>
            </Menu>
          </div>
          <div className="font-md c-black link" onClick={showHelp}>
            <b>Help</b>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
