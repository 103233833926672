import CustomTable from "../ui/Table/CustomTable";
import { formatDate } from "../../helper/util";
import { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";

const ReportTable = ({
  count,
  data,
  rowPerPage,
  page,
  handlePageChange,
  handleRowsPerPageChange,
  onSuccess,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [id, setId] = useState("");

  const getStatusDot = (status) => {
    const dotStyle = {
      marginRight: "5px",
    };

    if (status?.toLocaleLowerCase() === "active") {
      return <span style={{ ...dotStyle, color: "green" }}>●</span>;
    } else if (status?.toLocaleLowerCase() === "inactive") {
      return <span style={{ ...dotStyle, color: "#FFA500" }}>●</span>;
    } else if (status?.toLocaleLowerCase() === "expired") {
      return <span style={{ ...dotStyle, color: "red" }}>●</span>;
    }
  };
  const columns = [
    {
      name: "No.",
      id: "number",
    },
    // {
    //   name: "Company ID",
    //   id: "id",
    // },
    {
      name: "Company Name",
      id: "account_name",
    },
    {
      name: "Contact Name",
      id: "contact_name",
    },
    {
      name: "Email",
      id: "contact_email",
    },
    // {
    //   name: "Package ID",
    //   id: "package_id",
    // },
    {
      name: "Package Name",
      id: "package_name",
    },
    {
      name: "Start Date",
      id: "package_start_date",
      minWidth: 100,
      element: (row) => `${formatDate(row.package_start_date?.value)}`,
    },
    {
      name: "End Date",
      id: "package_end_date",
      minWidth: 100,
      element: (row) => `${formatDate(row.package_end_date?.value)}`,
    },
    {
      name: "Pushed",
      id: "export_actual",
    },
    {
      name: "Remaining",
      id: "export_remaining",
    },
    {
      name: "Number of Channel",
      id: "connectors_count",
    },
    {
      name: "Status",
      id: "status",
      element: (row) => (
        <>
          {getStatusDot(row.status)}
          {`${row.status}`}
        </>
      ),
    },
  ];
  return (
    <CustomTable
      columns={columns}
      data={data}
      count={count}
      rowPerPage={rowPerPage}
      page={page}
      handlePageChange={handlePageChange}
      handleRowsPerPageChange={handleRowsPerPageChange}
    />
  );
};

export default ReportTable;
