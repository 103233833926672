import React, { useState, useEffect } from "react";
import Navbar from "../Components/navbar";
import AppLogo from "../Components/AppLogo";
import Accordion from "@mui/material/Accordion";
import { AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LanguageSwitcher from "../Components/SwitchLangauage";
import LifeStages from "../Assets/LifeStages.png";
import LifeStyles from "../Assets/LifeStyles.png";
import { useTranslation } from "react-i18next";
import useLanguage from "../helper/customhook";
import DOMPurify from "dompurify";

function Help() {
  const [lng, changeLanguage] = useLanguage();
  const [help, setHelp] = useState({});

  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    fetch(`/content/${lng}/help.json`)
      .then((response) => response.json())
      .then((jsonData) => setHelp(jsonData))
      .catch((error) => console.error("Error fetching the JSON data:", error));
  };

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
    <div className="login-card privacy-card">
      <AppLogo />
      <LanguageSwitcher currentLang={lng} onLanguageChange={changeLanguage} />
      <div>
        {help?.data?.map((content, index) => {
          return (
            <div key={`paragraph-${index}`}>
              <h2 dangerouslySetInnerHTML={createMarkup(content?.title)} />
              <div className="font-md">
                {content?.lists?.map((list, Qindex) => {
                  return (
                    <Accordion
                      defaultExpanded={Qindex === 0 && index === 0}
                      key={`question-${Qindex}`}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        {list?.question}
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          dangerouslySetInnerHTML={createMarkup(list?.answer)}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Help;
