import React, { useState, useEffect, useRef } from "react";
import TabPanel from "../Components/TabPanel";
import Navbar from "../Components/navbar";
import Sidebar from "../Components/sidebar";
import { useNavigate, useParams } from "react-router-dom";
import APIS from "./APIS";
import Close from "@mui/icons-material/Close";
import {
  IconButton,
  Snackbar,
  Grid,
  Box,
  Tab,
  Tabs,
  Button,
} from "@mui/material";
import GlobalLoading from "./GlobalLoading";
import { ReactComponent as DemographicsIcon } from "../Assets/Demographics.svg";
import LocationCharts from "./LocationCharts";

const LocationInsights = ({ id }) => {
  const navigate = useNavigate();
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const [loading, setLoading] = useState(false);
  const [subTabValue, setSubTabValue] = useState(0);
  const { audienceId } = useParams();
  const [insightsChartInfo, setInsightsChartInfo] = useState({});
  const [insightsMapInfo, setInsightsMapInfo] = useState();

  const [isInsightsLoaded, setIsInsightsLoaded] = useState(false);
  const [isLocationInsightsLoaded, setIsLocationInsightsLoaded] =
    useState(false);
  const isInitialMount = useRef(true);

  const handleSubTabChange = (event, newValue) => {
    setSubTabValue(newValue);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchInsights();
      fetchLocationInsights();
    }
  }, []);

  useEffect(() => {
    // Check if both API responses have been received
    if (isInsightsLoaded && isLocationInsightsLoaded) {
      setLoading(false);
    }
  }, [isInsightsLoaded, isLocationInsightsLoaded]);

  const fetchInsights = async () => {
    setLoading(true);
    try {
      const response = await APIS.getAudienceInsights(
        localStorage.getItem("token"),
        audienceId
      );
      if (response.status === "success") {
        setInsightsChartInfo(response.data);
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
      }
    } catch (error) {
      setErrorSnackbar("Some Error Occurred");
    } finally {
      setIsInsightsLoaded(true);
    }
  };

  const fetchLocationInsights = async () => {
    setLoading(true);
    try {
      const response = await APIS.getAudienceLocInsights(
        localStorage.getItem("token"),
        audienceId
      );
      if (response.status === "success") {
        setInsightsMapInfo(response.data);
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
      }
    } catch (error) {
      setErrorSnackbar("Some Error Occurred");
    } finally {
      setIsLocationInsightsLoaded(true);
    }
  };

  const insightsTabs = [
    {
      name: "Density Report",
      value: 0,
      iconName: <DemographicsIcon />,
      toolTip: "Target audiences based on density factors",
    },
  ];

  const navigateToAudienceList = () => {
    navigate("/Audience");
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const renderTabs = () => {
    return insightsTabs.map(({ name, value, iconName, toolTip }) => (
      <Tab
        key={value + name}
        className="tab-aud-styles"
        iconPosition="start"
        icon={iconName}
        label={name}
        value={value}
        sx={{ alignItems: "flex-start" }}
      />
    ));
  };

  return (
    <Grid container>
      {loading && <GlobalLoading />}
      <Grid item xs={12}>
        <Navbar />
      </Grid>
      <Grid item xs={1}>
        <Sidebar />
      </Grid>
      <Grid item xs={11} mb={30}>
        <div className="content-wrapper">
          <h2>Audience Insights</h2>
          <div className="sub-title">To see insight information</div>

          <Box component="form" noValidate autoComplete="off">
            <div className="back-text" onClick={navigateToAudienceList}>
              <Button variant="contained" onClick={navigateToAudienceList}>
                <b>Back to Audience List</b>
              </Button>
            </div>
            <br />
            <Grid container spacing={2} className="content-container">
              {/* <Grid item xs={3.3}>
                <div className="content-card">
                  <div className="sec-header-title">Density</div>
                  <Tabs
                    value={subTabValue}
                    onChange={handleSubTabChange}
                    orientation="vertical"
                    className="border-tabs"
                  >
                    {renderTabs()}
                  </Tabs>
                </div>
              </Grid> */}
              <Grid item xs={12}>
                <div className="content-card3">
                  <TabPanel value={subTabValue} index={0}>
                    <LocationCharts
                      insightsChartData={insightsChartInfo}
                      insightsMapData={insightsMapInfo}
                    />
                  </TabPanel>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>

        <Snackbar
          open={errorSnackbar ? true : false}
          autoHideDuration={6000}
          onClose={handleCloseSanckBar}
          message={errorSnackbar}
          action={action}
          sx={{ zIndex: "5000" }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        />
      </Grid>
    </Grid>
  );
};

export default LocationInsights;
