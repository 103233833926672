import { useState, useEffect, useRef } from "react";
import {
  Dialog,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";
import CustomSelect from "../ui/CustomSelect";
import { CustomInputField } from "../ui";
import APIS from "../../Pages/APIS";
import { useAuthContext } from "../AuthContextProvider";

const EditUserDialog = ({ selectedUser, onSuccess }) => {
  const [formData, setFormData] = useState(selectedUser);
  const menuItems = [
    { value: "user", label: "User" },
    { value: "admin", label: "Admin" },
  ];
  const { hideModal } = useGlobalModalContext();
  const [formError, setFormError] = useState({
    account_id: "",
    role: "",
    job_title: "",
  });
  const [status, setStatus] = useState({
    isLoading: false,
    isFormChanged: false,
  });
  const [companys, setCompanys] = useState([]);
  const { token } = useAuthContext();
  const isInitialMount = useRef(true);

  useEffect(() => {
    const hasChanged = Object.keys(formData).some(
      (key) => formData[key] !== selectedUser[key]
    );
    setStatus({ ...status, isFormChanged: hasChanged });
  }, [formData]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchCompany();
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const fetchCompany = async () => {
    try {
      const response = await APIS.getMyAccountNameList(token);
      const data = response.data?.map((element) => ({
        value: element.id,
        label: element.account_name,
      }));
      setCompanys(data);
    } catch (error) {
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length === 0) {
      setStatus({ ...status, isLoading: true });
      const response = await APIS.updateUser(
        {
          email: formData.email,
          title: formData.job_title,
          role: formData.role,
          accountId: formData.account_id,
        },
        token
      );
      if (response.code == 200) {
        hideModal();
        onSuccess();
      }
      setStatus({ ...status, isLoading: false });
    } else {
      setFormError(newErrors);
    }

    // hideModal();
    // onSuccess();
  };

  const validateForm = () => {
    const newErrors = {};

    // if (!formData.role) {
    //   newErrors.role = "This field is required.";
    // }

    if (!formData.account_id) {
      newErrors.account_id = "This field is required.";
    }

    if (!formData.job_title) {
      newErrors.job_title = "This field is required.";
    }

    return newErrors;
  };
  return (
    <Dialog open onClose={hideModal} maxWidth="sm">
      <Grid>
        <form onSubmit={handleSubmit} noValidate>
          <Typography variant="h5">Edit User</Typography>
          <Grid container spacing={2} sx={12} mt={1}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                <CustomInputField
                  fullWidth
                  size={"small"}
                  label={"First Name"}
                  disabled
                  required
                  id="first_name"
                  name="first_name"
                  value={formData.first_name}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInputField
                  fullWidth
                  size={"small"}
                  label={"Last Name"}
                  disabled
                  required
                  id="last_name"
                  name="last_name"
                  value={formData.last_name}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                <CustomInputField
                  fullWidth
                  size={"small"}
                  label={"Contact No."}
                  disabled
                  required
                  type="number"
                  id="contact"
                  name="contact"
                  value={formData.contact}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInputField
                  fullWidth
                  size={"small"}
                  label={"Email"}
                  disabled
                  required
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CustomInputField
                fullWidth
                size={"small"}
                label={"Job Title"}
                required
                id="job"
                name="job_title"
                value={formData.job_title}
                error={!!formError.job_title}
                helperText={formError.job_title}
                onChange={handleChange}
                placeholder="Job Title"
              />
            </Grid>
            {/* <Grid item xs={12}>
              <CustomSelect
                fullWidth
                required
                size="small"
                label={"Role"}
                id={"role"}
                name={"role"}
                value={formData.role}
                error={!!formError.role}
                helperText={formError.role}
                onChange={handleChange}
                options={menuItems}
                placeholder="Role"
              />
            </Grid> */}
            <Grid item xs={12}>
              <CustomSelect
                fullWidth
                size="small"
                required
                label={"Company"}
                id={"account_id"}
                name={"account_id"}
                value={formData.account_id}
                error={!!formError.account_id}
                helperText={formError.account_id}
                onChange={handleChange}
                options={companys}
                placeholder="Company"
              />
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                <Button fullWidth onClick={hideModal} variant="contained">
                  CANCEL
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={!!!status.isFormChanged || status.isLoading}
                >
                  {status.isLoading ? <CircularProgress /> : "CONFIRM"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Dialog>
  );
};

export default EditUserDialog;
