import React from "react";
import AppLogoImg from "../Assets/AdTech_B2B_Logo.svg";
import { useNavigate } from "react-router-dom";

const AppLogo = () => {
  const navigate = useNavigate();
  return (
    <div
      className="header-logo"
      onClick={() => navigate("/")}
      style={{ cursor: "pointer" }}
    >
      <img src={AppLogoImg} alt="Ad Tech" />
    </div>
  );
};

export default AppLogo;
