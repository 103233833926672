import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "../Components/TabPanel";
import Grid from "@mui/material/Grid";
import LocationCheckboxes from "./LocationCheckboxes";
import Demographics from "./Demographics";
import CircularProgress from "@mui/material/CircularProgress";
import CustomerInterest from "./CustomerInterest";
import AudienceFooter from "./AudienceFooter";
import CommercialRelevant from "./CommercialRelevant";

import { ReactComponent as DemographicsIcon } from "../Assets/Demographics.svg";
import { ReactComponent as CustomerInterestIcon } from "../Assets/CustomerInterest.svg";
import { ReactComponent as GeographicIcon } from "../Assets/Geographic.svg";
import { ReactComponent as CommercialRelevantIcon } from "../Assets/CommercialRelevant.svg";
import SvgIcon from "@mui/material/SvgIcon";

import LabelWithTooltip from "../Components/LabelWithTooltip";

const TabAudience = ({
  demographicsData,
  loaderDemoData,
  custInterestData,
  loaderCustInterestData,
  locationObj,
  loading,
  commRelevantData,
  loaderCommRelData,
  demographicsselectedFilters,
  setDemographicsselectedFilters,
  custInterestselectedFilters,
  setCustInterestselectedFilters,
  selectedRegions,
  setSelectedRegions,
  selectedProvinces,
  setSelectedProvinces,
  selectedDistricts,
  setSelectedDistricts,
  commRelevantselectedFilters,
  setCommRelevantselectedFilters,
  provinceDistrictCount,
  setProvinceDistrictCount,
  regionProvinceCount,
  setRegionProvinceCount,
  locationTrafficData,
  setLocationTrafficData,
  websiteTrafficData,
  setWebsiteTrafficData,
  showNextTab,
  conjunctionType,
  setConjunctionType,
  navigateToAudienceSummary,
  handleTotalCount,
  audTotalCount,
  handleClearAll,
  audTotalCategories,
  handleCalculateTotalCategories,
  handleAddCondition
}) => {
  const [subTabValue, setSubTabValue] = useState(0);

  const handleSubTabChange = (event, newValue) => {
    setSubTabValue(newValue);
  };

  const audienceTabs = [
    {
      name: "Demographics",
      value: 0,
      iconName: <DemographicsIcon />,
      toolTip: "Target audiences based on demographic factors",
    },
    {
      name: "Customer Interest",
      value: 1,
      iconName: <CustomerInterestIcon />,
      toolTip: `Target audiences based on user's interests and hobbies, derived from their online behavior and interactions (Aggregate level)`,
    },
    {
      name: "Geographic",
      value: 2,
      iconName: <GeographicIcon />,
      toolTip:
        "Target audiences based on physical locations (Region,Province,District)",
    },
    {
      name: "Commercial Relevant",
      value: 3,
      iconName: <CommercialRelevantIcon />,
      toolTip:
        "Target audiences based on Telco business interests or relevance",
    },
  ];

  const renderTabs = () => {
    return audienceTabs.map(({ name, value, iconName, toolTip }) => (
      <Tab
        key={value + name}
        className="tab-aud-styles"
        iconPosition="start"
        icon={iconName}
        label={<LabelWithTooltip label={name} tooltip={toolTip} size="16px" />}
        value={value}
        sx={{ alignItems: "flex-start" }}
      />
    ));
  };

  return (
    <Grid container spacing={2} className="content-container">
      <Grid item xs={3.3}>
        <div className="content-card">
          <div className="sec-header-title">
            Select your audience by choosing multiple labels{" "}
          </div>
          <Tabs
            value={subTabValue}
            onChange={handleSubTabChange}
            orientation="vertical"
            className="border-tabs"
          >
            {renderTabs()}
          </Tabs>
        </div>
      </Grid>
      <Grid item xs={8.7}>
        <div className="content-card3">
          <TabPanel value={subTabValue} index={0}>
            {loaderDemoData ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Demographics
                demographicsData={demographicsData}
                setDemographicsselectedFilters={setDemographicsselectedFilters}
                demographicsselectedFilters={demographicsselectedFilters}
                setCustInterestselectedFilters={setCustInterestselectedFilters}
                custInterestselectedFilters={custInterestselectedFilters}
                setSelectedRegions={setSelectedRegions}
                selectedRegions={selectedRegions}
                setSelectedProvinces={setSelectedProvinces}
                selectedProvinces={selectedProvinces}
                setSelectedDistricts={setSelectedDistricts}
                selectedDistricts={selectedDistricts}
                setProvinceDistrictCount={setProvinceDistrictCount}
                provinceDistrictCount={provinceDistrictCount}
                setRegionProvinceCount={setRegionProvinceCount}
                regionProvinceCount={regionProvinceCount}
                setCommRelevantselectedFilters={setCommRelevantselectedFilters}
                commRelevantselectedFilters={commRelevantselectedFilters}
                setLocationTrafficData={setLocationTrafficData}
                locationTrafficData={locationTrafficData}
                setWebsiteTrafficData={setWebsiteTrafficData}
                websiteTrafficData={websiteTrafficData}
                handleNextTab={navigateToAudienceSummary}
                handleTotalCount={handleTotalCount}
                audTotalCount={audTotalCount}
                handleClearAll={handleClearAll}
                audTotalCategories={audTotalCategories}
                handleCalculateTotalCategories={handleCalculateTotalCategories}
                handleAddCondition={handleAddCondition}
              ></Demographics>
            )}
          </TabPanel>
          <TabPanel value={subTabValue} index={1}>
            {loaderCustInterestData ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <CustomerInterest
                custInterestData={custInterestData}
                setDemographicsselectedFilters={setDemographicsselectedFilters}
                demographicsselectedFilters={demographicsselectedFilters}
                setCustInterestselectedFilters={setCustInterestselectedFilters}
                custInterestselectedFilters={custInterestselectedFilters}
                setSelectedRegions={setSelectedRegions}
                selectedRegions={selectedRegions}
                setSelectedProvinces={setSelectedProvinces}
                selectedProvinces={selectedProvinces}
                setSelectedDistricts={setSelectedDistricts}
                selectedDistricts={selectedDistricts}
                setProvinceDistrictCount={setProvinceDistrictCount}
                provinceDistrictCount={provinceDistrictCount}
                setRegionProvinceCount={setRegionProvinceCount}
                regionProvinceCount={regionProvinceCount}
                setCommRelevantselectedFilters={setCommRelevantselectedFilters}
                commRelevantselectedFilters={commRelevantselectedFilters}
                setLocationTrafficData={setLocationTrafficData}
                locationTrafficData={locationTrafficData}
                setWebsiteTrafficData={setWebsiteTrafficData}
                websiteTrafficData={websiteTrafficData}
                handleNextTab={navigateToAudienceSummary}
                conjunctionType={conjunctionType}
                setConjunctionType={setConjunctionType}
                handleTotalCount={handleTotalCount}
                audTotalCount={audTotalCount}
                handleClearAll={handleClearAll}
                audTotalCategories={audTotalCategories}
                handleCalculateTotalCategories={handleCalculateTotalCategories}
                handleAddCondition={handleAddCondition}
              ></CustomerInterest>
            )}
          </TabPanel>
          <TabPanel value={subTabValue} index={2}>
            <div className="sec-header-title">Geographics </div>

            {loading ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            ) : (
              <div>
                <LocationCheckboxes
                  locationObj={locationObj}
                  setDemographicsselectedFilters={
                    setDemographicsselectedFilters
                  }
                  demographicsselectedFilters={demographicsselectedFilters}
                  setCustInterestselectedFilters={
                    setCustInterestselectedFilters
                  }
                  custInterestselectedFilters={custInterestselectedFilters}
                  setSelectedRegions={setSelectedRegions}
                  selectedRegions={selectedRegions}
                  setSelectedProvinces={setSelectedProvinces}
                  selectedProvinces={selectedProvinces}
                  setSelectedDistricts={setSelectedDistricts}
                  selectedDistricts={selectedDistricts}
                  setProvinceDistrictCount={setProvinceDistrictCount}
                  provinceDistrictCount={provinceDistrictCount}
                  setRegionProvinceCount={setRegionProvinceCount}
                  regionProvinceCount={regionProvinceCount}
                  setCommRelevantselectedFilters={
                    setCommRelevantselectedFilters
                  }
                  commRelevantselectedFilters={commRelevantselectedFilters}
                  setLocationTrafficData={setLocationTrafficData}
                  locationTrafficData={locationTrafficData}
                  setWebsiteTrafficData={setWebsiteTrafficData}
                  websiteTrafficData={websiteTrafficData}
                  handleNextTab={navigateToAudienceSummary}
                  handleTotalCount={handleTotalCount}
                  audTotalCount={audTotalCount}
                  handleClearAll={handleClearAll}
                  audTotalCategories={audTotalCategories}
                  handleCalculateTotalCategories={
                    handleCalculateTotalCategories
                  }
                  handleAddCondition={handleAddCondition}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={subTabValue} index={3}>
            {loaderCommRelData ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <CommercialRelevant
                commRelevantData={commRelevantData}
                setDemographicsselectedFilters={setDemographicsselectedFilters}
                demographicsselectedFilters={demographicsselectedFilters}
                setCustInterestselectedFilters={setCustInterestselectedFilters}
                custInterestselectedFilters={custInterestselectedFilters}
                setSelectedRegions={setSelectedRegions}
                selectedRegions={selectedRegions}
                setSelectedProvinces={setSelectedProvinces}
                selectedProvinces={selectedProvinces}
                setSelectedDistricts={setSelectedDistricts}
                selectedDistricts={selectedDistricts}
                setProvinceDistrictCount={setProvinceDistrictCount}
                provinceDistrictCount={provinceDistrictCount}
                setRegionProvinceCount={setRegionProvinceCount}
                regionProvinceCount={regionProvinceCount}
                setCommRelevantselectedFilters={setCommRelevantselectedFilters}
                commRelevantselectedFilters={commRelevantselectedFilters}
                setLocationTrafficData={setLocationTrafficData}
                locationTrafficData={locationTrafficData}
                setWebsiteTrafficData={setWebsiteTrafficData}
                websiteTrafficData={websiteTrafficData}
                handleNextTab={navigateToAudienceSummary}
                handleTotalCount={handleTotalCount}
                audTotalCount={audTotalCount}
                handleClearAll={handleClearAll}
                audTotalCategories={audTotalCategories}
                handleCalculateTotalCategories={handleCalculateTotalCategories}
                handleAddCondition={handleAddCondition}
              ></CommercialRelevant>
            )}
          </TabPanel>
        </div>
      </Grid>
    </Grid>
  );
};

export default TabAudience;
