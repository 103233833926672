import { useEffect, useRef, useState } from "react";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import APIS from "../../Pages/APIS";
import { formatDatetime, formatDate } from "../../helper/util";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";
import { useAuthContext } from "../AuthContextProvider";
import { CustomTable } from "../ui";

const PackageMappingDialog = ({ selectedAccount }) => {
  const [packageHistory, setpackageHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const { hideModal } = useGlobalModalContext();
  const { token } = useAuthContext();
  const isInitialMount = useRef(true);

  const columns = [
    {
      name: "Package Type",
      id: "package_type",
    },

    {
      name: "Package Name",
      id: "package_name",
    },
    {
      name: "Package Added By",
      id: "package_add_by",
    },
    {
      name: "Assigned Date",
      id: "package_add_date",
      minWidth: 150,
      element: (row) => `${formatDatetime(row.package_add_date?.value)}`,
    },
    {
      name: "Start Date",
      id: "package_start_date",
      minWidth: 100,
      element: (row) => `${formatDate(row.package_start_date?.value)}`,
    },
    {
      name: "End Date",
      id: "package_end_date",
      minWidth: 100,
      element: (row) => `${formatDate(row.package_end_date?.value)}`,
    },
    {
      name: "Export Count",
      id: "export_count",
      element: (row) => (row.export_count ? row.export_count : "0"),
    },
    {
      name: "Number of Audience per push",
      id: "audience_size_per_export",
    },
    { id: "connectors_count", name: "Connector Count" },
  ];

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      getPackageMapping();
    }
  }, []);

  const getPackageMapping = async () => {
    try {
      setLoading(true);

      const history = await APIS.getAccountPackageHistory(
        token,
        selectedAccount.id
      );

      setpackageHistory(history.packageHistory);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw new Error("Fetching Audience Push History Error", error);
    }
  };

  return (
    <Dialog
      open
      maxWidth={"lg"}
      onClose={hideModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid display={"grid"} margin={3} rowGap={2} width={"lg"}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {selectedAccount?.account_name ?? ""}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={hideModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>

        <CustomTable
          columns={columns}
          data={packageHistory}
          hidePagination
          isLoading={loading}
        />
      </Grid>
    </Dialog>
  );
};

export default PackageMappingDialog;
