import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import { Search, CalendarTodayOutlined } from "@mui/icons-material";
import { Box, CircularProgress, TextField, Button } from "@mui/material";
import APIS from "./APIS";
import { useAuthContext } from "../Components/AuthContextProvider";
import UserRoleUserTable from "../Components/MyAccount/UserRoleUserTable";
import CustomSelect from "../Components/ui/CustomSelect";
import ReactDatePicker from "react-datepicker";

const UserAccountListUserRole = () => {
  const [myAccountData, setMyAccountData] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const { token, userId } = useAuthContext();
  const [dateRange, setDateRange] = useState([]);
  const isInitialMount = useRef(true);

  const menuItems = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
    { value: "Expired", label: "Expired" },
  ];

  const [pagination, setPagination] = useState({
    page: 0,
    limit: 10,
    totalCount: 0,
  });
  const [query, setQuery] = useState({
    packageName: "",
    status: "All",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchMyAccountData();
    }
    // Fetch data from API endpoint
  }, []);

  const fetchMyAccountData = async () => {
    try {
      setLoading(true);
      const urlParams = new URLSearchParams({
        userId: userId,
        ...query,
        status: query.status == "All" ? "" : query.status,
        page: pagination.page + 1,
        limit: pagination.limit,
      });
      const queryString = urlParams.toString();
      const response = await APIS.getMyAccount(token, queryString);
      response.packageMappings?.forEach((element, index) => {
        element["index"] = index + 1;
      });
      setMyAccountData(response.packageMappings);
      setPagination({ ...pagination, totalCount: response.totalCount });
      setLoading(false); // Set loading to false after fetching data
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      limit: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleDateChange = (dateRange) => {
    setDateRange(dateRange);
  };

  return (
    <Grid item>
      <Grid className="search-card" mt={2}>
        <Box
          sx={{
            width: "60%",
            display: "inline-flex",
          }}
          columnGap={1}
        >
          <TextField
            fullWidth
            value={query.packageName}
            onChange={(e) => {
              setQuery({ ...query, packageName: e.target.value });
            }}
            size="small"
            placeholder="Search by Package Name"
            InputProps={{
              startAdornment: <Search />,
            }}
          />
          <CustomSelect
            sx={{ width: "40%" }}
            options={menuItems}
            placeholder="Status"
            onChange={(e) => {
              setQuery({ ...query, status: e.target.value });
            }}
            value={query.status}
          />
          {/* <ReactDatePicker
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            onChange={handleDateChange}
            placeholderText="Start Date - End Date"
            selectsRange
            showIcon
            icon={<CalendarTodayOutlined />}
          /> */}
          <Button variant="contained" onClick={fetchMyAccountData}>
            Search{" "}
          </Button>
        </Box>
        <Box
          sx={{
            width: "40%",
            display: "inline-flex",
          }}
        ></Box>
      </Grid>
      {loading ? ( // Show loader if loading is true
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <UserRoleUserTable
          data={myAccountData}
          count={pagination.totalCount}
          rowPerPage={pagination.limit}
          page={pagination.page}
          handlePageChange={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Grid>
  );
};

export default UserAccountListUserRole;
