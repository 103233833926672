import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Grid } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const data = {
  labels: [""],
  datasets: [
    {
      label: "High",
      data: [10],
      backgroundColor: "#f44336", // Red
    },
    {
      label: "Low",
      data: [20], // 30% of the bar
      backgroundColor: "#ff9800", // Orange
    },
    {
      label: "Medium",
      data: [35], // 25% of the bar
      backgroundColor: "#ffeb3b", // Yellow
    },
    {
      label: "Very High",
      data: [20], // 25% of the bar
      backgroundColor: "#4caf50", // Green
    },
    {
      label: "Very Low",
      data: [15], // 25% of the bar
      backgroundColor: "#3385FF", // Green
    },
  ],
};

const options = {
  indexAxis: "y",
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: true,
      text: "Affluence",
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        beginAtZero: true,
        max: 100,
        display: true, // Hide x-axis ticks
      },
      grid: {
        display: false, // Hide x-axis grid lines
      },
    },
    y: {
      stacked: true,
      ticks: {
        display: false, // Hide y-axis ticks
      },
      grid: {
        display: false, // Hide y-axis grid lines
      },
    },
  },
  maintainAspectRatio: false,
};


export default function StackChart() {
  return (
    <>
      <Grid width={700} height={150}>
        <Bar options={options} data={data} />
      </Grid>
    </>
  );
}
