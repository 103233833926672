import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import { Search, CalendarTodayOutlined } from "@mui/icons-material";
import { Box, CircularProgress, TextField, Button } from "@mui/material";
import APIS from "./APIS";
import { useAuthContext } from "../Components/AuthContextProvider";
import ReactDatePicker from "react-datepicker";
import UserAccountTable from "../Components/MyAccount/UserTable";
import CustomSelect from "../Components/ui/CustomSelect";

const UserAccountList = () => {
  const [query, setQuery] = useState({
    text: "",
    status: "All",
    // startDate: "",
    // endDate: "",
  });
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 10,
    totalCount: 0,
  });
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const { token } = useAuthContext();
  const isInitialMount = useRef(true);

  const menuItems = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Pending Approval", label: "Pending Approval" },
    //{ value: "Deactivate", label: "Deactivate" },
    { value: "Inactive", label: "Inactive" },
  ];

  useEffect(() => {
    // Fetch data from API endpoint
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchUserList();
    }
  }, [pagination.limit, pagination.page]);

  const fetchUserList = async () => {
    try {
      setLoading(true);
      const urlParams = new URLSearchParams({
        ...query,
        status: query.status == "All" ? "" : query.status,
        page: pagination.page + 1,
        limit: pagination.limit,
      });
      const queryString = urlParams.toString();
      const response = await APIS.getUserList(token, queryString);

      response.data?.forEach((element, index) => {
        element["index"] = pagination.page * pagination.limit + index + 1;
      });

      setUserList(response.data);
      setPagination({ ...pagination, totalCount: response.totalCount });
      setLoading(false); // Set loading to false after fetching data
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      limit: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleDateChange = (dateRange) => {
    setQuery({ ...query, startDate: dateRange[0], endDate: dateRange[1] });
  };

  return (
    <Grid item>
      <Grid className="search-card" mt={2}>
        <Box
          sx={{
            width: "60%",
            display: "inline-flex",
          }}
          columnGap={1}
        >
          <TextField
            fullWidth
            value={query.search}
            onChange={(e) => {
              setQuery({ ...query, text: e.target.value });
            }}
            size="small"
            placeholder="Search by Company, Email, First-Last Name"
            InputProps={{
              startAdornment: <Search />,
            }}
          />
          <CustomSelect
            options={menuItems}
            placeholder="Status"
            onChange={(e) => {
              setQuery({ ...query, status: e.target.value });
            }}
            value={query.status}
          />
          {/* <ReactDatePicker
            startDate={query.startDate}
            endDate={query.endDate}
            onChange={handleDateChange}
            dateFormat={"y-MM-dd"}
            placeholderText="Start Date - End Date"
            selectsRange
            showIcon
            icon={<CalendarTodayOutlined />}
          /> */}
          <Button
            variant="contained"
            onClick={() => {
              if (pagination.page > 0) {
                setPagination({ ...pagination, page: 0 });
              } else {
                fetchUserList();
              }
            }}
          >
            Search
          </Button>
        </Box>
        <Box
          sx={{
            width: "40%",
            display: "inline-flex",
          }}
        ></Box>
      </Grid>
      <UserAccountTable
        loading={loading}
        data={userList}
        count={pagination.totalCount}
        rowPerPage={pagination.limit}
        page={pagination.page}
        handlePageChange={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        onSuccess={fetchUserList}
      />
    </Grid>
  );
};

export default UserAccountList;
