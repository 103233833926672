import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Grid,
  Box,
  Button,
  IconButton,
  Snackbar,
} from "@mui/material";
import { Search, Close } from "@mui/icons-material";
import Navbar from "../Components/navbar";
import Sidebar from "../Components/sidebar";
import { useNavigate } from "react-router-dom";
import APIS from "./APIS";
import { useGlobalModalContext } from "../Components/GlobalModalContext/GlobalModalContext";
import GlobalLoading from "./GlobalLoading";
import { useAudienceContext } from "../Components/AudienceContext/AudienceContext";
import AudienceTable from "../Components/AudienceList/AudienceTable";
import { useAuthContext } from "../Components/AuthContextProvider";
import DeleteEnhancedAudDialog from "../Components/DeleteEnhancedAudDialog";

const AudienceList = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState({
    // page: 0,
    // limit: 10,
    audienceName: "",
    //totalCount: 0,
  });
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 10,
    totalCount: 0,
  });
  const [audiences, setAudiences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [globalLoading, setGlobalLoading] = useState(false);
  const { showModal, hideModal } = useGlobalModalContext();
  const { setAudienceSummaryData, setAudienceEnhancement } =
    useAudienceContext();
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const { token, userId } = useAuthContext();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchAudience();
    }
  }, [pagination.page, pagination.limit]);

  const fetchAudience = async () => {
    try {
      setLoading(true); // Set loading to true when fetching data
      const urlParams = new URLSearchParams({
        userId,
        ...query,
        page: pagination.page + 1,
        limit: pagination.limit,
      });
      const queryString = urlParams.toString();
      const response = await APIS.getAudienceList(token, queryString);
      if (response.status === "success") {
        setAudiences(response.audiences ?? []); // Update projects state with fetched data
        setPagination({ ...pagination, totalCount: response.totalCount });
        //setQuery({ ...query, totalCount: response.totalCount });
        setLoading(false); // Set loading to false after fetching data
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false); // Set loading to false in case of error
    }
  };

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      limit: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleCreateAudience = () => {
    setAudienceSummaryData({});
    setAudienceEnhancement({});
    navigate(`/CreateAudience`);
  };

  const handleDeleteAudience = async (audId) => {
    const formData = {
      audienceId: audId,
    };
    try {
      setGlobalLoading(true);
      const response = await APIS.deleteAudience(formData, token);
      if (response.code == 200) {
        hideModal();
        fetchAudience();
        setGlobalLoading(false);
      } else if (response.code == 201) {
        showModal(
          <DeleteEnhancedAudDialog
            message={response.error}
            onSuccess={() => handleDeleteEnhancedAudience(audId)}
          />
        );
        setGlobalLoading(false);
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
        hideModal();
        setGlobalLoading(false);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleDeleteEnhancedAudience = async (audId) => {
    const formData = {
      audienceId: audId,
      deleteFlag: true,
    };
    try {
      setGlobalLoading(true);
      const response = await APIS.deleteAudience(formData, token);
      if (response.code == 200) {
        hideModal();
        setGlobalLoading(false);
        fetchAudience();
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
        hideModal();
        setGlobalLoading(false);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="inner-wrapper">
      {globalLoading && <GlobalLoading />}
      <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "5000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <Grid container>
        <Grid item xs={12}>
          <Navbar />
        </Grid>
        <Grid item xs={1}>
          <Sidebar />
        </Grid>
        <Grid item xs={11}>
          <div className="content-wrapper">
            <h2>Audience</h2>

            <div className="sub-title">
              Create custom audiences by leveraging mobility data, customer
              interests, behavior, and geographic information, then export them
              for use in your social and digital advertising campaigns.{" "}
            </div>
            <Grid className="search-card" mt={2}>
              <Box
                columnGap={1}
                sx={{
                  width: "60%",
                  display: "inline-flex",
                }}
              >
                <TextField
                  onChange={(e) =>
                    setQuery({ ...query, audienceName: e.target.value })
                  }
                  sx={{ width: "60%" }}
                  // variant="standard"
                  size="small"
                  placeholder="Search by Audience Name"
                  InputProps={{
                    startAdornment: <Search />,
                  }}
                />
                <Button variant="contained" onClick={fetchAudience}>
                  Search
                </Button>
              </Box>

              <Box
                sx={{
                  width: "40%",
                  display: "inline-flex",
                }}
              >
                <Button
                  onClick={handleCreateAudience}
                  sx={{
                    backgroundColor: "#202529",
                    color: "#FFFFFF !important",
                    fontSize: "12px",
                    minWidth: "160px",
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: "#F24822",
                      borderColor: "#0062cc",
                      boxShadow: "none",
                    },
                  }}
                >
                  Create New Audience
                </Button>
              </Box>
            </Grid>

            <AudienceTable
              data={audiences}
              count={pagination.totalCount}
              rowPerPage={pagination.limit}
              page={pagination.page}
              loading={loading}
              handlePageChange={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleDeleteAudience={handleDeleteAudience}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AudienceList;
