import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  Typography,
  Grid,
  Button,
  Snackbar,
  CircularProgress,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { CustomInputField, CustomSelect } from "../ui";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";
import ChannelLogoLable from "../ChannelLogoLable";
import APIS from "../../Pages/APIS";
import { useAuthContext } from "../AuthContextProvider";
import { Close } from "@mui/icons-material";
import CustomDateInputField from "../ui/CustomDateInputField";
import { format } from "date-fns";

const AssignPackageDialog = ({ selectedAccount, onSuccess }) => {
  const intialData = {
    export_count: "",
    duration: "",
    packageId: "",
    packageStartDate: "",
    connectors_count: 0,
    connectors: {
      google: "",
      meta: "",
      tiktok: "",
      line: "",
    },
  };
  const { hideModal } = useGlobalModalContext();
  const { token } = useAuthContext();
  const [formData, setFormData] = useState(intialData);
  const [packages, setPackages] = useState([]);
  const [formError, setFormError] = useState({
    packageId: "",
    packageStartDate: "",
    apiError: "",
    packageType: "",
    channel: "",
  });
  const [packageType, setPackageType] = useState("");
  const [loading, setIsLoading] = useState(false);
  const isInitialMount = useRef(true);

  const packageTypes = [
    { value: "main", label: "Main" },
    { value: "topUp", label: "Top Up" },
  ];

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (packageType == "main") {
        getMainPackages();
      } else if (packageType == "topUp") {
        getTopupPackages();
      }
    }
  }, [packageType]);

  const getMainPackages = async () => {
    try {
      const response = await APIS.getMaingPackageName(token);
      if (response.status == "success") {
        setPackages(response.mainPackages);
      } else {
        setFormError({
          ...formError,
          apiError: response?.message ?? "Main package list api failed!",
        });
      }
    } catch (error) {
    }
  };

  const getTopupPackages = async () => {
    try {
      const response = await APIS.getTopupPackageName(token);
      if (response.status == "success") {
        setPackages(response.topupPackages);
      } else {
        setFormError({
          ...formError,
          apiError: response?.message ?? "Topup list api failed!",
        });
      }
    } catch (error) {
    }
  };

  const getPackageMenu = () => {
    return packages.map((pck) => ({
      value: pck.id,
      label: pck.name,
    }));
  };

  const handlePackageChage = (e) => {
    const selectedPackage = packages.filter((p) => p.id == e.target.value)[0];
    if (selectedPackage) {
      setFormData({
        ...formData,
        packageId: selectedPackage.id,
        connectors: {
          google: "",
          meta: "",
          tiktok: "",
          line: "",
        },
        connectors_count: selectedPackage.connectors_count,
        duration: selectedPackage.duration,
        export_count: selectedPackage.export_count,
        audience_size_per_export: selectedPackage.audience_size_per_export,
      });
    }
    setFormError({ ...formError, packageId: "" });
  };

  const isIconDiabled = (channel) => {
    if (formData.connectors[channel] == "Y") return false;

    return (
      Object.values(formData.connectors).filter((data) => data == "Y").length ==
      formData.connectors_count
    );
  };

  const handleClickLogo = (channel) => {
    setFormData({
      ...formData,
      connectors: {
        ...formData.connectors,
        [channel]: formData.connectors[channel] == "Y" ? "N" : "Y",
      },
    });
    setFormError({ ...formError, channel: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    if (Object.keys(newErrors).length != 0) {
      setFormError(newErrors);
      return;
    }

    setIsLoading(true);
    if (packageType == "main") {
      const data = {
        accountId: selectedAccount.id,
        packageId: formData.packageId,
        packageStartDate: formData.packageStartDate,
        connectors: {
          google: formData.connectors.google || "N",
          meta: formData.connectors.meta || "N",
          tiktok: formData.connectors.tiktok || "N",
          line: formData.connectors.line || "N",
        },
      };
      const response = await APIS.addMainPackage(data, token);
      if (response.status == "success") {
        hideModal();
        onSuccess();
      } else {
        setFormError({
          ...formError,
          apiError: response?.message || "Add main package api failed!",
        });
      }
    } else {
      const data = {
        accountId: selectedAccount.id,
        packageId: formData.packageId,
      };
      const response = await APIS.addTopupPackage(data, token);
      if (response.status == "success") {
        hideModal();
        onSuccess();
      } else {
        setFormError({
          ...formError,
          apiError: response?.error || "Add toppu package api failed!",
        });
      }
    }
    setIsLoading(false);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!packageType) {
      newErrors.packageType = "This field is required.";
    }
    if (!formData.packageId) {
      newErrors.packageId = "This field is required.";
    }

    if (packageType == "main") {
      if (!formData.packageStartDate) {
        newErrors.packageStartDate = "This field is required.";
      }

      if (
        Object.values(formData.connectors).filter((data) => data == "Y")
          .length < formData.connectors_count
      ) {
        newErrors.channel = `You have to select ${formData.connectors_count} channel(s).`;
      }
    }

    return newErrors;
  };
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setFormError({ ...formError, apiError: "" })}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Dialog open onClose={hideModal} maxWidth="xs">
      <Grid p={3}>
        <form onSubmit={handleSubmit} noValidate>
          <Typography variant="h5">Assign Package To Company</Typography>

          <Grid container sx={12} mt={1}>
            <Grid item xs={12} rowGap={"10px"}>
              <Grid mb={2}>
                <CustomSelect
                  label={"Package Type"}
                  required
                  id="packageType"
                  placeholder="Select Package Type"
                  value={packageType}
                  error={!!formError.packageType}
                  helperText={formError.packageType}
                  options={packageTypes}
                  onChange={(e) => {
                    setPackageType(e.target.value);
                    setFormError({ ...formError, packageType: "" });
                    setFormData(intialData);
                  }}
                />
              </Grid>
              <Grid mb={2}>
                <CustomSelect
                  label={"Package"}
                  disabled={!Boolean(packageType)}
                  required
                  id="packageId"
                  name="packageId"
                  value={formData.packageId}
                  placeholder="Select Package"
                  onChange={handlePackageChage}
                  options={getPackageMenu()}
                  error={!!formError.packageId}
                  helperText={formError.packageId}
                />
              </Grid>
              {packageType == "main" && (
                <>
                  <Grid mb={2}>
                    <CustomDateInputField
                      required
                      label={"Start Date"}
                      name="packageStartDate"
                      minDate={getTodayDate()}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          packageStartDate: format(e, "yyyy-MM-dd"),
                        });
                        setFormError({ ...formError, packageStartDate: "" });
                      }}
                      value={
                        formData.packageStartDate &&
                        format(formData.packageStartDate, "dd-MMM-yyyy")
                      }
                      placeholder="dd-MMM-yyyy"
                      error={!!formError.packageStartDate}
                      helperText={formError.packageStartDate}
                    />
                  </Grid>
                  <Grid mb={2}>
                    <Grid container display={"row"} gap={3} m={3}>
                      <ChannelLogoLable
                        channelName={"Google"}
                        onClick={() => {
                          handleClickLogo("google");
                        }}
                        selected={formData.connectors.google == "Y"}
                        disabled={isIconDiabled("google")}
                      />
                      <ChannelLogoLable
                        channelName={"Meta"}
                        onClick={() => {
                          handleClickLogo("meta");
                        }}
                        selected={formData.connectors.meta == "Y"}
                        disabled={isIconDiabled("meta")}
                      />
                      <ChannelLogoLable
                        channelName={"Tiktok"}
                        onClick={() => {
                          handleClickLogo("tiktok");
                        }}
                        selected={formData.connectors.tiktok == "Y"}
                        disabled={isIconDiabled("tiktok")}
                      />
                      {/* <ChannelLogoLable
                        channelName={"Line"}
                        selected={formData.connectors.line == "Y"}
                        onClick={() => {
                          handleClickLogo("line");
                        }}
                        disabled={isIconDiabled("line")}
                      /> */}
                      <FormHelperText error={!!formError.channel}>
                        {formError.channel}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  <Grid mb={2}>
                    <CustomInputField
                      disabled
                      size={"small"}
                      label={"Max Audience Size Per Export"}
                      value={formData.audience_size_per_export}
                      id="audience_size_per_export"
                      placeholder="Max Audience Size Per Export"
                    />
                  </Grid>
                </>
              )}

              <CustomInputField
                size={"small"}
                disabled
                label={"No. of Export"}
                value={formData.export_count}
                id="exportTopup"
                placeholder="No. of Export"
              />
              <Grid my={2}>
                <CustomInputField
                  disabled
                  size={"small"}
                  label={"Expiry Duration"}
                  id="expiryTopup"
                  value={formData.duration}
                  placeholder="Expiry Duration"
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                <Button fullWidth onClick={hideModal} variant="contained">
                  CANCEL
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? <CircularProgress /> : "ASSIGN"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Snackbar
        open={Boolean(formError.apiError)}
        autoHideDuration={6000}
        onClose={() => setFormError({ ...formError, apiError: "" })}
        message={formError.apiError}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        action={action}
      />
    </Dialog>
  );
};

export default AssignPackageDialog;
