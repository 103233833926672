import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import APIS from './APIS';

const UpdateUserRegisterStatus = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []); // Run this effect whenever the pathname changes

  const fetchData = async () => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const email = queryParams.get('email'); 
      
      const data = {
        "email": email,
        "status": "Active"
      }

      const response = await APIS.ChangeRegistrationUserStatus(data, '');
      if (response.code == 200) {
        alert(response.message);
       // navigate('/CreatePassword', {st: code});
      } else{
      alert(response.message);
      }
    } catch (error) {
      alert(error);
      //setRegistrationStatus({ success: null, error: error });
    }
  };

  return (
    <div>
      <h1>update user registration status</h1>
    </div>
  );
};

export default UpdateUserRegisterStatus;
