import React, { useEffect } from "react";
import Registration from "./Pages/Registration";
import Login from "./Pages/Login";
import Grid from "@mui/material/Grid";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Terms from "./Pages/Terms";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
// import ListOrderPage from './Pages/ListOrderPage';
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import CreatePassword from "./Pages/CreatePassoword";
import ResetPassword from "./Pages/ResetPassword";
import ProtectedRoute from "./Components/ProtectedRoute";
import Audience from "./Pages/Audience";
import AudienceList from "./Pages/AudienceList";
import CreateAudience from "./Pages/CreateAudience";
import Demographics from "./Pages/Demographics";
import CustomerInterest from "./Pages/CustomerInterest";
import CustomerRelevant from "./Pages/CommercialRelevant";
import EmailVerify from "./Pages/EmailVerify";
import ForgotPassword from "./Pages/ForgotPassword";
import PageInfoModalBox from "./Components/PageInfoModalBox";
import UpdateUserRegisterStatus from "./Pages/UpdateUserRegisterStatus";
import ChannelSetup from "./Pages/ChannelSetup";
import ChannelStatic from "./Pages/ChannelStatic";
import AudiencePerformPush from "./Pages/AudiencePerformPush";
import MyAccount from "./Pages/MyAccount";
import { GlobalModalProvider } from "./Components/GlobalModalContext/GlobalModalContext";
import { AuthContextProvider } from "./Components/AuthContextProvider";
import { AudienceProvider } from "./Components/AudienceContext/AudienceContext";
import AudienceSummary from "./Pages/AudienceSummary";
import UserProfile from "./Pages/UserProfile";
import CustomMap from "./Components/CustomMap";
import Report from "./Pages/Report";
import ResendPwdResetLink from "./Pages/ResentPwdResetLink";
import { RegisterProvider } from "./Components/RegisterContext";
import MarketingConsent from "./Pages/MarketingConsent";
import Home from "./Pages/Home";
import PackageManagement from "./Pages/PackageManagement";
import ChangePassword from "./Pages/ChangePassword";
import Help from "./Pages/Help";
import { debugService } from "./helper/util";
import EmailTemplate from "./Pages/EmailTemplate";
import TrafficMap from "./Components/TrafficMap";
import StackChart from "./Components/StarkChart";
import Footer from "./Components/Footer";
import "./i18n";
import About from "./Pages/About";
import LocationInsights from "./Pages/LocationInsights";

function App() {
  useEffect(() => {
    debugService();
  }, []);

  return (
    <div>
      <Grid container>
        <BrowserRouter>
          <AuthContextProvider>
            <RegisterProvider>
              <AudienceProvider>
                <GlobalModalProvider>
                  <Grid item xs={12}>
                    <div className="container">
                      <Routes>
                        {/* <Route path="/" element={<Layout />}> */}
                        <Route path="home" element={<Home />} />
                        {/* <Route path="CustomMap" element={<CustomMap />} /> */}
                        <Route path="Registration" element={<Registration />} />
                        <Route path="Login" element={<Login />} />
                        <Route path="Terms" element={<Terms />} />
                        <Route path="StackChart" element={<StackChart />} />
                        <Route
                          path="PrivacyPolicy"
                          element={<PrivacyPolicy />}
                        />
                        <Route
                          path="MarketingConsent"
                          element={<MarketingConsent />}
                        />
                        <Route
                          path="CreatePassword"
                          element={<CreatePassword />}
                        />
                        <Route
                          path="ResetPassword"
                          element={<ResetPassword />}
                        />

                        <Route path="EmailVerify" element={<EmailVerify />} />
                        <Route
                          path="UpdateUserRegisterStatus"
                          element={<UpdateUserRegisterStatus />}
                        />
                        <Route
                          path="PageInfoModalBox"
                          element={<PageInfoModalBox />}
                        />
                        <Route
                          path="ResendPwdResetLink"
                          element={<ResendPwdResetLink />}
                        />
                        <Route
                          path="ForgotPassword"
                          element={<ForgotPassword />}
                        />
                        <Route
                          path="ChannelStatic"
                          element={<ChannelStatic />}
                        />
                        <Route path="*" element={<Login />} />
                        <Route
                          element={<ProtectedRoute roles={["admin", "user"]} />}
                        >
                          <Route path="Help" element={<Help />} />
                          <Route path="About" element={<About />} />
                          <Route path="MyAccount" element={<MyAccount />} />
                          <Route path="UserProfile" element={<UserProfile />} />
                          <Route
                            path="ChangePassword"
                            element={<ChangePassword />}
                          />
                        </Route>
                        {/* <Route path='List' element={<ListOrderPage />} /> */}
                        <Route element={<ProtectedRoute roles={["admin"]} />}>
                          <Route path="Report" element={<Report />} />
                          <Route
                            path="PackageManagement"
                            element={<PackageManagement />}
                          />
                          <Route
                            path="EmailTemplate"
                            element={<EmailTemplate />}
                          />
                        </Route>

                        {/* <Route path="ProjectList" element={<ProjectList />} /> */}
                        <Route element={<ProtectedRoute roles={["user"]} />}>
                          {/* <Route
                            path="ChannelStatic"
                            element={<ChannelStatic />}
                          /> */}
                          <Route
                            path="ChannelSetup"
                            element={<ChannelSetup />}
                          />
                          <Route path="Audience" element={<Audience />} />
                          <Route
                            path="/AudiencePerformPush/:id"
                            element={<AudiencePerformPush />}
                          />
                          <Route
                            path="AudienceList"
                            element={<AudienceList />}
                          />
                          <Route
                            path="CreateAudience"
                            element={<CreateAudience />}
                          />
                          <Route
                            path="/AudienceSummary/:mode"
                            element={<AudienceSummary />}
                          />
                          <Route
                            path="CustomerInterest"
                            element={<CustomerInterest />}
                          />
                          <Route
                            path="Demographics"
                            element={<Demographics />}
                          />
                          <Route
                            path="CustomerRelevant"
                            element={<CustomerRelevant />}
                          />
                          <Route
                            path="LocationInsights/:audienceId"
                            element={<LocationInsights />}
                          />
                        </Route>
                      </Routes>
                    </div>
                  </Grid>
                </GlobalModalProvider>
              </AudienceProvider>
            </RegisterProvider>
          </AuthContextProvider>
        </BrowserRouter>
      </Grid>
      <Footer id="nomal-footer" />
    </div>
  );
}

export default App;
