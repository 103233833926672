import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import BarChartCustom from "../Components/BarChartCustom";
import TrafficMap from "../Components/TrafficMap";
import StackedBarChartCustom from "../Components/StackedBarChartCustom";
import { getTopFive } from "../helper/util";

const LocationCharts = ({ insightsChartData, insightsMapData }) => {
  const [lifeStagesDataset, setLifeStagesDataset] = useState([]);
  const [customerInterestDataset, setCustomerInterestDataset] = useState([]);
  const [ageCategoryDataset, setAgeCategoryDataset] = useState([]);

  useEffect(() => {
    handleChartValues();
   
  }, [insightsChartData, insightsMapData]);

  //console.log("insightsMapData from charts", insightsMapData);

  const handleChartValues = () => {
    const customerLifestage = getTopFive(insightsChartData?.customer_lifestage);
    const customerInterest = getTopFive(insightsChartData?.customer_interest);

    setLifeStagesDataset(customerLifestage || []);
    setCustomerInterestDataset(customerInterest || []);
    setAgeCategoryDataset(insightsChartData?.age_charge);
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={5.9}
            className="box-shadow"
            style={{ marginRight: "1%" }}
          >
            <div className="sec-header-title" style={{ paddingLeft: "8px" }}>Home Period</div>
            <TrafficMap
              color="blue"
              locations={insightsMapData?.home}
              search={insightsMapData?.search}
            />
          </Grid>
          <Grid item xs={5.9} className="box-shadow">
            <div className="sec-header-title" style={{ paddingLeft: "8px" }}>Work Period</div>
            <TrafficMap
              color="red"
              locations={insightsMapData?.work}
              search={insightsMapData?.search}
            />
          </Grid>
          <Grid
            item
            xs={5.9}
            className="box-shadow"
            style={{ marginRight: "1%", marginTop: "16px" }}
          >
            <div style={{ minHeight: "250px" }}>
              <BarChartCustom label="Life Stages" data={lifeStagesDataset} />
            </div>
          </Grid>
          <Grid
            item
            xs={5.9}
            className="box-shadow"
            style={{ marginTop: "16px" }}
          >
            <div style={{ minHeight: "250px" }}>
              <BarChartCustom
                label="Customer Interest"
                data={customerInterestDataset}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            className="box-shadow"
            style={{ marginTop: "16px" }}
          >
            <div style={{ minHeight: "500px" }}>
              <StackedBarChartCustom data={ageCategoryDataset} />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LocationCharts;
