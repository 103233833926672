import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "./AuthContextProvider";
import { Icon, IconButton } from "@mui/material";
import { MailOutline } from "@mui/icons-material";

function Sidebar() {
  const location = useLocation();
  const { isAdmin } = useAuthContext();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className="side-menu">
      <div className="top-menu">
        <div className={`menu ${isActive("/MyAccount") ? "active" : ""}`}>
          <Link to="/MyAccount">
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 8.00001C10.5 6.35001 9.14999 5.00001 7.49999 5.00001C5.84999 5.00001 4.49999 6.35001 4.49999 8.00001C4.49999 9.65001 5.84999 11 7.49999 11C9.14999 11 10.5 9.65001 10.5 8.00001ZM7.49999 9.00001C6.94999 9.00001 6.49999 8.55001 6.49999 8.00001C6.49999 7.45001 6.94999 7.00001 7.49999 7.00001C8.04999 7.00001 8.49999 7.45001 8.49999 8.00001C8.49999 8.55001 8.04999 9.00001 7.49999 9.00001ZM14 11C15.11 11 16 10.11 16 9.00001C16 7.89001 15.11 7.00001 14 7.00001C12.89 7.00001 11.99 7.89001 12 9.00001C12 10.11 12.89 11 14 11ZM9.98999 0.0100098C4.46999 0.0100098 -0.0100098 4.49001 -0.0100098 10.01C-0.0100098 15.53 4.46999 20.01 9.98999 20.01C15.51 20.01 19.99 15.53 19.99 10.01C19.99 4.49001 15.51 0.0100098 9.98999 0.0100098ZM3.83999 15.12C4.51999 14.58 6.10999 14.01 7.49999 14.01C7.56999 14.01 7.64999 14.02 7.72999 14.02C7.96999 13.38 8.39999 12.73 9.02999 12.16C8.46999 12.06 7.93999 12 7.49999 12C6.19999 12 4.10999 12.45 2.76999 13.43C2.26999 12.39 1.98999 11.23 1.98999 10C1.98999 5.59001 5.57999 2.00001 9.98999 2.00001C14.4 2.00001 17.99 5.59001 17.99 10C17.99 11.2 17.72 12.34 17.24 13.37C16.24 12.78 14.88 12.5 14 12.5C12.48 12.5 9.49999 13.31 9.49999 15.2V17.98C7.22999 17.85 5.20999 16.77 3.83999 15.12Z"
                  fill="#323232"
                />
              </svg>
            </div>
            {isAdmin() ? "Admin Page" : " My Package"}
          </Link>
        </div>

        {!isAdmin() && (
          <div className={`menu ${isActive("/ChannelSetup") ? "active" : ""}`}>
            <Link to="/ChannelSetup">
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.3766 23C11.3403 23 10.4643 22.6375 9.7487 21.9125C9.03312 21.1875 8.67532 20.3 8.67532 19.25C8.67532 18.3833 8.9303 17.6208 9.44026 16.9625C9.95022 16.3042 10.6 15.8583 11.3896 15.625V13.5H5.46753V9.5H3V2.5H9.90909V9.5H7.44156V11.5H17.3117V9.375C16.5221 9.14167 15.8723 8.69583 15.3623 8.0375C14.8524 7.37917 14.5974 6.61667 14.5974 5.75C14.5974 4.7 14.9552 3.8125 15.6708 3.0875C16.3864 2.3625 17.2623 2 18.2987 2C19.3351 2 20.211 2.3625 20.9266 3.0875C21.6422 3.8125 22 4.7 22 5.75C22 6.61667 21.745 7.37917 21.2351 8.0375C20.7251 8.69583 20.0753 9.14167 19.2857 9.375V13.5H13.3636V15.625C14.1532 15.8583 14.803 16.3042 15.313 16.9625C15.8229 17.6208 16.0779 18.3833 16.0779 19.25C16.0779 20.3 15.7201 21.1875 15.0045 21.9125C14.289 22.6375 13.413 23 12.3766 23ZM18.2987 7.5C18.7758 7.5 19.1829 7.32917 19.5201 6.9875C19.8574 6.64583 20.026 6.23333 20.026 5.75C20.026 5.26667 19.8574 4.85417 19.5201 4.5125C19.1829 4.17083 18.7758 4 18.2987 4C17.8216 4 17.4145 4.17083 17.0773 4.5125C16.74 4.85417 16.5714 5.26667 16.5714 5.75C16.5714 6.23333 16.74 6.64583 17.0773 6.9875C17.4145 7.32917 17.8216 7.5 18.2987 7.5ZM4.97403 7.5H7.93506V4.5H4.97403V7.5ZM12.3766 21C12.8537 21 13.2608 20.8292 13.5981 20.4875C13.9353 20.1458 14.1039 19.7333 14.1039 19.25C14.1039 18.7667 13.9353 18.3542 13.5981 18.0125C13.2608 17.6708 12.8537 17.5 12.3766 17.5C11.8996 17.5 11.4924 17.6708 11.1552 18.0125C10.818 18.3542 10.6494 18.7667 10.6494 19.25C10.6494 19.7333 10.818 20.1458 11.1552 20.4875C11.4924 20.8292 11.8996 21 12.3766 21Z"
                    fill="#202529"
                  />
                </svg>
              </div>
              Channel
              <br /> Setup
            </Link>
          </div>
        )}

        {!isAdmin() && (
          <div
            className={`menu ${
              isActive("/Audience") ||
              isActive("/CreateAudience") ||
              isActive("/AudienceList")
                ? "active"
                : ""
            }`}
          >
            <Link to="/Audience">
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 16C16.1667 16 15.4583 15.7083 14.875 15.125C14.2917 14.5417 14 13.8333 14 13C14 12.1667 14.2917 11.4583 14.875 10.875C15.4583 10.2917 16.1667 10 17 10C17.8333 10 18.5417 10.2917 19.125 10.875C19.7083 11.4583 20 12.1667 20 13C20 13.8333 19.7083 14.5417 19.125 15.125C18.5417 15.7083 17.8333 16 17 16ZM17 14C17.2833 14 17.5208 13.9042 17.7125 13.7125C17.9042 13.5208 18 13.2833 18 13C18 12.7167 17.9042 12.4792 17.7125 12.2875C17.5208 12.0958 17.2833 12 17 12C16.7167 12 16.4792 12.0958 16.2875 12.2875C16.0958 12.4792 16 12.7167 16 13C16 13.2833 16.0958 13.5208 16.2875 13.7125C16.4792 13.9042 16.7167 14 17 14ZM11 23V20.1C11 19.75 11.0833 19.4208 11.25 19.1125C11.4167 18.8042 11.65 18.5583 11.95 18.375C12.4833 18.0583 13.0458 17.7958 13.6375 17.5875C14.2292 17.3792 14.8333 17.225 15.45 17.125L17 19L18.55 17.125C19.1667 17.225 19.7667 17.3792 20.35 17.5875C20.9333 17.7958 21.4917 18.0583 22.025 18.375C22.325 18.5583 22.5625 18.8042 22.7375 19.1125C22.9125 19.4208 23 19.75 23 20.1V23H11ZM12.975 21H16.05L14.7 19.35C14.4 19.4333 14.1083 19.5417 13.825 19.675C13.5417 19.8083 13.2583 19.95 12.975 20.1V21ZM17.95 21H21V20.1C20.7333 19.9333 20.4583 19.7875 20.175 19.6625C19.8917 19.5375 19.6 19.4333 19.3 19.35L17.95 21ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V10C20.7333 9.66667 20.4417 9.35 20.125 9.05C19.8083 8.75 19.4333 8.55 19 8.45V5H5V19H9.15C9.1 19.1833 9.0625 19.3667 9.0375 19.55C9.0125 19.7333 9 19.9167 9 20.1V21H5ZM7 9H14C14.4333 8.66667 14.9083 8.41667 15.425 8.25C15.9417 8.08333 16.4667 8 17 8V7H7V9ZM7 13H12C12 12.65 12.0375 12.3083 12.1125 11.975C12.1875 11.6417 12.2917 11.3167 12.425 11H7V13ZM7 17H10.45C10.6333 16.85 10.8292 16.7167 11.0375 16.6C11.2458 16.4833 11.4583 16.375 11.675 16.275V15H7V17ZM5 19V5V8.425V8V19Z"
                    fill="#737373"
                  />
                </svg>
              </div>
              Audience
              <br /> List
            </Link>
          </div>
        )}

        {isAdmin() && (
          <div
            className={`menu ${isActive("/PackageManagement") ? "active" : ""}`}
          >
            <Link to="/PackageManagement">
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.3885 22L16.1007 20.5C15.9089 20.4167 15.729 20.3292 15.5612 20.2375C15.3933 20.1458 15.2214 20.0333 15.0456 19.9L13.6547 20.35L12.6954 18.65L13.7986 17.65C13.7666 17.45 13.7506 17.2333 13.7506 17C13.7506 16.7667 13.7666 16.55 13.7986 16.35L12.6954 15.35L13.6547 13.65L15.0456 14.1C15.2214 13.9667 15.3933 13.8542 15.5612 13.7625C15.729 13.6708 15.9089 13.5833 16.1007 13.5L16.3885 12H18.307L18.5947 13.5C18.7866 13.5833 18.9664 13.6708 19.1343 13.7625C19.3022 13.8542 19.474 13.9667 19.6499 14.1L21.0408 13.65L22 15.35L20.8969 16.35C20.9289 16.55 20.9448 16.7667 20.9448 17C20.9448 17.2333 20.9289 17.45 20.8969 17.65L22 18.65L21.0408 20.35L19.6499 19.9C19.474 20.0333 19.3022 20.1458 19.1343 20.2375C18.9664 20.3292 18.7866 20.4167 18.5947 20.5L18.307 22H16.3885ZM17.3477 19C17.8753 19 18.3269 18.8042 18.7026 18.4125C19.0783 18.0208 19.2662 17.55 19.2662 17C19.2662 16.45 19.0783 15.9792 18.7026 15.5875C18.3269 15.1958 17.8753 15 17.3477 15C16.8201 15 16.3685 15.1958 15.9928 15.5875C15.6171 15.9792 15.4293 16.45 15.4293 17C15.4293 17.55 15.6171 18.0208 15.9928 18.4125C16.3685 18.8042 16.8201 19 17.3477 19ZM3.91847 18V6V10.3V10V18ZM3.91847 20C3.39089 20 2.93925 19.8042 2.56355 19.4125C2.18785 19.0208 2 18.55 2 18V6C2 5.45 2.18785 4.97917 2.56355 4.5875C2.93925 4.19583 3.39089 4 3.91847 4H9.67386L11.5923 6H19.2662C19.7938 6 20.2454 6.19583 20.6211 6.5875C20.9968 6.97917 21.1847 7.45 21.1847 8V11.275C20.8969 11.0583 20.5931 10.8708 20.2734 10.7125C19.9536 10.5542 19.6179 10.4167 19.2662 10.3V8H10.801L8.88249 6H3.91847V18H10.705C10.753 18.35 10.8289 18.6917 10.9329 19.025C11.0368 19.3583 11.1607 19.6833 11.3046 20H3.91847Z"
                    fill="#1C1B1F"
                  />
                </svg>
              </div>
              <div>Package Management</div>
            </Link>
          </div>
        )}

        {isAdmin() && (
          <div className={`menu ${isActive("/Report") ? "active" : ""}`}>
            <Link to="/Report">
              <div>
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 10V8H12V10H4ZM4 6V4H12V6H4ZM2 12H9.5C9.98333 12 10.4333 12.1042 10.85 12.3125C11.2667 12.5208 11.6167 12.8167 11.9 13.2L14 15.95V2H2V12ZM2 18H13.05L10.325 14.425C10.225 14.2917 10.1042 14.1875 9.9625 14.1125C9.82083 14.0375 9.66667 14 9.5 14H2V18ZM14 20H2C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H14C14.55 0 15.0208 0.195833 15.4125 0.5875C15.8042 0.979167 16 1.45 16 2V18C16 18.55 15.8042 19.0208 15.4125 19.4125C15.0208 19.8042 14.55 20 14 20Z"
                    fill="#737373"
                  />
                </svg>
              </div>
              <div>Report</div>
            </Link>
          </div>
        )}
      </div>

      <div className="bottom-menu">
        {isAdmin() && (
          <div className={`menu ${isActive("/EmailTemplate") ? "active" : ""}`}>
            <Link to="/EmailTemplate">
              <div>
                <MailOutline />
              </div>
              Email
            </Link>
          </div>
        )}
        <div className={`menu ${isActive("/ChangePassword") ? "active" : ""}`}>
          <Link to="/ChangePassword">
            <div>
              <svg
                width="24"
                height="14"
                viewBox="0 0 24 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 14H16V10H13.32C12.18 12.42 9.72 14 7 14C3.14 14 0 10.86 0 7C0 3.14 3.14 0 7 0C9.72 0 12.17 1.58 13.32 4H24V10H22V14ZM18 12H20V8H22V6H11.94L11.71 5.33C11.01 3.34 9.11 2 7 2C4.24 2 2 4.24 2 7C2 9.76 4.24 12 7 12C9.11 12 11.01 10.66 11.71 8.67L11.94 8H18V12ZM7 10C5.35 10 4 8.65 4 7C4 5.35 5.35 4 7 4C8.65 4 10 5.35 10 7C10 8.65 8.65 10 7 10ZM7 6C6.45 6 6 6.45 6 7C6 7.55 6.45 8 7 8C7.55 8 8 7.55 8 7C8 6.45 7.55 6 7 6Z"
                  fill="#323232"
                />
              </svg>
            </div>
            Change
            <br /> Password
          </Link>
        </div>

        <div className={`menu ${isActive("/UserProfile") ? "active" : ""}`}>
          <Link to="/UserProfile">
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 8.00001C10.5 6.35001 9.14999 5.00001 7.49999 5.00001C5.84999 5.00001 4.49999 6.35001 4.49999 8.00001C4.49999 9.65001 5.84999 11 7.49999 11C9.14999 11 10.5 9.65001 10.5 8.00001ZM7.49999 9.00001C6.94999 9.00001 6.49999 8.55001 6.49999 8.00001C6.49999 7.45001 6.94999 7.00001 7.49999 7.00001C8.04999 7.00001 8.49999 7.45001 8.49999 8.00001C8.49999 8.55001 8.04999 9.00001 7.49999 9.00001ZM14 11C15.11 11 16 10.11 16 9.00001C16 7.89001 15.11 7.00001 14 7.00001C12.89 7.00001 11.99 7.89001 12 9.00001C12 10.11 12.89 11 14 11ZM9.98999 0.0100098C4.46999 0.0100098 -0.0100098 4.49001 -0.0100098 10.01C-0.0100098 15.53 4.46999 20.01 9.98999 20.01C15.51 20.01 19.99 15.53 19.99 10.01C19.99 4.49001 15.51 0.0100098 9.98999 0.0100098ZM3.83999 15.12C4.51999 14.58 6.10999 14.01 7.49999 14.01C7.56999 14.01 7.64999 14.02 7.72999 14.02C7.96999 13.38 8.39999 12.73 9.02999 12.16C8.46999 12.06 7.93999 12 7.49999 12C6.19999 12 4.10999 12.45 2.76999 13.43C2.26999 12.39 1.98999 11.23 1.98999 10C1.98999 5.59001 5.57999 2.00001 9.98999 2.00001C14.4 2.00001 17.99 5.59001 17.99 10C17.99 11.2 17.72 12.34 17.24 13.37C16.24 12.78 14.88 12.5 14 12.5C12.48 12.5 9.49999 13.31 9.49999 15.2V17.98C7.22999 17.85 5.20999 16.77 3.83999 15.12Z"
                  fill="#323232"
                />
              </svg>
            </div>
            User
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
