import { format } from "date-fns";
import mustache from "mustache";

const formatDate = (dateString) => {
  if (!dateString) return "";
  return format(dateString, "dd-MMM-yyyy");
};

const formatDatetime = (dateTime) => {
  if (!dateTime) return "";
  return format(dateTime, "dd-MMM-yyyy HH:mm");
};

const getStatusDot = (color) => {
  const dotStyle = {
    marginRight: "5px",
  };
  return <span style={{ ...dotStyle, color }}>●</span>;
};

const convertArrayToObjects = (array) => {
  return array.map((item) => ({ value: item }));
};

const convertDistricts = (array) => {
  return array.map((item) => ({
    value: {
      region: item.region,
      province: item.province,
      district: item.district,
    },
  }));
};

const transformedCreateAudData = (data) => {
  const transformedData = {};
  for (const key in data) {
    transformedData[key] = {
      filters: data[key].map((item) => ({ value: item.value })),
    };
  }
  return transformedData;
};

const convertToArray = (obj) => {
  return obj?.filters.map((filter) => filter?.value);
};

const transformFilters = (filters) => {
  return filters.map((filter) => ({
    value: filter.value,
    label: filter.value,
    //sublabel: sublabelMapping[filter.value],
  }));
};

// Transform the demo object
const transformedData = (data) => {
  let transformedData = {};
  for (const key in data) {
    if (data[key].filters) {
      transformedData[key] = transformFilters(data[key].filters);
    }
  }

  return transformedData;
};

const getStatusStyle = (status) => {
  const dotStyle = {
    marginRight: "5px",
  };
  const statusText = status ? status.toLocaleLowerCase() : "unknown";

  let dotColor;
  switch (statusText) {
    case "active":
      dotColor = "green";
      break;
    case "deactive":
      dotColor = "red";
      break;
    case "expired":
      dotColor = "red";
      break;
    default:
      dotColor = "yellow";
  }

  return (
    <span>
      <span style={{ ...dotStyle, color: dotColor }}>●</span>
      {status}
    </span>
  );
};

const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

const debugService = () => {
  const attachDebugMessage = () => {
    if (document.querySelector(".debug-message")) return;

    const version = process.env.REACT_APP_VERSION_UAT || "1.0";

    const env = process.env.NEXT_PUBLIC_ENV;
    const hideVersionLabel = env === "PRD";

    const floatingMessage = document.createElement("div");
    floatingMessage.classList.add("debug-message");
    floatingMessage.style.cssText = `position: fixed; z-index: 999; bottom: 0.5rem; left: 0.5rem; text-align: left; opacity: ${
      hideVersionLabel ? "0" : "0.7"
    }; font-size: 0.8rem;`;
    floatingMessage.innerHTML = `${version}`;

    document.body.appendChild(floatingMessage);
  };

  attachDebugMessage();
  return "debug-service";
};

const formatCurrency = (input) => {
  // Extract the numerical part and the currency part
  let match = input.match(/^(\d+)([A-Z]+)$/);
  if (match) {
    let number = match[1];
    let currency = match[2];

    // Format the number with commas
    let formattedNumber = Number(number).toLocaleString();

    // Combine the formatted number with the currency symbol
    return `${formattedNumber} ${currency}`;
  }
  return input;
};

const extractVariables = (template) => {
  const parsedTemplate = mustache.parse(template);
  const variables = new Set();
  parsedTemplate.forEach((token) => {
    if (token[0] === "name") {
      variables.add(token[1]);
    }
  });
  return [...variables];
};

const capitalizeFirstLetter = (inputString) => {
  return inputString
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const convertToCamelCase = (inputString) => {
  return inputString
    .replace(/_/g, " ")
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    );
};

const removeUnderscore = (inputString) => {
  return inputString
    .replace(/_/g, " ");
};

const convertProvince = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const getTopFive = (data) => {
  if (!data) return [];

  // Filter out elements with null category and take the first 5
  const filteredData = data.filter((element) => element.category != null).slice(0, 5);

  return filteredData;
};

const countFilters = (obj) => {
  let count = 0;

  for (const key in obj) {
    if (obj[key].filters) {
      count += obj[key].filters.length;
    } else if (typeof obj[key] === "object") {
      count += countFilters(obj[key]);
    }
  }

  return count;
};

export {
  formatDate,
  getStatusDot,
  convertArrayToObjects,
  transformedCreateAudData,
  convertToArray,
  transformedData,
  getStatusStyle,
  formatDatetime,
  convertDistricts,
  debounce,
  debugService,
  formatCurrency,
  extractVariables,
  capitalizeFirstLetter,
  convertToCamelCase,
  removeUnderscore,
  convertProvince,
  getTopFive,
  countFilters
};
