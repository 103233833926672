import { useState, useEffect } from "react";

const useLanguage = () => {
  const [lng, setLng] = useState(localStorage.getItem("lng") || "en");

  useEffect(() => {
    localStorage.setItem("lng", lng);
  }, [lng]);

  const changeLanguage = (language) => {
    setLng(language);
  };

  return [lng, changeLanguage];
};

export default useLanguage;
