import React, { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import APIS from "./APIS";
import AppLogo from "../Components/AppLogo";
import { ArrowBack } from "@mui/icons-material";
import { useRegisterContext } from "../Components/RegisterContext";
import { emailRegx } from "../helper/constant";
import { Close } from "@mui/icons-material";
import CustomInputField from "../Components/ui/CustomInputField";
import GlobalLoading from "./GlobalLoading";

const ResetPassword = () => {
  const [resetState, setResetState] = useState({
    email: "",
    emailError: "",
  });
  const navigate = useNavigate();
  const { setForgotPasswordData } = useRegisterContext();
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const [globalLoading, setGlobalLoading] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    const { email } = resetState;
    const isValidEmail = emailRegx.test(email);

    if (!email || !isValidEmail) {
      setResetState({
        ...resetState,
        emailError: !email
          ? "This field is required. "
          : "The email address format is incorrect. Please enter your valid email address.",
      });
      return;
    }

    try {
      setGlobalLoading(true);
      const formData = {
        email: resetState.email,
      };

      setForgotPasswordData(formData);
      const response = await APIS.ForgotPassword(formData);
      if (response.code == 200) {
        // const displaydata = {
        //   text1: "We have received your request",
        //   text2: "Reset password link was sent",
        //   text3: "Use the link we sent you to create your new password.",
        //   text4: "Please check your email",
        // };
        setGlobalLoading(false);
        navigate("/ResendPwdResetLink");
      } else if (response.code == 404) {
        setGlobalLoading(false);
        setResetState({
          ...resetState,
          emailError: "This email doesn't match any account. Please try again.",
        });
      } else if (response.code == 421 || response.code == 422) {
        setGlobalLoading(false);
        setResetState({
          ...resetState,
          emailError: response.message,
        });
      } else {
        setGlobalLoading(false);
        setErrorSnackbar(response.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleBack = () => {
    setForgotPasswordData({
      email: resetState.email,
    });
    navigate("/Login");
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="login-container">
      {globalLoading && <GlobalLoading />}
      <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "10000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <AppLogo />
      <h3>Reset Password</h3>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        onSubmit={handleClick}
        noValidate
        autoComplete="off"
      >
        <div>
          <div className="info-sec">
            No worries! Let's get your password sorted out. Please enter your
            email address below to receive instructions on resetting your
            password.
          </div>
          <CustomInputField
            label="Email"
            id="email"
            value={resetState.email}
            required={true}
            onChange={(e) =>
              setResetState({ email: e.target.value, emailError: "" })
            }
            error={!!resetState.emailError}
            size="small"
            placeholder="Enter Email"
            helperText={resetState.emailError}
          />
          <Button variant="contained" size="medium" type="submit">
            RESET PASSWORD
          </Button>
        </div>
        <div className="back-text back-text-center" onClick={handleBack}>
          <ArrowBack />
          <span>BACK TO LOG-IN</span>
        </div>
      </Box>
    </div>
  );
};

export default ResetPassword;
