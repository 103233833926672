import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import APIS from "./APIS";
import AudienceFooter from "./AudienceFooter";
import HelpIcon from "../Assets/help.svg";
import { IconButton, Snackbar } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { capitalizeFirstLetter, removeUnderscore } from "../helper/util";

const CustomerInterest = ({
  custInterestData,
  custInterestselectedFilters,
  setCustInterestselectedFilters,
  handleNextTab,
  conjunctionType,
  setConjunctionType,
  handleTotalCount,
  audTotalCount,
  handleClearAll,
  audTotalCategories,
  handleCalculateTotalCategories,
  handleAddCondition
}) => {
  const columnLabel = {
    width: "15%",
    color: "#878787",
    fontSize: "12px",
    fontWeight: "600",
    border: "none !important",
  };
  const [mainTabValue, setMainTabValue] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [errorSnackbar, setErrorSnackbar] = useState("");

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const setConjunction = (type) => {
    setConjunctionType(type);
  };

  useEffect(() => {
    calculateCount();
  }, [custInterestselectedFilters]);

  const calculateCount = () => {
    // const countFilters = (obj) => {
    //   let count = 0;

    //   for (const key in obj) {
    //     if (obj[key].filters) {
    //       count += obj[key].filters.length;
    //     } else if (typeof obj[key] === "object") {
    //       count += countFilters(obj[key]);
    //     }
    //   }

    //   return count;
    // };

    // // Initial call to the recursive function with the main object
    // let totalSelectionCount = countFilters(custInterestselectedFilters);

    // setTotalSelectionCount(totalSelectionCount);

    handleCalculateTotalCategories();
  };

  const filteredCategories = Object.keys(custInterestData).filter(
    (category) => {
      // Check if the category matches the search value
      if (category.toLowerCase().includes(searchValue.toLowerCase())) {
        return true;
      }

      // Check if any subcategory in this category matches the search value
      const subcategories = Object.keys(custInterestData[category]);
      for (const subcategory of subcategories) {
        if (subcategory.toLowerCase().includes(searchValue.toLowerCase())) {
          return true;
        }
      }

      return false;
    }
  );

  const toggleFilter = (category, subcategory, value) => {
    setCustInterestselectedFilters((prevFilters) => {
      // Initialize updatedFilters as a copy of prevFilters
      const updatedFilters = { ...prevFilters };

      // Check if the category exists in updatedFilters
      if (!updatedFilters[category]) {
        updatedFilters[category] = {}; // If not, initialize it as an empty object
      }

      // Check if the subcategory exists in updatedFilters[category]
      if (!updatedFilters[category][subcategory]) {
        updatedFilters[category][subcategory] = { filters: [{ value: value }] }; // If not, initialize it with the checked value
      } else {
        // Toggle the filter
        const index = updatedFilters[category][subcategory].filters.findIndex(
          (item) => item.value === value
        ); // Get the index of the value

        if (index !== -1) {
          // If value is found, remove it
          updatedFilters[category][subcategory].filters.splice(index, 1);
        } else {
          // Otherwise, add it
          updatedFilters[category][subcategory].filters.push({ value: value });
        }

        // If filters array becomes empty, remove the subcategory
        if (updatedFilters[category][subcategory].filters.length === 0) {
          delete updatedFilters[category][subcategory];
        }
      }

      // If category becomes empty, remove it
      if (Object.keys(updatedFilters[category]).length === 0) {
        delete updatedFilters[category];
      }

      // Log and return updatedFilters
      return updatedFilters;
    });
  };

  const isChecked = (cat, subCat, filterValue) => {
    if (
      custInterestselectedFilters[cat] &&
      custInterestselectedFilters[cat][subCat] &&
      custInterestselectedFilters[cat][subCat].filters.length > 0 &&
      custInterestselectedFilters[cat][subCat].filters.some(
        (filter) => filter.value == filterValue
      )
    ) {
      return true;
    }
    return false;
  };

  const convertCount = (count) => {
    let convertedCount = count;
    if (count) {
      if (count >= 1000000) {
        convertedCount = (count / 1000000).toFixed(2) + "M";
      } else if (count >= 1000) {
        convertedCount = (count / 1000).toFixed(2) + "K";
      } else {
        convertedCount = count;
      }
    }

    return convertedCount;
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleClear = () => {
    setSearchValue("");
    handleClearAll();
  };

  return (
    <div>
      <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "5000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <div className="sec-title">Customer Interest</div>
      <Grid container spacing={2} className="content-container">
        <Grid item xs={12}>
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            <Button
              onClick={() => setConjunction("AND")}
              variant={conjunctionType === "AND" ? "contained" : "outlined"}
              sx={{
                border: "1px solid #737373",
                color: conjunctionType === "AND" ? "#FFFFFF" : "#878787",
              }}
            >
              AND
            </Button>
            <Button
              onClick={() => setConjunction("OR")}
              variant={conjunctionType === "OR" ? "contained" : "outlined"}
              sx={{
                border: "1px solid #737373",
                color: conjunctionType === "OR" ? "#FFFFFF" : "#878787",
              }}
            >
              OR
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={{ width: "100%" }}
            size="small"
            placeholder="Select..."
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
            value={searchValue}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="content-card">
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={mainTabValue}
              onChange={handleMainTabChange}
              className="tab-box"
            >
              {filteredCategories.map((category, index) => (
                <Tab
                  key={category}
                  value={index}
                  label={
                    <div className="tab-label-cust-int">
                      {removeUnderscore(category)}
                      {/* {category} */}
                      {/* <img src={HelpIcon} /> */}
                    </div>
                  }
                />
              ))}
            </Tabs>
          </div>
        </Grid>
      </Grid>

      <div>
        <AudienceFooter
          count={audTotalCount}
          onCalculate={handleTotalCount}
          onNextClick={handleNextTab}
          totalSlectionCount={audTotalCategories}
          onChildClick={handleClear}
          handleAddCondition={handleAddCondition}
        />
      </div>
      {/* Iterate over each category */}
      {filteredCategories.map((category, index) => (
        <TabPanel key={category} value={mainTabValue} index={index}>
          {/* Iterate over each subcategory in the category */}
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ width: "55%", border: "none !important" }}
                ></TableCell>
                <TableCell sx={columnLabel}>High</TableCell>
                <TableCell sx={columnLabel}>Medium</TableCell>
                <TableCell sx={columnLabel}>Low</TableCell>
              </TableRow>
              {Object.keys(custInterestData[category]).map((subcategory) => (
                <TableRow key={subcategory}>
                  <TableCell sx={{ width: "55%" }}>
                    {capitalizeFirstLetter(removeUnderscore(subcategory))}
                  </TableCell>
                  {[
                    { value: "high" },
                    { value: "medium" },
                    { value: "low" },
                  ].map((filter, index) => (
                    <TableCell key={index} sx={{ width: "15%" }}>
                      <Checkbox
                        checked={isChecked(category, subcategory, filter.value)}
                        onChange={() =>
                          toggleFilter(category, subcategory, filter.value)
                        }
                      />
                      <label>
                        {convertCount(
                          custInterestData[category][subcategory].filters.find(
                            (f) => f.value === filter.value
                          )?.count
                        ) || "0"}
                      </label>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TabPanel>
      ))}
    </div>
  );
};

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
};

export default CustomerInterest;
