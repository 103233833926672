import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import {
  Search,
  CalendarTodayOutlined,
  Close,
  FileDownloadOutlined,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  TextField,
  Button,
  Snackbar,
  IconButton,
} from "@mui/material";
import ReactDatePicker from "react-datepicker";
import APIS from "../../Pages/APIS";
import { useAuthContext } from "../AuthContextProvider";
import ReportTable from "./ReportTable";
import CustomSelect from "../ui/CustomSelect";
import AudSummaryReportTable from "./AudSummaryReportTable";

const ReportList = () => {
  const [query, setQuery] = useState({
    searchTerm: "",
    status: "All",
    startDate: "",
    endDate: "",
  });
  const [reportData, setReportData] = useState([]);
  const [reportType, setReportType] = useState("Package Summary Report");
  const [loading, setLoading] = useState(false); // Add loading state
  const { token, userId } = useAuthContext();
  const [dateRange, setDateRange] = useState([]);
  const [showPackageSummary, setShowPackageSummary] = useState(false);
  const [showAudienceSummary, setShowAudienceSummary] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const isInitialMount = useRef(true);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 10,
    totalCount: 0,
  });
  const [paginationAud, setPaginationAud] = useState({
    page: 0,
    limit: 10,
    totalCount: 0,
  });

  const menuItems = [
    { value: "Package Summary Report", label: "Package Summary Report" },
    {
      value: "Audience Usage Summary Report",
      label: "Audience Usage Summary Report",
    },
  ];

  const statusOptionsPS = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
    { value: "Expired", label: "Expired" },
  ];

  const statusOptionsAS = [
    { value: "All", label: "All" },
    { value: "Completed", label: "Completed" },
    { value: "Failed", label: "Failed" },
    { value: "In Progress", label: "In Progress" },
  ];

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (reportType) {
        handleSearch();
      }
    }
  }, [
    pagination.page,
    pagination.limit,
    paginationAud.page,
    paginationAud.limit,
  ]);

  const handleSearch = () => {
    if (reportType == "Package Summary Report") {
      fetchPackageSummaryReport();
      setShowPackageSummary(true);
      setShowAudienceSummary(false);
    } else {
      fetchAudienceSummaryReport();
      setShowAudienceSummary(true);
      setShowPackageSummary(false);
    }
  };

  const fetchPackageSummaryReport = async () => {
    const urlParams = new URLSearchParams({
      companyName: query.searchTerm,
      status: query.status == "All" ? "" : query.status,
      page: pagination.page + 1,
      limit: pagination.limit,
    });
    const queryString = urlParams.toString();
    try {
      setLoading(true); // Set loading to true when fetching data
      const response = await APIS.getPackageSummaryReport(token, queryString);
      if (response.code === "200") {
        response.data?.forEach((element, index) => {
          element["index"] = index + 1;
        });
        setReportData(response.data ?? []); // Update projects state with fetched data
        setPagination({ ...pagination, totalCount: response.totalCount });
        setLoading(false); // Set loading to false after fetching data
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false); // Set loading to false in case of error
    }
  };

  const fetchAudienceSummaryReport = async () => {
    const urlParams = new URLSearchParams({
      companyName: query.searchTerm,
      status: query.status == "All" ? "" : query.status,
      page: paginationAud.page + 1,
      limit: paginationAud.limit,
    });
    const queryString = urlParams.toString();
    try {
      setLoading(true); // Set loading to true when fetching data
      const response = await APIS.getAudienceSummaryReport(token, queryString);
      if (response.code === "200") {
        response.data?.forEach((element, index) => {
          element["index"] = index + 1;
        });
        setReportData(response.data ?? []); // Update projects state with fetched data
        setPaginationAud({ ...paginationAud, totalCount: response.totalCount });
        setLoading(false); // Set loading to false after fetching data
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false); // Set loading to false in case of error
    }
  };

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      limit: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleChangePageAud = (event, newPage) => {
    setPaginationAud({ ...paginationAud, page: newPage });
  };

  const handleChangeRowsPerPageAud = (event) => {
    setPaginationAud({
      ...paginationAud,
      limit: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleDateChange = (dateRange) => {
    setDateRange(dateRange);
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const handleDownload = async () => {
    const urlParams = new URLSearchParams({
      reportType: reportType === "Package Summary Report" ? "PS" : "AS",
      companyName: query.searchTerm,
      status: query.status == "All" ? "" : query.status,
      //userId: userId,
    });
    const queryString = urlParams.toString();

    try {
      setLoading(true);
      const response = await APIS.downloadReport(token, queryString);
      const url = window.URL.createObjectURL(response);
      const link = document.createElement("a");
      const filename = `${reportType}.xlsx`;
      link.href = url;
      link.setAttribute("download", filename); 
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorSnackbar(error.message || "Some Error Occurred");
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Grid item>
      <Grid className="search-card" mt={2}>
        <Box
          sx={{
            width: "100%",
            display: "inline-flex",
          }}
          columnGap={1}
        >
          <TextField
            fullWidth
            value={query.searchTerm}
            onChange={(e) => {
              setQuery({ ...query, searchTerm: e.target.value });
            }}
            size="small"
            placeholder="Search by Company Name"
            InputProps={{
              startAdornment: <Search />,
            }}
          />
          <CustomSelect
            options={menuItems}
            placeholder="Report"
            onChange={(e) => {
              //setSearchTerm(e.target.value);
              setReportType(e.target.value);
              setQuery({ ...query, status: "All" });
            }}
            value={reportType}
          />
          <CustomSelect
            options={
              reportType == "Package Summary Report"
                ? statusOptionsPS
                : statusOptionsAS
            }
            placeholder="Status"
            onChange={(e) => {
              setQuery({ ...query, status: e.target.value });
            }}
            value={query.status}
          />
          <Button
            variant="contained"
            onClick={handleSearch}
            disabled={!reportType}
            sx={{
              backgroundColor: "#202529",
              color: "#FFFFFF !important",
              fontSize: "12px",
              minWidth: "80px",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "#F24822",
                borderColor: "#0062cc",
                boxShadow: "none",
              },
            }}
          >
            Search
          </Button>
        </Box>
        <Box
          sx={{
            width: "30%",
            display: "inline-flex",
          }}
        >
          <Button
            variant="contained"
            onClick={handleDownload}
            disabled={!reportType}
            sx={{
              backgroundColor: "#202529",
              color: "#FFFFFF !important",
              fontSize: "12px",
              minWidth: "80px",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "#F24822",
                borderColor: "#0062cc",
                boxShadow: "none",
              },
            }}
          >
            Download Report <FileDownloadOutlined />
          </Button>
        </Box>
      </Grid>
      {showPackageSummary &&
        (loading ? ( // Show loader if loading is true
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <ReportTable
            data={reportData}
            count={pagination.totalCount}
            rowPerPage={pagination.limit}
            page={pagination.page}
            handlePageChange={handleChangePage}
            handleRowsPerPageChange={handleChangeRowsPerPage}
          />
        ))}
      {showAudienceSummary &&
        (loading ? ( // Show loader if loading is true
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <AudSummaryReportTable
            data={reportData}
            count={paginationAud.totalCount}
            rowPerPage={paginationAud.limit}
            page={paginationAud.page}
            handlePageChange={handleChangePageAud}
            handleRowsPerPageChange={handleChangeRowsPerPageAud}
          />
        ))}
      <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "5000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </Grid>
  );
};

export default ReportList;
