import { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import APIS from "../../Pages/APIS";
import { formatDate, formatDatetime } from "../../helper/util";
import { useAuthContext } from "../AuthContextProvider";
import { CustomTable } from "../ui";

const PackageHistoryTable = ({ selectedAccount }) => {
  const [packageHistory, setpackageHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token, accountId } = useAuthContext();
  const isInitialMount = useRef(true);

  const columns = [
    {
      name: "Package Type",
      id: "package_type",
    },

    {
      name: "Package Name",
      id: "package_name",
    },
    {
      name: "Package Added By",
      id: "package_add_by",
    },

    {
      name: "Assigned Date",
      id: "package_add_date",
      minWidth: 150,
      element: (row) => `${formatDatetime(row.package_add_date?.value)}`,
    },
    {
      name: "Start Date",
      id: "package_start_date",
      minWidth: 100,
      element: (row) => `${formatDate(row.package_start_date?.value)}`,
    },
    {
      name: "Expiry Date",
      id: "package_end_date",
      minWidth: 100,
      element: (row) => `${formatDate(row.package_end_date?.value)}`,
    },
    {
      name: "Export Count",
      id: "export_count",
      element: (row) => (row.export_count ? row.export_count : "0"),
    },
    {
      name: "Number of Audience per push",
      id: "audience_size_per_export",
    },
    {
      name: "Duration",
      id: "duration",
      element: (row) => (row.duration ? row.duration + " Day(s)" : "0 Day(s)"),
    },
    { id: "connectors_count", name: "Connector Count" },
  ];

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      getPackageHistory();
    }
  }, []);

  const getPackageHistory = async () => {
    try {
      setLoading(true);

      const history = await APIS.getPackageUserHistory(token);

      setpackageHistory(history.packageHistory);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Fetching Audience Push History Error", error);
    }
  };

  return (
    <Grid display={"grid"} my={3} rowGap={2}>
      <h2>Package History</h2>

      <CustomTable
        columns={columns}
        data={packageHistory}
        hidePagination
        isLoading={loading}
      />
    </Grid>
  );
};

export default PackageHistoryTable;
