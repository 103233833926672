import * as React from "react";
import InterestsIcon from "@mui/icons-material/Interests";
import Footer from "../Components/Footer";
import { Grid, Button } from "@mui/material";

const CreateAudienceFooter = ({
  onBack,
  loadingCreateAud,
  buttonLabel,
  createAudience,
}) => {
  return (
    <div className="demo-ft-card">
      <Grid
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <div className="first-item">
          <Button variant="text"></Button>
        </div>
        <div className="center-item"></div>
        <div className="last-item">
          <Button
            variant="contained"
            onClick={onBack}
            sx={{
              borderRadius: "4px !important",
              backgroundColor: "#EDEEF6 !important",
              color: "#4F5464 !important",
              fontWeight: "700 !important",
            }}
            disabled={loadingCreateAud}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={(event) => createAudience()}
            sx={{
              borderRadius: "4px !important",
              fontWeight: "700 !important",
            }}
            disabled={loadingCreateAud}
          >
            {buttonLabel}
          </Button>
        </div>
      </Grid>
      <Footer />
    </div>
  );
};
export default CreateAudienceFooter;
