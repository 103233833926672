import ReactDatePicker from "react-datepicker";
import { CalendarTodayOutlined, Error } from "@mui/icons-material";
import { FormControl, FormLabel, FormHelperText } from "@mui/material";

const CustomDateInputField = ({
  name,
  onChange,
  placeholder,
  label,
  size,
  fullWidth = true,
  required,
  error,
  helperText,
  minDate,
  value,
  dateFormat = "dd-MMM-yyyy",
  className = "",
}) => {
  return (
    <FormControl
      fullWidth={fullWidth}
      size={size}
      required={required}
      error={error}
      className={className + `${error && " error"} `}
    >
      <FormLabel style={{ fontSize: "14px" }}>
        {error && <Error fontSize="14px" style={{ marginBottom: "-2px" }} />}
        {label}
      </FormLabel>
      <ReactDatePicker
        minDate={minDate}
        name={name}
        value={value}
        dateFormat={dateFormat}
        onChange={onChange}
        placeholderText={placeholder}
        showIcon
        toggleCalendarOnIconClick
        icon={<CalendarTodayOutlined />}
      />
      <FormHelperText error>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomDateInputField;
