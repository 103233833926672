import {
  TextField,
  IconButton,
  List,
  ListItem,
  InputAdornment,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState, useRef } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMapEvents,
  Tooltip,
  ZoomControl,
} from "react-leaflet";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { Clear } from "@mui/icons-material";

const CustomMap = ({
  locationTrafficData,
  setLocationTrafficData,
  positions,
  setPositions,
  errorSnackbar,
  setErrorSnackbar,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const mapRef = useRef(null);

  const LocationMarkers = () => {
    useMapEvents({
      click(e) {
        if (locationTrafficData.length < 25) {
          const isDuplicateLocation = locationTrafficData.some(
            (location) => location.home_lat === e.latlng.lat && location.home_long === e.latlng.lng
          );
      
          if (isDuplicateLocation) {
            setErrorSnackbar("Location already present");
            return;
          }
      
          setPositions((currentPositions) => [...currentPositions, e.latlng]);
          setLocationTrafficData([
            ...locationTrafficData,
            { home_lat: e.latlng.lat, home_long: e.latlng.lng, radius: 500 },
          ]);
          if (mapRef.current) {
            mapRef.current.flyTo(e.latlng, mapRef.current.getZoom());
          }
        } else {
          setErrorSnackbar("Cannot add more than 25 locations");
        }
      },
    });

    return positions.map((position, idx) => (
      <Marker key={idx} position={position}>
        <Tooltip>{`Lat: ${position.lat}, Lng: ${position.lng}`}</Tooltip>
      </Marker>
    ));
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${searchQuery}&countrycodes=TH`
    );
    const data = await response.json();
    if (data.length == 0) {
      setErrorSnackbar("No found location");
    }
    setSearchResults(data);
  };

  const handleClear = () => {
    setSearchQuery("");
    setSearchResults([]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="search-map">
        {/* <form onSubmit={handleSearch}> */}
        <div className="search-map-input">
          <TextField
            type="text"
            value={searchQuery}
            //onKeyUp={handleSearch}
            size="small"
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setSelectedLocation(null);
            }}
            placeholder="Search for a location"
            list="search-results"
            sx={{ backgroundColor: "#FFFFFF", width: "20vw" }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ backgroundColor: "#CCCCCC" }}
                >
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                  {searchQuery && (
                    <IconButton onClick={handleClear}>
                      <Clear />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            fullWidth
            onKeyDown={handleKeyDown}
          />
          {searchResults.length > 0 && !selectedLocation && (
            <List style={{ backgroundColor: "#FFFFFF", width: "20vw" }}>
              {searchResults.map((result, idx) => (
                <ListItem
                  key={idx}
                  sx={{
                    cursor: "pointer",
                    borderBottom: "1px solid #C3C3C3",
                    textWrap: "wrap",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    const { lat, lon, display_name } = result;
                    const newLatLng = {
                      lat: parseFloat(lat),
                      lng: parseFloat(lon),
                    };

                    const isDuplicateLocation = locationTrafficData.some(
                      (location) => location.home_lat === newLatLng.lat && location.home_long === newLatLng.lng
                    );
                
                    if (isDuplicateLocation) {
                      setErrorSnackbar("Location already exist.");
                      return;
                    }

                    if (mapRef.current && locationTrafficData.length < 25) {
                      mapRef.current.flyTo(newLatLng, 13);
                      setPositions((currentPositions) => [
                        ...currentPositions,
                        newLatLng,
                      ]);
                      setLocationTrafficData([
                        ...locationTrafficData,
                        {
                          home_lat: newLatLng.lat,
                          home_long: newLatLng.lng,
                          radius: 500,
                        },
                      ]);
                      setSearchQuery(display_name);
                      setSelectedLocation(display_name);
                      setSearchResults([]);
                    } else {
                      setErrorSnackbar("Cannot add more than 25 locations");
                    }
                  }}
                >
                  <RoomOutlinedIcon />
                  {result.display_name}
                </ListItem>
              ))}
            </List>
          )}
        </div>
        {/* </form> */}
      </div>
      <MapContainer
        center={[13.7851, 100.5943]}
        zoom={13}
        minZoom={10}
        maxZoom={18}
        zoomControl={false}
        scrollWheelZoom={true}
        ref={(mapInstance) => {
          mapRef.current = mapInstance;
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          updateWhenIdle={false}
          updateWhenZooming={false}
        />
        <ZoomControl position="bottomright" />
        <LocationMarkers />
      </MapContainer>
    </>
  );
};

export default CustomMap;
