import React, { useState, useEffect, useRef } from "react";
import TabPanel from "../Components/TabPanel";
import Navbar from "../Components/navbar";
import Sidebar from "../Components/sidebar";
import TabAudience from "./Tab-Audience";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import TabWebsiteTraffic from "./Tab-WebsiteTraffic";
import TabLocationTraffic from "./Tab-LocationTraffic";
import APIS from "./APIS";
import Close from "@mui/icons-material/Close";
import {
  IconButton,
  Snackbar,
  Grid,
  Box,
  Tab,
  Tabs,
  Button,
} from "@mui/material";
import { useAudienceContext } from "../Components/AudienceContext/AudienceContext";
import {
  convertArrayToObjects,
  transformedCreateAudData,
  convertDistricts,
  countFilters
} from "../helper/util";
import GlobalLoading from "./GlobalLoading";
import { useGlobalModalContext } from "../Components/GlobalModalContext/GlobalModalContext";
import ErrorMessageDialog from "../Components/AudienceList/ErrorMessageDialog";
import LabelWithTooltip from "../Components/LabelWithTooltip";
import AddConditionDialog from "../Components/AddConditionDialog";

const CreateAudience = ({ id }) => {
  const navigate = useNavigate();
  const [mainTabValue, setMainTabValue] = useState("aud");
  const [errorSnackbar, setErrorSnackbar] = useState("");

  const [locationObj, setLocationObj] = useState(null);
  const [loading, setLoading] = useState(true);

  const [demographicsData, setDemographicsData] = useState([]);
  const [loaderDemoData, setLoaderDemoData] = useState(true);

  const [custInterestData, setCustInterestData] = useState([]);
  const [loaderCustInterestData, setLoaderCustInterestData] = useState(true);

  const [commRelevantData, setCommRelevantData] = useState([]);
  const [loaderCommRelData, setLoaderCommRelData] = useState(true);

  const [locationTrafficData, setLocationTrafficData] = useState([]);
  const [websiteTrafficData, setWebsiteTrafficData] = useState([]);

  //Data Persist in state for all tabs
  const [demographicsselectedFilters, setDemographicsselectedFilters] =
    useState({});
  const [custInterestselectedFilters, setCustInterestselectedFilters] =
    useState({});
  //const [geographicsselectedFilters, setGeographicsselectedFilters] = useState({});
  const [commRelevantselectedFilters, setCommRelevantselectedFilters] =
    useState({});

  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedProvinces, setSelectedProvinces] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [provinceDistrictCount, setProvinceDistrictCount] = useState([]);
  const [regionProvinceCount, setRegionProvinceCount] = useState([]);
  const [conjunctionType, setConjunctionType] = useState("AND");
  const [conjunctionTypeWeb, setConjunctionTypeWeb] = useState("OR");
  const [selectedPeriod, setSelectedPeriod] = useState("1");
  const [selectedPeriodLoc, setSelectedPeriodLoc] = useState("1");
  const {
    setAudienceSummaryData,
    audienceSummaryData,
    setAudienceEnhancement,
    audienceEnhancement,
  } = useAudienceContext();
  const [loadingCreateAud, setLoadingCreateAud] = useState(false);
  const [audTotalCount, setAudTotalCount] = useState(0);
  const [audTotalCategories, setAudTotalCategories] = useState(0);
  const [positions, setPositions] = useState([]);
  const { showModal } = useGlobalModalContext();
  const isInitialMount = useRef(true);

  useEffect(() => {
    // Simulate an API call or any asynchronous operation
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchDemographicData();
      fetchCustomerInterestData();
      fetchCommercialRelevantData();
      getLocationData();
    }
    if (Object.keys(audienceSummaryData).length) {
      setAudienceData();
    }

    const element = document.getElementById("nomal-footer");
    if (element) {
      element.style.display = "none";
    }
    return () => {
      if (element) {
        element.style.display = "";
      }
    };
  }, []);

  const setAudienceData = () => {
    setDemographicsselectedFilters(
      audienceSummaryData?.demographicsselectedFilters
    );
    setCustInterestselectedFilters(
      audienceSummaryData?.custInterestselectedFilters
    );
    setCommRelevantselectedFilters(
      audienceSummaryData?.commRelevantselectedFilters
    );
    setSelectedRegions(audienceSummaryData?.selectedRegions);
    setSelectedDistricts(audienceSummaryData?.selectedDistricts);
    setSelectedProvinces(audienceSummaryData?.selectedProvinces);
    setWebsiteTrafficData(audienceSummaryData?.websiteTrafficData);
    setLocationTrafficData(audienceSummaryData?.locationTrafficData);
    setConjunctionType(audienceSummaryData?.conjunctionType);
    setConjunctionTypeWeb(audienceSummaryData?.conjunctionTypeWeb);
    setSelectedPeriod(audienceSummaryData?.selectedPeriod);
    setSelectedPeriodLoc(audienceSummaryData?.selectedPeriodLoc);
    setPositions(audienceSummaryData?.positions);
  };

  const getLocationData = async () => {
    setLoading(true);

    try {
      const response = await APIS.getGeographics(localStorage.getItem("token"));
      setLocationObj(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorSnackbar("Some Error Occurred");
    }
  };

  const fetchDemographicData = async () => {
    setLoaderDemoData(true);

    try {
      const response = await APIS.getDemographics(
        localStorage.getItem("token")
      );
      if (response.code == 200) {
        setDemographicsData(response.data.AudienceFilter.demo);
        setLoaderDemoData(false);
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
      }
    } catch (error) {
      setLoaderDemoData(false);
      setErrorSnackbar("Some Error Occurred");
    }
  };

  const fetchCustomerInterestData = async () => {
    setLoaderCustInterestData(true);

    try {
      const response = await APIS.getCustomerInterest(
        localStorage.getItem("token")
      );
      if (response.code == 200) {
        setCustInterestData(response.data.AudienceFilter.segment);
        setLoaderCustInterestData(false);
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
      }
    } catch (error) {
      setLoaderCustInterestData(false);
      setErrorSnackbar("Some Error Occurred");
    }
  };

  const fetchCommercialRelevantData = async () => {
    setLoaderCommRelData(true);

    try {
      const response = await APIS.getCommercialRelevant(
        localStorage.getItem("token")
      );
      if (response.code == 200) {
        setCommRelevantData(response.data.AudienceFilter.comm_relevance);
        setLoaderCommRelData(false);
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
      }
    } catch (error) {
      setLoaderCommRelData(false);
      setErrorSnackbar("Some Error Occurred");
    }
  };

  const handleTotalCount = async () => {
    const totalCountData = transformFilters();

    try {
      setLoadingCreateAud(true);
      let response = await APIS.calculateTotalCount(
        totalCountData,
        localStorage.getItem("token")
      );
      if (response.status == "success") {
        setLoadingCreateAud(false);
        setAudTotalCount(response.count);
        setAudienceSummaryData((prevData) => ({
          ...prevData,
          totalCount: response.count,
        }));
        return "success";
      } else if (response.status == "fail") {
        setLoadingCreateAud(false);
        showModal(
          <ErrorMessageDialog
            title={response?.message}
            description={response?.error}
          />
        );
        return "fail";
      } else {
        setLoadingCreateAud(false);
        //showModal(<ErrorMessageDialog title={response.message} />);
        setErrorSnackbar(response?.message || "Some Error Occurred");
        return "error";
      }
    } catch (error) {
      setLoadingCreateAud(false);
    }
  };

  const transformFilters = () => {
    return {
      demo: transformedCreateAudData(demographicsselectedFilters), // correct
      geog: {
        resident_location_region_en_cat: {
          filters: convertArrayToObjects(selectedRegions),
        },
        resident_location_province_en_cat: {
          filters: convertArrayToObjects(selectedProvinces), // corrected
        },
        resident_location_district_en_cat: {
          filters: convertDistricts(selectedDistricts), // corrected
        },
      },
      segment: {
        operation: conjunctionType,
        interests: custInterestselectedFilters,
      }, // corrected
      comm_relevance: transformedCreateAudData(commRelevantselectedFilters), // correct
      locationTraffic: {
        //period: Number(selectedPeriodLoc),
        filters: locationTrafficData, // corrected
      },
      websiteTraffic: {
        operation: conjunctionTypeWeb,
        //period: Number(selectedPeriod),
        filters: websiteTrafficData, // corrected
      },
      audienceEnhancement: audienceEnhancement,
    };
  };

  const tabStyles = {
    width: "33.33%", // Set the width for each tab
  };

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  const navigateToAudienceList = () => {
    handleClearAll();
    navigate("/Audience");
  };
  const showNextTab = () => {
    // Logic to switch to the next tab
  };
  const navigateToAudienceSummary = async () => {
    await handleCalculateTotalCategories();
    if(audTotalCategories == 0) {
      showModal(
        <ErrorMessageDialog
          title={''}
          description={'No filter is selected, please select filter'}
        />
      );

      return;
    }

    const status = await handleTotalCount();
    if (status === "success" && audTotalCategories > 0) {
      setAudienceSummaryData((prevData) => ({
        ...prevData,
        demographicsselectedFilters: demographicsselectedFilters,
        selectedRegions: selectedRegions,
        selectedDistricts: selectedDistricts,
        selectedProvinces: selectedProvinces,
        conjunctionType: conjunctionType,
        conjunctionTypeWeb: conjunctionTypeWeb,
        custInterestselectedFilters: custInterestselectedFilters,
        commRelevantselectedFilters: commRelevantselectedFilters,
        locationTrafficData: locationTrafficData,
        selectedPeriod: selectedPeriod,
        selectedPeriodLoc: selectedPeriodLoc,
        websiteTrafficData: websiteTrafficData,
        positions: positions,
        isAudienceCustom: false,
      }));
      // setAudienceSummaryData({
      //   demographicsselectedFilters: demographicsselectedFilters,
      //   selectedRegions: selectedRegions,
      //   selectedDistricts: selectedDistricts,
      //   selectedProvinces: selectedProvinces,
      //   conjunctionType: conjunctionType,
      //   conjunctionTypeWeb: conjunctionTypeWeb,
      //   custInterestselectedFilters: custInterestselectedFilters,
      //   commRelevantselectedFilters: commRelevantselectedFilters,
      //   locationTrafficData: locationTrafficData,
      //   selectedPeriod: selectedPeriod,
      //   selectedPeriodLoc: selectedPeriodLoc,
      //   websiteTrafficData: websiteTrafficData,
      //   positions: positions,
      //   totalCount: audTotalCount,
      //   isAudienceCustom: false
      // });
      navigate("/AudienceSummary/" + "Create");
    }
  };



  const handleCalculateTotalCategories = () => {
    const demoCount = Object.keys(demographicsselectedFilters)
      .map((filterType) => demographicsselectedFilters[filterType]?.length || 0)
      .reduce((acc, count) => acc + count, 0);

    const commRelCount = Object.keys(commRelevantselectedFilters)
      .map((filterType) => commRelevantselectedFilters[filterType]?.length || 0)
      .reduce((acc, count) => acc + count, 0);

    const geogCount =
      selectedRegions.length +
      selectedProvinces.length +
      selectedDistricts.filter((item) => item.district).length;

    const custInterestCount = countFilters(custInterestselectedFilters);

    setAudTotalCategories(
      demoCount +
        commRelCount +
        geogCount +
        custInterestCount +
        locationTrafficData.length +
        websiteTrafficData.length
    );
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const handleClearAll = () => {
    setDemographicsselectedFilters({});
    setCustInterestselectedFilters({});
    setCommRelevantselectedFilters({});
    setSelectedRegions([]);
    setSelectedDistricts([]);
    setSelectedProvinces([]);
    setLocationTrafficData([]);
    setWebsiteTrafficData([]);
    setPositions([]);
    setAudTotalCount(0);
    setAudienceEnhancement({});
  };

  const handleAddCondition = () => {
    showModal(<AddConditionDialog title={"Title"} description={"Desc"} />);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Grid container>
      {loadingCreateAud && <GlobalLoading />}
      <Grid item xs={12}>
        <Navbar></Navbar>
      </Grid>
      <Grid item xs={1}>
        <Sidebar></Sidebar>
      </Grid>
      <Grid item xs={11} mb={30}>
        <>
          <div className="content-wrapper">
            <h2>Create Audience</h2>
            <div className="sub-title">Build your Audience</div>

            <Box component="form" noValidate autoComplete="off">
              <div className="back-text" onClick={navigateToAudienceList}>
                {/* <ArrowBackIcon></ArrowBackIcon>
                <span>{"Back to Audience List".toLocaleUpperCase()}</span>{" "} */}
                <Button variant="contained" onClick={navigateToAudienceList}>
                  <b>Back to Audience List</b>
                </Button>
              </div>
              <div className="description-text">
                {mainTabValue === "loc-traffic" &&
                  "Select a location from the map, or manually enter the latitude and longitude"}
                {mainTabValue === "web-traffic" &&
                  "Enter the domain URL that your audience visits to target for your campaign"}
              </div>
              <Tabs
                value={mainTabValue}
                onChange={handleMainTabChange}
                className="tab-sec"
              >
                <Tab
                  label={
                    <LabelWithTooltip
                      label="Audience"
                      tooltip="Select target audiences based on specific Telco characteristics or behavior"
                      size="16px"
                    />
                  }
                  value={"aud"}
                  style={tabStyles}
                />
                <Tab
                  label={
                    <LabelWithTooltip
                      label="Location Traffic"
                      tooltip="Precisely target audiences in specific geographical areas by selecting a point of interest (POI) and drawing a geofence with your preferred coverage radius"
                      size="16px"
                    />
                  }
                  value={"loc-traffic"}
                  style={tabStyles}
                />
                <Tab
                  label={
                    <LabelWithTooltip
                      label="Domain Traffic"
                      tooltip="Precisely target the audiences who visit specific websites domain name (Users-defined)"
                      size="16px"
                    />
                  }
                  value={"web-traffic"}
                  style={tabStyles}
                />
              </Tabs>

              <TabPanel value={mainTabValue} index={"aud"}>
                <TabAudience
                  demographicsData={demographicsData}
                  loaderDemoData={loaderDemoData}
                  custInterestData={custInterestData}
                  loaderCustInterestData={loaderCustInterestData}
                  locationObj={locationObj}
                  loading={loading}
                  commRelevantData={commRelevantData}
                  loaderCommRelData={loaderCommRelData}
                  setDemographicsselectedFilters={
                    setDemographicsselectedFilters
                  }
                  demographicsselectedFilters={demographicsselectedFilters}
                  setCustInterestselectedFilters={
                    setCustInterestselectedFilters
                  }
                  custInterestselectedFilters={custInterestselectedFilters}
                  setSelectedRegions={setSelectedRegions}
                  selectedRegions={selectedRegions}
                  setSelectedProvinces={setSelectedProvinces}
                  selectedProvinces={selectedProvinces}
                  setSelectedDistricts={setSelectedDistricts}
                  selectedDistricts={selectedDistricts}
                  setProvinceDistrictCount={setProvinceDistrictCount}
                  provinceDistrictCount={provinceDistrictCount}
                  setRegionProvinceCount={setRegionProvinceCount}
                  regionProvinceCount={regionProvinceCount}
                  setCommRelevantselectedFilters={
                    setCommRelevantselectedFilters
                  }
                  commRelevantselectedFilters={commRelevantselectedFilters}
                  setLocationTrafficData={setLocationTrafficData}
                  locationTrafficData={locationTrafficData}
                  setWebsiteTrafficData={setWebsiteTrafficData}
                  websiteTrafficData={websiteTrafficData}
                  showNextTab={showNextTab}
                  conjunctionType={conjunctionType}
                  setConjunctionType={setConjunctionType}
                  navigateToAudienceSummary={navigateToAudienceSummary}
                  handleTotalCount={handleTotalCount}
                  audTotalCount={audTotalCount}
                  handleClearAll={handleClearAll}
                  audTotalCategories={audTotalCategories}
                  handleCalculateTotalCategories={
                    handleCalculateTotalCategories
                  }
                  handleAddCondition={handleAddCondition}
                />
              </TabPanel>

              <TabPanel
                value={mainTabValue}
                index={"loc-traffic"}
                className="aud-type"
              >
                <TabLocationTraffic
                  handleNextTab={showNextTab}
                  setLocationTrafficData={setLocationTrafficData}
                  locationTrafficData={locationTrafficData}
                  setWebsiteTrafficData={setWebsiteTrafficData}
                  websiteTrafficData={websiteTrafficData}
                  navigateToAudienceSummary={navigateToAudienceSummary}
                  handleTotalCount={handleTotalCount}
                  audTotalCount={audTotalCount}
                  positions={positions}
                  setPositions={setPositions}
                  handleClearAll={handleClearAll}
                  selectedPeriodLoc={selectedPeriodLoc}
                  setSelectedPeriodLoc={setSelectedPeriodLoc}
                  audTotalCategories={audTotalCategories}
                  handleCalculateTotalCategories={
                    handleCalculateTotalCategories
                  }
                  handleAddCondition={handleAddCondition}
                />
              </TabPanel>

              <TabPanel
                value={mainTabValue}
                index={"web-traffic"}
                className="aud-type"
              >
                <TabWebsiteTraffic
                  handleNextTab={showNextTab}
                  setLocationTrafficData={setLocationTrafficData}
                  locationTrafficData={locationTrafficData}
                  setWebsiteTrafficData={setWebsiteTrafficData}
                  websiteTrafficData={websiteTrafficData}
                  selectedPeriod={selectedPeriod}
                  setSelectedPeriod={setSelectedPeriod}
                  navigateToAudienceSummary={navigateToAudienceSummary}
                  handleTotalCount={handleTotalCount}
                  audTotalCount={audTotalCount}
                  handleClearAll={handleClearAll}
                  audTotalCategories={audTotalCategories}
                  conjunctionTypeWeb={conjunctionTypeWeb}
                  setConjunctionTypeWeb={setConjunctionTypeWeb}
                  handleCalculateTotalCategories={
                    handleCalculateTotalCategories
                  }
                  handleAddCondition={handleAddCondition}
                />
              </TabPanel>
            </Box>
          </div>

          <Snackbar
            open={errorSnackbar ? true : false}
            autoHideDuration={6000}
            onClose={handleCloseSanckBar}
            message={errorSnackbar}
            action={action}
            sx={{ zIndex: "5000" }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          />
        </>
      </Grid>
    </Grid>
  );
};

export default CreateAudience;
