import React, { useState, createContext, useContext } from "react";

const initalState = {
  setAudienceSummaryData: (modal) => {
    return;
  },
  audienceSummaryData: {
    // demographicsselectedFilters: {},
    // selectedRegions: {},
    // selectedDistricts: [],
    // selectedProvinces: [],
    // conjunctionType: 'AND',
    // custInterestselectedFilters: {},
    // commRelevantselectedFilters: {},
    // locationTrafficData: [],
    // selectedPeriod: '1',
    // websiteTrafficData: [],
  },
  setAudienceEnhancement: () => {
    return;
  },
  audienceEnhancement: {},
  clearEnhancement: () => {}
};

const AudienceContext = createContext(initalState);

export const AudienceProvider = ({ children }) => {
  const [audienceSummaryData, setAudienceSummaryData] = useState({});
  const [audienceEnhancement, setAudienceEnhancement] = useState({});

  const clearEnhancement = () => {
    setAudienceEnhancement({});
  }
  return (
    <AudienceContext.Provider
      value={{
        audienceSummaryData,
        setAudienceSummaryData,
        audienceEnhancement,
        setAudienceEnhancement,
        clearEnhancement
      }}
    >
      {/* {renderComponent()} */}
      {children}
    </AudienceContext.Provider>
  );
};

export const useAudienceContext = () => useContext(AudienceContext);
