import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import APIS from "./APIS";
import { useNavigate } from "react-router-dom";
import GlobalLoading from "./GlobalLoading";

const EmailVerify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isInitialMount = useRef(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      const fetchData = async () => {
        setLoading(true);
        try {
          const queryParams = new URLSearchParams(location.search);
          const otp = queryParams.get("otp");
          //const email = queryParams.get("email");
          const token = queryParams.get("token");
          const refCode = queryParams.get("refCode");

          const data = {
            otp,
            refCode,
            //email,
            token
          };

          const response = await APIS.ConfirmEmailResponse(data);
          let displaydata;

          if (response.code == 200 || response.code == 201) {
            displaydata = {
              text1: "WAITING FOR APPROVAL",
              text2:
                "Your account is currently pending approval. We'll notify you via email once your account has been approved. Thank you for your patience.",
              text4: "",
            };
          } else {
            displaydata = {
              text1: response.message,
              text2: "",
              text3: "",
              text4: "",
            };
          }
          setLoading(false);
          navigate("/PageInfoModalBox", { state: displaydata });
        } catch (error) {
          setLoading(false);
          alert(error);
        }
      };

      fetchData();
    }
  }, [location.pathname]); // You can keep the dependency array if you want to trigger on location changes after the initial mount.

  return (
    <div>
     {loading && <GlobalLoading />}
    </div>
  );
};

export default EmailVerify;
