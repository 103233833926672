import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import AudienceFooter from "./AudienceFooter";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import APIS from "./APIS";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { Snackbar, Tooltip } from "@mui/material";
import Close from "@mui/icons-material/Close";
import CustomInputField from "../Components/ui/CustomInputField";
import {
  doesNotStartWithRegex,
  incompleteUrlRegex,
  validStringRegex,
} from "../helper/constant";
import ButtonGroup from "@mui/material/ButtonGroup";
import LabelWithTooltip from "../Components/LabelWithTooltip";
import { HelpOutlineOutlined } from "@mui/icons-material";

const TabWebsiteTraffic = ({
  websiteTrafficData,
  setWebsiteTrafficData,
  selectedPeriod,
  setSelectedPeriod,
  navigateToAudienceSummary,
  handleTotalCount,
  audTotalCount,
  handleClearAll,
  audTotalCategories,
  handleCalculateTotalCategories,
  conjunctionTypeWeb,
  setConjunctionTypeWeb,
  handleAddCondition,
}) => {
  const [webUrl, setWebUrl] = useState("");
  const [matchCriteria, setMatchCriteria] = useState("");
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const [webTrafficError, setWebTrafficError] = useState({
    webUrl: "",
    matchCriteria: "",
  });

  useEffect(() => {
    calculateCount();
  }, [websiteTrafficData]);

  const setConjunction = (type) => {
    setConjunctionTypeWeb(type);
  };

  const calculateCount = () => {
    //setTotalSelectionCount(websiteTrafficData.length);
    handleCalculateTotalCategories();
  };

  const handleURLChange = (event) => {
    setWebUrl(event.target.value);
    setWebTrafficError({ ...webTrafficError, webUrl: "", duplicateWebURL: "" });
  };

  const handleAddRow = (event) => {
    event.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setWebTrafficError(newErrors);
      return;
    } else {
      if (websiteTrafficData.length < 25) {
        setWebsiteTrafficData([
          ...websiteTrafficData,
          { webUrl, matchCriteria },
        ]);
        setWebUrl("");
        setMatchCriteria("");
        setWebTrafficError({ webUrl: "", matchCriteria: "" });
        //setInterestValue("high");
      } else {
        //setErrorSnackbar("Cannot add more than 25 rows");
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const isDuplicate = websiteTrafficData.some(
      (data) => data.webUrl.toLocaleLowerCase() == webUrl.toLocaleLowerCase()
    );

    if (websiteTrafficData.length >= 25) {
      newErrors.totalCountError = "Cannot add more than 25 rows.";
      return newErrors;
    }

    if (isDuplicate) {
      newErrors.duplicateWebURL = "Duplicate URL. This URL is already added.";
      return newErrors;
    }

    if (!webUrl) {
      newErrors.webUrl = "Please enter your Domain URL.";
    } else if (
      !doesNotStartWithRegex.test(webUrl) &&
      matchCriteria.toLocaleLowerCase() == "exact"
    ) {
      newErrors.webUrl = "Domain URL format is incorrect. Please enter correct format e.g. google.com and all of them must be alphabet only";
    }else if (
      !doesNotStartWithRegex.test(webUrl) &&
      matchCriteria.toLocaleLowerCase() == "contains"
    ) {
      newErrors.webUrl = "Enter only alphabetic characters, without including 'http://', 'https', or 'www'.";
    }
     else if (
      (!incompleteUrlRegex.test(webUrl) &&
        matchCriteria.toLocaleLowerCase() == "exact") ||
      (!validStringRegex.test(webUrl) &&
        matchCriteria.toLocaleLowerCase() == "exact")
    ) {
      newErrors.webUrl =
        "Domain URL format is incorrect. Please enter correct format e.g. google.com and all of them must be alphabet only.";
    } else if (
      !validStringRegex.test(webUrl) &&
      matchCriteria.toLocaleLowerCase() == "contains"
    ) {
      newErrors.webUrl =
        "Domain URL format is incorrect. Please enter correct format. Must be alphabet only.";
    }
    if (!matchCriteria) {
      newErrors.matchCriteria = "Please select Match Criteria.";
    }

    return newErrors;
  };

  const handleDelete = (index) => {
    const updatedData = [...websiteTrafficData];
    updatedData.splice(index, 1);
    setWebsiteTrafficData(updatedData);
    setWebTrafficError({ ...webTrafficError, totalCountError: "" });
  };

  const handleQueryChange = (event) => {
    setMatchCriteria(event.target.value);
    setWebTrafficError({ ...webTrafficError, matchCriteria: "" });
  };

  const handleInterestValueChange = (event, index) => {
    const newValue = event.target.value;

    const newFilters = [...websiteTrafficData];
    newFilters[index].interestValue = newValue;

    setWebsiteTrafficData(newFilters);
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleClear = () => {
    setWebUrl("");
    setMatchCriteria("");
    setSelectedPeriod("1");
    handleClearAll();
  };

  return (
    <>
      <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "5000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <Grid container spacing={2} className="content-container">
        <Grid item xs={3}>
          <div className="content-card">
            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: 12, marginRight: 10 }}>
                <b>Period:</b>
              </label>
              <select
                className="website-select-period"
                value={selectedPeriod}
                onChange={handlePeriodChange}
              >
                <option value="1">1 month</option>
                <option value="2">2 month</option>
                <option value="3">3 month</option>
                <option value="4">4 month</option>
                <option value="5">5 month</option>
                <option value="6">6 month</option>
              </select>
            </div>
            <br></br> */}

            <div>
              <div>
                <CustomInputField
                  label={
                    <>
                      <span>Domain URL</span>
                      <Tooltip title="Please enter domain only (e.g., www.google.com or google.com) ">
                        <HelpOutlineOutlined
                          sx={{ fontSize: "12px" }}
                          style={{ marginLeft: 4 }}
                        />
                      </Tooltip>
                    </>
                  }
                  id="webURL"
                  value={webUrl}
                  type="text"
                  required
                  onChange={handleURLChange}
                  onKeyDown={handleKeyDown}
                  error={
                    !!webTrafficError.webUrl ||
                    !!webTrafficError.duplicateWebURL
                  }
                  size="small"
                  placeholder="Enter URL and click ‘ADD’ to add link"
                  helperText={
                    webTrafficError.webUrl || webTrafficError.duplicateWebURL
                  }
                />
              </div>
            </div>
            <div>
              <RadioGroup
                aria-label="options"
                name="query"
                value={matchCriteria}
                onChange={handleQueryChange}
              >
                <FormControlLabel
                  value="exact"
                  control={<Radio />}
                  label={
                    <LabelWithTooltip
                      label="EXACT"
                      tooltip="Use exact matching logic for the specified website domains"
                      size="12px"
                    />
                  }
                />
                <FormControlLabel
                  value="contains"
                  control={<Radio />}
                  label={
                    <LabelWithTooltip
                      label="CONTAINS"
                      tooltip="Use contain matching for the specified website domains"
                      size="12px"
                    />
                  }
                />
              </RadioGroup>
              <div
                style={{ fontSize: "12px", color: "#D32F2F", padding: "12px" }}
              >
                {webTrafficError.matchCriteria}
              </div>
              {webTrafficError.totalCountError && (
                <div
                  style={{
                    fontSize: "12px",
                    color: "#D32F2F",
                    padding: "12px",
                  }}
                >
                  {webTrafficError.totalCountError}
                </div>
              )}
              {/* {webTrafficError.duplicateWebURL && (
                <div
                  style={{
                    fontSize: "12px",
                    color: "#D32F2F",
                    padding: "12px",
                  }}
                >
                  {webTrafficError.duplicateWebURL}
                </div>
              )} */}
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                size="small"
                onClick={(event) => handleAddRow(event)}
              >
                ADD
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={9}>
          <div className="content-card3">
            <div className="web-traffic-and-or">
              <ButtonGroup variant="outlined" aria-label="Basic button group">
                <Button
                  onClick={() => setConjunction("AND")}
                  variant={
                    conjunctionTypeWeb == "AND" ? "contained" : "outlined"
                  }
                  sx={{
                    border: "1px solid #737373",
                    color: conjunctionTypeWeb == "AND" ? "#FFFFFF" : "#878787",
                  }}
                >
                  AND
                </Button>
                <Button
                  onClick={() => setConjunction("OR")}
                  variant={
                    conjunctionTypeWeb == "OR" ? "contained" : "outlined"
                  }
                  sx={{
                    border: "1px solid #737373",
                    color: conjunctionTypeWeb == "OR" ? "#FFFFFF" : "#878787",
                  }}
                >
                  OR
                </Button>
              </ButtonGroup>
            </div>
            <div className="sec-title loc-traffic-title">
              Domain Traffic ({websiteTrafficData.length})
            </div>
            <List>
              {websiteTrafficData.map((rowData, index) => (
                <ListItem
                  key={index}
                  className="loc-traffic-list"
                  sx={{
                    borderRadius: "8px",
                    marginBottom: "8px",
                    padding: "0px 8px",
                  }}
                >
                  <div className="web-traffic-list">
                    <div className="web-traffic-list-item">
                      <Typography component="span" sx={{ fontSize: "14px" }}>
                        {rowData.webUrl}
                      </Typography>{" "}
                    </div>
                    <div className="web-traffic-match-criteria">
                      <div>
                        <Typography
                          component="span"
                          sx={{ fontSize: "14px", margin: "none" }}
                        >
                          Match criteria:{" "}
                          <b>{rowData.matchCriteria.toUpperCase()}</b>
                        </Typography>
                      </div>
                      <div>
                        <IconButton
                          edge="end"
                          onClick={() => handleDelete(index)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                  {/* <div className="web-traffic-list-interest">
                    {" "}
                    <Typography
                      component="span"
                      sx={{ fontSize: "14px", margin: "none" }}
                    >
                      <label>Interest value: </label>
                      <select
                        name="interestValue"
                        value={rowData.interestValue}
                        className="website-select-period"
                        onChange={(event) =>
                          handleInterestValueChange(event, index)
                        }
                      >
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                        <option value="all">All</option>
                      </select>
                    </Typography>
                  </div> */}
                </ListItem>
              ))}
            </List>
          </div>
          <AudienceFooter
            onNextClick={navigateToAudienceSummary}
            count={audTotalCount}
            onCalculate={handleTotalCount}
            totalSlectionCount={audTotalCategories}
            onChildClick={handleClear}
            handleAddCondition={handleAddCondition}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TabWebsiteTraffic;
