import { Grid } from "@mui/material";

const Footer = ({ id }) => {
  return (
    <div id={id}>
      <Grid textAlign={"center"} my={3} fontSize={14}>
        Copyright © 2024 True Digital Group Company Limited. All rights reserved
      </Grid>
    </div>
  );
};

export default Footer;
