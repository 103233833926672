import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem("cookieConsent");
    if (!consentGiven) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", true);
    setShowConsent(false);
  };

  if (!showConsent) {
    return null;
  }

  return (
    <div style={consentStyle}>
      <h4>This website uses cookies.</h4>
      <p>
        To offer you a better experience on our site, we rely on cookies stored
        on your device for analyzing site usage. Cookies enable you to enjoy
        certain features like finding deals and content relevant to your
        interest.
      </p>
      <Grid container justifyContent={"center"} gap={2}>
        <Grid>
          <Button fullWidth variant="contained" onClick={handleAccept}>
            Accept and Close
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const consentStyle = {
  position: "fixed",
  bottom: "0",
  left: "0",
  right: "0",
  backgroundColor: "gray",
  color: "white",
  padding: "20px",
  textAlign: "center",
  zIndex: "1000",
};

export default CookieConsent;
