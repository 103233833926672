import {
  Grid,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import Navbar from "../Components/navbar";
import Sidebar from "../Components/sidebar";
import { CustomInputField } from "../Components/ui";
import { useEffect, useState, useRef } from "react";
import { useAuthContext } from "../Components/AuthContextProvider";
import APIS from "./APIS";
import GlobalLoading from "./GlobalLoading";
import { mobileRegx, nameRegEx } from "../helper/constant";

const UserProfile = () => {
  const [disabledForm, setDisabledForm] = useState(true);
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const { token } = useAuthContext();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      getUserProfile();
    }
  }, []);

  const getUserProfile = async () => {
    setLoading(true);
    try {
      const response = await APIS.getUserProfile(token);
      if (response.code == 200) {
        setFormData(response.data);
      } else {
        setFormError({
          ...formError,
          apiError: response.meesage ?? "Featch User Profile API failed!",
        });
      }
      setLoading(false);
    } catch (error) {}
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (disabledForm) {
      setDisabledForm(false);
      return;
    }
    const errors = validateForm();
    if (Object.keys(errors).length != 0) {
      setFormError(errors);
      return;
    }
    setLoading(true);
    try {
      const response = await APIS.updateUserProfile(formData, token);
      if (response.code == 200) {
        setFormError({ apiError: "Successfully Updated!" });
        setLoading(false);
        setDisabledForm(true);
        getUserProfile();
      } else {
        setFormError({ apiError: response.meesage ?? "Updated API failed!" });
        setLoading(false);
      }
    } catch (error) {}
    setLoading(false);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!nameRegEx.test(formData.firstName)) {
      newErrors.firstName = "Name and Last name can only contain letters, spaces, hyphens, and apostrophes.";
    }

    if (!nameRegEx.test(formData.lastName)) {
      newErrors.lastName = "Name and Last name can only contain letters, spaces, hyphens, and apostrophes.";
    }

    if (formData?.firstName?.length > 50) {
      newErrors.firstName = "The input you entered is too long. Max allowed: 50 characters.";
    }

    if (formData?.lastName?.length > 50) {
      newErrors.lastName = "The input you entered is too long. Max allowed: 50 characters.";
    }

    if (!formData.firstName) {
      newErrors.firstName = "First name is required";
    }

    if (!formData.lastName) {
      newErrors.lastName = "Surname is required";
    }

    if (!formData.position) {
      newErrors.position = "Postion is required";
    }

    if (!formData.contact) {
      newErrors.contact = "Phone number is required.";
    } else if (formData.contact && !mobileRegx.test(formData.contact)) {
      newErrors.contact = "Phone number is invalid.";
    }

    return newErrors;
  };

  const handleCancel = () => {
    setDisabledForm(true);
    getUserProfile();
    setFormError({});
  };
  return (
    <div className="inner-wrapper">
      {loading && <GlobalLoading />}
      <Grid container>
        <Grid item xs={12}>
          <div className="inner-wrapper">
            <Grid container>
              <Grid item xs={12}>
                <Navbar />
              </Grid>
              <Grid item xs={1}>
                <Sidebar />
              </Grid>
              <Grid item xs={11}>
                <form onSubmit={handleSubmit} noValidate>
                  <div className="content-wrapper">
                    <Grid
                      container
                      mt={2}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <h2>User Profile</h2>

                        <div className="sub-title">To Manage your account preferences and details, including personal information updates</div>
                      </Grid>

                      <Grid item columnGap={2} display={"flex"}>
                        <Button onClick={handleCancel} disabled={disabledForm}>
                          CANCEL
                        </Button>

                        <Button
                          variant="contained"
                          type="submit"
                          disabled={loading}
                        >
                          {disabledForm ? " EDIT INFORMATION" : "SAVE CHANGES"}
                        </Button>
                      </Grid>
                    </Grid>

                    <form action="">
                      <Grid container spacing={2} sx={12} mt={1}>
                        <Grid item container xs={12} spacing={2}>
                          <Grid item xs={6}>
                            <CustomInputField
                              value={formData.firstName || ""}
                              required
                              disabled={disabledForm}
                              label="Name"
                              id="firstName"
                              name="firstName"
                              onChange={handleChange}
                              size="small"
                              placeholder="Name"
                              error={!!formError.firstName}
                              helperText={formError.firstName}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomInputField
                              value={formData.lastName || ""}
                              required
                              disabled={disabledForm}
                              label="Surname"
                              name="lastName"
                              id="lastName"
                              onChange={handleChange}
                              size="small"
                              placeholder="Surname"
                              error={!!formError.lastName}
                              helperText={formError.lastName}
                            />
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                          <Grid item xs={6}>
                            <CustomInputField
                              value={formData.contact || ""}
                              disabled={disabledForm}
                              required
                              label="Phone Number"
                              name="contact"
                              id="contact"
                              onChange={handleChange}
                              error={!!formError.contact}
                              helperText={formError.contact}
                              size="small"
                              placeholder="Phone Number"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomInputField
                              value={formData.email || ""}
                              disabled
                              label="Email"
                              id="email"
                              type="email"
                              size="small"
                              placeholder="Email"
                            />
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                          <Grid item xs={6}>
                            <CustomInputField
                              value={formData.position || ""}
                              disabled={disabledForm}
                              required
                              label="Position"
                              name="position"
                              id="position"
                              onChange={handleChange}
                              size="small"
                              placeholder="Postition"
                              error={!!formError.position}
                              helperText={formError.position}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomInputField
                              value={formData.companyName || ""}
                              disabled
                              label="Company Name"
                              name="companyName"
                              id="companyName"
                              onChange={handleChange}
                              size="small"
                              placeholder="Company Name"
                            />
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                          <Grid item xs={6}>
                            <CustomInputField
                              value={formData.industry || ""}
                              disabled={disabledForm}
                              label="Industry"
                              name="industry"
                              id="industry"
                              onChange={handleChange}
                              size="small"
                              placeholder="Industry"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomInputField
                              value={formData.employeeSize || ""}
                              type="number"
                              disabled={disabledForm}
                              label="Employee Size"
                              name="employeeSize"
                              id="employeeSize"
                              onChange={handleChange}
                              size="small"
                              placeholder="Employee Size"
                            />
                          </Grid>
                        </Grid>

                        <Typography p={2} mt={3} color={"gray"}>
                          Billing Address
                        </Typography>
                        <Grid item container xs={12} spacing={2}>
                          <Grid item xs={6}>
                            <CustomInputField
                              value={formData.addressLine1 || ""}
                              disabled={disabledForm}
                              label="Address Line 1"
                              name="addressLine1"
                              id="addressLine1"
                              onChange={handleChange}
                              size="small"
                              placeholder="Address Line 1"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomInputField
                              value={formData.addressLine2 || ""}
                              disabled={disabledForm}
                              label="Address Line 2"
                              name="addressLine2"
                              id="addressLine2"
                              onChange={handleChange}
                              size="small"
                              placeholder="Address Line 2"
                            />
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                          <Grid item xs={6}>
                            <CustomInputField
                              value={formData.city || ""}
                              disabled={disabledForm}
                              label="City"
                              name="city"
                              id="city"
                              onChange={handleChange}
                              size="small"
                              placeholder="City"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomInputField
                              value={formData.state || ""}
                              disabled={disabledForm}
                              label="State"
                              name="state"
                              id="state"
                              onChange={handleChange}
                              size="small"
                              placeholder="State"
                            />
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                          <Grid item xs={6}>
                            <CustomInputField
                              value={formData.postalCode || ""}
                              disabled={disabledForm}
                              label="Postal Code"
                              name="postalCode"
                              id="postal_code"
                              type="number"
                              onChange={handleChange}
                              size="small"
                              placeholder="Postal Code"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomInputField
                              value={formData.country || ""}
                              disabled={disabledForm}
                              label="Country"
                              name="country"
                              id="country"
                              onChange={handleChange}
                              size="small"
                              placeholder="Country"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </form>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Snackbar
          open={Boolean(formError.apiError)}
          autoHideDuration={6000}
          onClose={() => setFormError({ ...formError, apiError: "" })}
          message={formError.apiError}
        />
      </Grid>
    </div>
  );
};

export default UserProfile;
