import {
  Grid,
  Typography,
  Dialog,
  Button,
  CircularProgress,
} from "@mui/material";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";
import APIS from "../../Pages/APIS";
import { useAuthContext } from "../AuthContextProvider";
import { useState } from "react";

const DeactivateUserDialog = ({ email, onSuccess }) => {
  const { hideModal } = useGlobalModalContext();
  const { token } = useAuthContext();
  const [loading, setIsLoading] = useState(false);

  const handleOnclick = async () => {
    try {
      setIsLoading(true);
      const response = await APIS.deactivateUser({ email }, token);
      if (response.code == 200) {
        onSuccess();
        hideModal();
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error, "Dactivate User Error");
    }
  };
  return (
    <Dialog open onClose={hideModal} maxWidth="xs">
      <Grid p={3} display={"grid"} justifyContent={"center"} a>
        <Typography
          variant="h5"
          display={"grid"}
          justifyContent={"center"}
          fontWeight={"bold"}
        >
          Deactivate this user?
        </Typography>
        <Typography display={"grid"} variant="body1" my={2} color={"gray"}>
          Are you sure you want to deactivate this user? Deactivating a user
          will restrict their access to the platform.
        </Typography>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <Button fullWidth onClick={hideModal} variant="contained">
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              color="error"
              disabled={loading}
              onClick={handleOnclick}
            >
              {loading ? <CircularProgress /> : "DEACTIVATE"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeactivateUserDialog;
