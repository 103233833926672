import { useState, useEffect } from "react";
import { Dialog, Grid, Typography, Button } from "@mui/material";
import { useGlobalModalContext } from "./GlobalModalContext/GlobalModalContext";
import { Add, Delete } from "@mui/icons-material";
import { CustomSelect } from "./ui";
import { useAudienceContext } from "./AudienceContext/AudienceContext";
import APIS from "../Pages/APIS";
import { useAuthContext } from "./AuthContextProvider";

const AddConditionDialog = ({ title, description }) => {
  const { hideModal } = useGlobalModalContext();
  const [loading, setLoading] = useState(false);
  const [inclusionRows, setInclusionRows] = useState([
    { number: 1, operation: "AND", audienceId: "", audienceName: "" },
  ]);
  const [exclusionRows, setExclusionRows] = useState([
    { number: 1, audienceId: "", audienceName: "" },
  ]);
  const [audienceMenu, setAudienceMenu] = useState([]);
  const [options, setOptions] = useState([]);
  const { audienceEnhancement, setAudienceEnhancement } = useAudienceContext();
  const { token } = useAuthContext();

  useEffect(() => {
    fetchAudiences();
  }, []);

  useEffect(() => {
    if (audienceEnhancement) {
      // Auto-populate inclusionRows if audienceEnhancement.include exists
      if (
        audienceEnhancement.include &&
        audienceEnhancement.include.length > 0
      ) {
        setInclusionRows(
          audienceEnhancement.include.map((row, index) => ({
            number: index + 1,
            operation: row.operation || "AND",
            audienceId: row.audienceId || "",
            audienceName: row.audienceName || "",
          }))
        );
      }

      // Auto-populate exclusionRows if audienceEnhancement.exclude exists
      if (
        audienceEnhancement.exclude &&
        audienceEnhancement.exclude.length > 0
      ) {
        setExclusionRows(
          audienceEnhancement.exclude.map((row, index) => ({
            number: index + 1,
            audienceId: row.audienceId || "",
            audienceName: row.audienceName || "",
          }))
        );
      }
    }
  }, [audienceEnhancement]);

  const fetchAudiences = async () => {
    try {
      setLoading(true);
      const response = await APIS.getAudienceName(token);
      const data =
        response?.data.map((element) => ({
          value: element.audienceId,
          label: element.audienceName,
        })) || [];
      setOptions(data);
      setAudienceMenu(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const operatorType = [
    { value: "AND", label: "AND" },
    { value: "OR", label: "OR" },
  ];

  // Inclusion Row Functions
  const addInclusionCondition = () => {
    if (inclusionRows.length < 5) {
      setInclusionRows([
        ...inclusionRows,
        {
          number: inclusionRows.length + 1,
          operation: "AND",
          audienceId: "",
          audienceName: "",
        },
      ]);
    }
  };

  const deleteInclusionCondition = (index) => {
    if (inclusionRows.length > 1) {
      const updatedRows = inclusionRows.filter((_, i) => i !== index);
      // Update the 'number' field for all rows
      setInclusionRows(
        updatedRows.map((row, i) => ({ ...row, number: i + 1 }))
      );
    } else {
      setInclusionRows([
        { number: 1, operation: "AND", audienceId: "", audienceName: "" },
      ]);
    }
  };

  const handleOptionChangeInclude = (index, field, value) => {
    const selectedAudience = audienceMenu.find(
      (audience) => audience.value === value
    );
    const updatedRows = inclusionRows.map((row, i) =>
      i === index
        ? {
            ...row,
            [field]: value,
            audienceName: selectedAudience ? selectedAudience.label : "",
          }
        : row
    );
    setInclusionRows(updatedRows);
  };

  const getDisabledOptionsInclusion = () => {
    return [
      ...inclusionRows
        .map((row) => row.audienceId)
        .filter((audience) => audience !== ""),
      ...exclusionRows
        .map((row) => row.audienceId)
        .filter((audience) => audience !== ""),
    ];
  };

  // Exclusion Row Functions
  const addExclusionCondition = () => {
    if (exclusionRows.length < 5) {
      setExclusionRows([
        ...exclusionRows,
        { number: exclusionRows.length + 1, audienceId: "", audienceName: "" },
      ]);
    }
  };

  const deleteExclusionCondition = (index) => {
    if (exclusionRows.length > 1) {
      const updatedRows = exclusionRows.filter((_, i) => i !== index);
      // Update the 'number' field for all rows
      setExclusionRows(
        updatedRows.map((row, i) => ({ ...row, number: i + 1 }))
      );
    } else {
      setExclusionRows([{ number: 1, audienceId: "", audienceName: "" }]);
    }
  };

  const handleOptionChangeExclusion = (index, value) => {
    const selectedAudience = audienceMenu.find(
      (audience) => audience.value === value
    );
    const updatedRows = exclusionRows.map((row, i) =>
      i === index
        ? {
            ...row,
            audienceId: value,
            audienceName: selectedAudience ? selectedAudience.label : "",
          }
        : row
    );

    setExclusionRows(updatedRows);
  };

  const getDisabledOptionsExclusion = () => {
    return [
      ...exclusionRows
        .map((row) => row.audienceId)
        .filter((audience) => audience !== ""),
      ...inclusionRows
        .map((row) => row.audienceId)
        .filter((audience) => audience !== ""),
    ];
  };

  const saveRows = () => {
    const validInclusion = inclusionRows.filter((row) => row.audienceId !== "") || {};
    const validExclusion = exclusionRows.filter((row) => row.audienceId !== "") || {};

    //if (validInclusion.length > 0 || validExclusion.length > 0) {
      const enhancements = {
        include: validInclusion,
        exclude: validExclusion,
      };

      //console.log("enhancements", enhancements);

      setAudienceEnhancement(enhancements);
    //}
    hideModal();
  };

  return (
    <>
      {/* {loading && <GlobalLoading />} */}

      <Dialog
        open
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modal-add-condition"
      >
        <Grid display={"grid"} rowGap={2} padding={3}>
          <Typography
            display={"grid"}
            variant="h5"
            justifyContent={"flex-start"}
            fontWeight={"bold"}
          >
            Audience Segmentation Enhancement
          </Typography>
          <Grid display={"grid"} rowGap={2}>
            {/* Inclusion Section */}
            <Grid item xs={12} className="box-shadow" padding={2}>
              <div
                className="div-flex-between"
                style={{ marginBottom: "16px" }}
              >
                <div>
                  <Typography variant="h6">
                    Define AND - OR Segment Criteria
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Select 'AND' to require all conditions or 'OR' for any
                    condition. Then, pick your target audience segments.
                  </Typography>
                </div>

                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "4px !important",
                    backgroundColor: "#EDEEF6 !important",
                    color:
                      inclusionRows.length >= 5
                        ? "rgba(0, 0, 0, 0.3) !important"
                        : "#4F5464 !important",
                    fontWeight: "700 !important",
                  }}
                  onClick={addInclusionCondition}
                  disabled={inclusionRows.length >= 5}
                >
                  <Add /> Add condition
                </Button>
              </div>
              {inclusionRows.map((row, index) => (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  key={`inclusion-${index}`}
                  style={{ marginBottom: "8px" }}
                >
                  <Grid item xs={5}>
                    <CustomSelect
                      options={operatorType}
                      placeholder="Select Operator"
                      onChange={(e) =>
                        handleOptionChangeInclude(
                          index,
                          "operation",
                          e.target.value
                        )
                      }
                      value={row.operation}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <CustomSelect
                      options={audienceMenu.map((audience) => ({
                        ...audience,
                        isDisabled: getDisabledOptionsInclusion().includes(
                          audience.value
                        ),
                      }))}
                      placeholder="Select Audience (Only Standard and Custom audience source)"
                      onChange={(e) =>
                        handleOptionChangeInclude(
                          index,
                          "audienceId",
                          e.target.value
                        )
                      }
                      value={row.audienceId}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "4px !important",
                        backgroundColor: "#EDEEF6 !important",
                        color: "#4F5464 !important",
                        fontWeight: "700 !important",
                      }}
                      onClick={() => deleteInclusionCondition(index)}
                    >
                      <Delete />
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            {/* Exclusion Section */}
            <Grid item xs={12} className="box-shadow" padding={2}>
              <div
                className="div-flex-between"
                style={{ marginBottom: "16px" }}
              >
                <div>
                  <Typography variant="h6">
                    Exclude Audience Segments
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Specify segments to exclude from your target audience by
                    selecting the segments to omit.
                  </Typography>
                </div>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "4px !important",
                    backgroundColor: "#EDEEF6 !important",
                    color:
                      exclusionRows.length >= 5
                        ? "rgba(0, 0, 0, 0.3) !important"
                        : "#4F5464 !important",
                    fontWeight: "700 !important",
                  }}
                  onClick={addExclusionCondition}
                  disabled={exclusionRows.length >= 5}
                >
                  <Add /> Add condition
                </Button>
              </div>
              {exclusionRows.map((row, index) => (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  key={`exclusion-${index}`}
                  style={{ marginBottom: "8px" }}
                >
                  <Grid item xs={11}>
                    <CustomSelect
                      options={audienceMenu.map((audience) => ({
                        ...audience,
                        isDisabled: getDisabledOptionsExclusion().includes(
                          audience.value
                        ),
                      }))}
                      placeholder="Select Audience to Exclude (Only Standard and Custom audience source)"
                      onChange={(e) =>
                        handleOptionChangeExclusion(index, e.target.value)
                      }
                      value={row.audienceId}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "4px !important",
                        backgroundColor: "#EDEEF6 !important",
                        color: "#4F5464 !important",
                        fontWeight: "700 !important",
                      }}
                      onClick={() => deleteExclusionCondition(index)}
                    >
                      <Delete />
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid display={"flex"} justifyContent={"space-between"} columnGap={2}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "4px !important",
                backgroundColor: "#EDEEF6 !important",
                color: "#4F5464 !important",
                fontWeight: "700 !important",
              }}
              onClick={hideModal}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={saveRows}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default AddConditionDialog;
