import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";

const DeactivatePackageDialog = ({ id, onSuccess, title, description }) => {
  const { hideModal, hideAllModal } = useGlobalModalContext();
  const [acccount, setAccount] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    hideModal();
    onSuccess();
  };
  return (
    <Dialog
      open
      onClose={hideModal}
      className="modal-delete"
      sx={{ padding: "32px, 24px, 24px, 24px" }}
    >
      <DialogTitle className="modal-lg-text">
        {title ? title : " Deactivate this package?"}
      </DialogTitle>
      <DialogContent className="modal-sm-text">
        {description
          ? description
          : "Are you sure you want to Deactivate this package?"}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={hideModal}
          sx={{ fontSize: "12px" }}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{ color: "#FFFFFF", backgroundColor: "#D92D20 !important" }}
        >
          Deactivate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeactivatePackageDialog;
