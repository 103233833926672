import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import APIS from "./APIS";
import AppLogo from "../Components/AppLogo";
import { ArrowBack } from "@mui/icons-material";
import { useRegisterContext } from "../Components/RegisterContext";
import { emailRegx } from "../helper/constant";
import CustomInputField from "../Components/ui/CustomInputField";

const ResendPwdResetLink = () => {
  const { forgotPasswordData, setForgotPasswordData } = useRegisterContext();

  const [resetState, setResetState] = useState({
    ...forgotPasswordData,
  });

  const [timer, setTimer] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (isTimerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setIsTimerActive(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerActive]);

  const handleClick = async () => {
    const { email } = resetState;
    const isValidEmail = emailRegx.test(email);

    if (!email || !isValidEmail) {
      setResetState({ ...resetState, emailError: true });
    } else {
      setResetState({ ...resetState, emailError: false });

      try {
        const formData = {
          email: resetState.email,
        };

        const response = await APIS.ForgotPassword(formData);
        if (response.code == 200) {
          setTimer(900); // Set timer to 15 minutes
          setIsTimerActive(true); // Activate the timer
        } else {
          console.error("Login failed:", response.message);
        }
      } catch (error) {
        console.error("Error during login:", error);
      }
    }
  };

  const handleBack = () => {
    navigate("/Login");
  };

  const handleResendLink = () => {
    handleClick();
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`;
  };

  return (
    <div className="login-container">
      <AppLogo />
      <h3>Reset password link was sent</h3>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <div className="info-sec">
            We've just sent you an email with instructions on how to reset your
            password. Please check your inbox and follow the steps provided.
          </div>
          <TextField
            label="Email"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            value={resetState.email}
            type="email"
            disabled={true}
            onChange={() => {}}
            error={resetState.emailError}
            helperText={
              resetState.emailError ? "Enter a valid email address" : ""
            }
            required
            placeholder="Enter Email"
          />
        </div>
        <div className="back-text-center">
          {!isTimerActive && (
            <span>
              Did not receive the email?
              <span
                className="c-red"
                onClick={handleResendLink}
                style={{ cursor: "pointer" }}
              >
                {" "}
                Click to resend
              </span>
            </span>
          )}
          {isTimerActive && (
            <span>
              Resend email in
              {isTimerActive && <span> {formatTime(timer)}s</span>}
            </span>
          )}
        </div>
        <div className="back-text back-text-center" onClick={handleBack}>
          <ArrowBack />
          <span>BACK TO LOG-IN</span>
        </div>
      </Box>
    </div>
  );
};

export default ResendPwdResetLink;
