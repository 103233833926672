import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import APIS from "./APIS";
import AppLogo from "../Components/AppLogo";
import { useAuthContext } from "../Components/AuthContextProvider";
import CustomInputField from "../Components/ui/CustomInputField";
import { emailRegx } from "../helper/constant";
import { Close } from "@mui/icons-material";
import GlobalLoading from "../Pages/GlobalLoading";
import CookieConsent from "../Components/CookieConsent";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formError, setFormError] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const { token, login } = useAuthContext();
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const [globalLoading, setGlobalLoading] = useState(false);

  useEffect(() => {
    if (token) {
      navigate("/MyAccount");
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setFormError(newErrors);
      return;
    } else {
      try {
        setGlobalLoading(true);

        const response = await APIS.Login(formData);
        if (response.code == 200) {
          login(response);
          setGlobalLoading(false);
        }
        //  else if (response.code == 401) {
        //   setGlobalLoading(false);
        //   setFormError({
        //     ...formError,
        //     password:
        //       "Incorrect username or password. Please try again.",
        //   });
        // } 
        // else if (response.code == 400) {
        //   setGlobalLoading(false);
        //   setFormError({
        //     ...formError,
        //     email:
        //       "Incorrect username or password. Please try again.",
        //   });
        // } else if (response.code == 422) {
        //   setGlobalLoading(false);
        //   setFormError({
        //     ...formError,
        //     password: response.message ?? "Password not set before.",
        //   });
        // }
         else {
          setGlobalLoading(false);
          setFormError({
            ...formError,
            password:
            "Incorrect username or password. Please try again.",
          });
          //setErrorSnackbar();
        }
      } catch (error) {
        setGlobalLoading(false);
        setErrorSnackbar(error || "Some Error Occurred");
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.email) {
      newErrors.email =
        "This field is required. Please enter your email address..";
    } else if (!emailRegx.test(formData.email)) {
      newErrors.email =
        "The email address format is incorrect. Please enter a valid email address.";
    }

    if (!formData.password) {
      newErrors.password =
        "This field is required. Please enter your password.";
    }

    return newErrors;
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      {globalLoading && <GlobalLoading />}
      <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "10000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <div className="login-container">
        <AppLogo />
        <h3>Log In</h3>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div>
            <CustomInputField
              label="Email"
              id="email"
              name="email"
              type="email"
              value={formData.email}
              required
              onChange={handleChange}
              error={!!formError.email}
              size="small"
              placeholder="Enter Email"
              helperText={formError.email}
            />
            <CustomInputField
              label="Password"
              id="password"
              name="password"
              type="password"
              value={formData.password}
              required
              onChange={handleChange}
              error={!!formError.password}
              size="small"
              placeholder="Enter Password"
              helperText={formError.password}
            />

            <div>
              <Link to="/ResetPassword" className="link">
                Forgot Password
              </Link>
            </div>
            <Button
              variant="contained"
              size="medium"
              type="submit"
              disabled={globalLoading}
            >
              LOG IN
            </Button>
            <div className="center">
              <span className="font-md">New to this site?</span>
              <span>
                <Link className="c-red font-md pd-l-10" to="/registration">
                  Create an Account
                </Link>
              </span>
            </div>
          </div>
        </Box>
        <CookieConsent />
      </div>
    </>
  );
};

export default Login;
