import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CustomInputField } from "../Components/ui";
import { emailRegx, mobileRegx, nameRegEx } from "../helper/constant";
import APIS from "./APIS";
import { useRegisterContext } from "../Components/RegisterContext";
import AppLogo from "../Components/AppLogo";

function Registration() {
  const navigate = useNavigate();
  const { setRegisterData, registerData } = useRegisterContext();
  const [formData, setFormData] = useState({
    ...registerData,
    userType: "email",
    role: "user",
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  const [isTermsVisited, setIsTermsVisited] = useState(false);
  const [isPrivacyPolicyVisited, setIsPrivacyPolicyVisited] = useState(false);
  const [isValidContact, setIsValidContact] = useState(true);
  const [emailError, setEmailError] = useState(
    "The email address format is incorrect. Please enter a valid email address."
  );

  const [formError, setFormError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setFormError({ ...formError, [e.target.name]: "" });
  };

  const showPrivacyPolicy = () => {
    setRegisterData(formData);
    setIsPrivacyPolicyVisited(true);
    navigate("/PrivacyPolicy");
  };

  const showTerms = () => {
    setRegisterData(formData);
    setIsTermsVisited(true);
    navigate("/Terms");
  };

  const register = async () => {
    setIsFormSubmit(true);

    // Check for empty required fields
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setFormError(newErrors);
      return;
    }

    //to check duplicate email
    setIsButtonDisabled(true);
    const response = await APIS.checkEmailDuplicate({ email: formData.email });
    if (response.code == 200) {
      navigate("/MarketingConsent", {
        state: { ...formData, mobileNo: formData.contact },
      });
    } else if (response.code == 409) {
      setFormError({ ...formError, email: response.message });
      setIsValidEmail(false);
    }
    setRegisterData({ ...formData, mobileNo: formData.contact });
    setIsButtonDisabled(false);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName) {
      errors.firstName = "Please enter first name.";
    }

    if (!formData.lastName) {
      errors.lastName = "Please enter last name.";
    }

    if (!nameRegEx.test(formData.firstName)) {
      errors.firstName = "Name and Last name can only contain letters, spaces, hyphens, and apostrophes.";
    }

    if (!nameRegEx.test(formData.lastName)) {
      errors.lastName = "Name and Last name can only contain letters, spaces, hyphens, and apostrophes.";
    }

    if (formData?.firstName?.length > 50) {
      errors.firstName = "The input you entered is too long. Max allowed: 50 characters.";
    }

    if (formData?.lastName?.length > 50) {
      errors.lastName = "The input you entered is too long. Max allowed: 50 characters.";
    }

    if (!formData.email) {
      errors.email = "Please enter E-mail.";
    } else if (!emailRegx.test(formData.email)) {
      errors.email =
        "The email address format is incorrect. Please enter a valid email address.";
    }

    if (!formData.contact) {
      errors.contact = "Please enter Contact No.";
    } else if (!mobileRegx.test(formData.contact)) {
      errors.contact =
        "The Contact No. format is incorrect. Please enter a valid Contact No.";
    }

    return errors;
  };

  return (
    <div className="login-card">
      <AppLogo />
      <h2>Register</h2>

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              width: "48%",
              display: "inline-flex",
            }}
          >
            <CustomInputField
              label="First Name"
              name="firstName"
              placeholder="Enter First Name"
              InputLabelProps={{ shrink: true }}
              onChange={handleInputChange}
              required
              value={formData.firstName || ""}
              error={!!formError.firstName}
              helperText={formError.firstName}
            />
          </Box>

          <Box
            sx={{
              width: "48%",
              display: "inline-flex",
            }}
          >
            <CustomInputField
              label="Last Name"
              name="lastName"
              value={formData.lastName || ""}
              placeholder="Enter Last Name"
              InputLabelProps={{ shrink: true }}
              onChange={handleInputChange}
              required
              error={!!formError.lastName}
              helperText={formError.lastName}
            />
          </Box>
        </div>

        <div style={{ marginTop: 10 }}>
          <CustomInputField
            label="Email"
            name="email"
            value={formData.email || ""}
            placeholder="Enter Email"
            InputLabelProps={{ shrink: true }}
            onChange={handleInputChange}
            required
            error={!!formError.email}
            helperText={formError.email}
          />
        </div>

        <div style={{ marginTop: 10 }}>
          <CustomInputField
            label="Contact No."
            name="contact"
            type="number"
            value={formData.contact || ""}
            placeholder="Enter Contact No."
            InputLabelProps={{ shrink: true }}
            onChange={handleInputChange}
            required
            error={!!formError.contact}
            helperText={formError.contact}
          />
        </div>

        <div style={{ marginTop: 10 }}>
          <CustomInputField
            label="Job Title"
            name="jobTitle"
            value={formData.jobTitle || ""}
            placeholder="Enter Job Title"
            InputLabelProps={{ shrink: true }}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <CustomInputField
            label="Company"
            name="company"
            value={formData.company || ""}
            placeholder="Enter Company"
            InputLabelProps={{ shrink: true }}
            onChange={handleInputChange}
          />
        </div>
        <div className="center" style={{ marginTop: 10 }}>
          <span className="font-md">By signing up, you agree to our </span>
          <span className="font-md c-black link" onClick={showTerms}>
            Terms of Use
          </span>
          <span> and </span>
          <span className="font-md c-black link" onClick={showPrivacyPolicy}>
            Privacy Policy
          </span>
        </div>
        <Button
          variant="contained"
          size="medium"
          onClick={register}
          disabled={isButtonDisabled}
        >
          REGISTER
        </Button>
        <div className="center" sx={{ m: 20 }}>
          <span className="font-md">Already have an account?</span>
          <span>
            <Link className="c-red font-md pd-l-10" to="/login">
              Login
            </Link>
          </span>
        </div>
      </Box>
    </div>
  );
}

export default Registration;
