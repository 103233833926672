import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  Button,
  MenuItem,
  Select,
  Dialog,
  IconButton,
  Typography,
  CircularProgress,
  TextField,
  Snackbar,
} from "@mui/material";
import Sidebar from "../Components/sidebar";
import Navbar from "../Components/navbar";
import { ArrowBack, Close, Error } from "@mui/icons-material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { CustomSwitch, CustomSelect } from "../Components/ui";
import APIS from "./APIS";
import ChannelLogoLable from "../Components/ChannelLogoLable";
import { useAuthContext } from "../Components/AuthContextProvider";
import { useGlobalModalContext } from "../Components/GlobalModalContext/GlobalModalContext";
import SuccessLogo from "../Assets/success.svg";
import { v4 as uuidv4 } from "uuid";
import GlobalLoading from "./GlobalLoading";

const AudiencePerformPush = () => {
  const { token } = useAuthContext();
  const { id } = useParams();
  const location = useLocation();
  const { audienceSize } = location.state;
  const [status, setStatus] = useState({
    google: false,
    meta: false,
    tiktok: false,
  });
  const [selectedAccounts, setSelectedAccounts] = useState({
    googleAds: "",
    meta: "",
    metaAds: "",
    tiktokAds: "",
  });
  const [metaBusinessAccounts, setmetaBusinessAccounts] = useState([]);
  const [googleAdsAccounts, setGoogleAdsAccount] = useState([]);
  const [tiktokAdsAccounts, setTikTokAdsAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successDialog, showSuccessDialog] = useState(false);
  const [error, setError] = useState("");
  const nevigate = useNavigate();
  const { showModal, hideModal, hideAllModal } = useGlobalModalContext();
  const isInitialMount = useRef(true);
  const pushId = uuidv4();

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      getAllList();
    }
  }, []);

  const getAllList = () => {
    const promises = [
      getGoogleAdsAccounts(),
      getMetaAccounts(),
      getTikTokAds(),
    ];
    setLoading(true);
    Promise.all(promises).finally(() => {
      setLoading(false);
    });
  };

  const getMetaAccounts = async () => {
    try {
      const response = await APIS.getMetaLoadAccounts(token);
      setmetaBusinessAccounts(response.accounts ?? []);
    } catch (error) {
      setError("Error Fetching MetaAds Account");
    }
  };

  const getTikTokAds = async () => {
    try {
      const response = await APIS.getTikTokAdsAccounts(token);
      setTikTokAdsAccounts(response.data ?? []);
    } catch (error) {
      console.error("Error Fetching  Tiktoks Account", error);
    }
  };

  const getMetaAdsAccounts = () => {
    if (selectedAccounts.meta) {
      const filterArray = metaBusinessAccounts.filter(
        (account) => account["business_manager_id"] == selectedAccounts.meta
      );
      return filterArray[0]["account_ids"];
    }
    return [];
  };

  const getMetaBusinessAccounts = () => {
    const business_manager_ids = metaBusinessAccounts.map(
      (obj) => obj.business_manager_id
    );

    return business_manager_ids;
  };

  const getGoogleAdsAccounts = async () => {
    try {
      const response = await APIS.getGoogleAdsAccounts(token);
      const AdsAccounts = response?.customerIds?.map((account) => ({
        label: account,
        value: account,
      }));
      setGoogleAdsAccount(AdsAccounts ?? []);
    } catch (error) {
      setError("Error Fetching Google Ads Account");
    }
  };

  const audiencePushHandle = async () => {
    try {
      if (status.google && !selectedAccounts.googleAds) {
        setError("Select Google Ads Account ");
        return;
      }

      if (status.meta && !selectedAccounts.metaAds) {
        setError("Select Meta Ads Account");
        return;
      }

      if (status.tiktok && !selectedAccounts.tiktokAds) {
        setError("Select Tiktok Ads Account");
        return;
      }

      let channelCount = 0;
      if (status.google) {
        channelCount = channelCount + 1;
      }
      if (status.meta) {
        channelCount = channelCount + 1;
      }
      if (status.tiktok) {
        channelCount = channelCount + 1;
      }
      setLoading(true);

      const checkQuotaResponse = await APIS.checkQuota(
        {
          audienceSize,
          channelCount,
        },
        token
      );

      setLoading(false);

      if (checkQuotaResponse.code == 428) {
        showModal(
          QuotaCheckDialog(
            checkQuotaResponse.error,
            checkQuotaResponse.exportCount,
            channelCount
          )
        );
      } else if (checkQuotaResponse.code == 409) {
        showModal(FailDialog(checkQuotaResponse.error, true));
      } else if (checkQuotaResponse.status == "fail") {
        showModal(FailDialog(checkQuotaResponse.message));
      } else {
        performAudiencePush(checkQuotaResponse.exportCount, channelCount);
      }
    } catch (error) {
      setError("One or more API requests failed");
      // setLoading(false);
      console.error("One or more API requests failed", error);
    }
  };

  const performAudiencePush = async (exportCount, channelCount) => {
    setLoading(true);
    showSuccessDialog(true);
    let promises = [];
    if (status.google) {
      const promise = pushGoogleAudience(exportCount, channelCount);
      promises = [...promises, promise];
    }
    if (status.meta) {
      const promise = pushMetaAudience(exportCount, channelCount);
      promises = [...promises, promise];
    }
    if (status.tiktok) {
      const promise = pushTiktokAudience(exportCount, channelCount);
      promises = [...promises, promise];
    }
    const resposnes = await Promise.all(promises);
    setLoading(false);
  };

  const pushGoogleAudience = async (exportCount, channelCount) => {
    try {
      const data = {
        exportCount,
        channelCount,
        customerId: selectedAccounts.googleAds,
        audienceId: id,
        campaignCreation: false,
        pushId,
      };
      return await APIS.pushAudienceGoogle(token, data);
    } catch (error) {
      throw new Error("Google Audience Push Failed!", error);
    }
  };

  const pushTiktokAudience = async (exportCount, channelCount) => {
    try {
      const data = {
        exportCount,
        channelCount,
        adsId: selectedAccounts.tiktokAds,
        audienceId: id,
        pushId,
      };
      return await APIS.pushAudienceTiktok(token, data);
    } catch (error) {
      throw new Error("Tiktok Audience Push Failed!", error);
    }
  };

  const pushMetaAudience = async (exportCount, channelCount) => {
    try {
      const data = {
        exportCount,
        channelCount,
        adsAccountId: selectedAccounts.metaAds,
        businessManagerId: selectedAccounts.meta,
        audienceId: id,
        campaignCreation: false,
        pushId,
      };
      return await APIS.pushAudienceMeta(token, data);
    } catch (error) {
      throw new Error("Meta Audience Push Failed!", error);
    }
  };

  const handleGoAudienceList = () => {
    hideAllModal();
    nevigate("/AudienceList");
  };

  const handleCloseSanckBar = () => {
    setError("");
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const checkStatus = (channel) => {
    if (channel === "tiktok") {
      setStatus({ ...status, tiktok: !status.tiktok });
    }

    if (channel === "google") {
      setStatus({ ...status, google: !status.google });
    }
    if (channel === "meta") {
      setStatus({ ...status, meta: !status.meta });
    }
  };

  const isButtonDisabled = () => {
    if (loading) return loading;
    if (!status.google && !status.meta && !status.tiktok) {
      return true;
    }
    return false;
  };

  const getConfirmDialogContent = () => {
    let adsArray = [];
    let channelArray = [];
    let ads = "";
    let channels = "";
    if (status.google) {
      channelArray.push("Google");
      adsArray.push(`Google (${selectedAccounts.googleAds})`);
    }

    if (status.meta) {
      channelArray.push("Meta");
      adsArray.push(`Meta (${selectedAccounts.metaAds})`);
    }

    if (status.tiktok) {
      channelArray.push("Tiktok");
      adsArray.push(`Tiktok (${selectedAccounts.tiktokAds})`);
    }

    if (channelArray.length === 1) {
      ads = adsArray[0];
      channels = channelArray[0];
    } else {
      const lastAds = adsArray.pop();
      const lastChannel = channelArray.pop();
      ads = `${adsArray.join(", ")} and ${lastAds}`;
      channels = `${channelArray.join(", ")} and ${lastChannel}`;
    }

    return `You are about to push the ${audienceSize} audience size segment to ‘${ads}’. Once pushed, this segment will be used in your ${channels} Ads campaign. Do you want to proceed?`;
  };

  const ConfirmDialog = () => (
    <Dialog
      open
      onClose={hideModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
    >
      <Grid
        display={"grid"}
        padding={"24px"}
        justifyContent={"center"}
        rowGap={2}
      >
        <Grid display={"grid"} justifyContent={"center"}>
          <img src={SuccessLogo} />
        </Grid>

        <Typography
          display={"grid"}
          variantMapping="h2"
          justifyContent={"center"}
          fontWeight={"bold"}
        >
          Do you want to confirm push Audience?
        </Typography>
        <Grid display={"grid"} justifyContent={"center"}>
          {getConfirmDialogContent()}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={hideModal}>
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                hideModal();
                audiencePushHandle();
              }}
            >
              CONFIRM
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );

  const SuccessDialog = () => (
    <Dialog
      open={successDialog}
      onClose={() => {
        showSuccessDialog(false);
        handleGoAudienceList();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid
        display={"grid"}
        padding={"24px"}
        justifyContent={"center"}
        rowGap={2}
      >
        <Grid display={"grid"} justifyContent={"center"}>
          <img src={SuccessLogo} />
        </Grid>

        <Typography
          display={"grid"}
          variantMapping="h2"
          justifyContent={"center"}
          fontWeight={"bold"}
        >
          Successfully pushed audience!
        </Typography>
        <Grid display={"grid"} justifyContent={"center"}>
          Your audience is pushed to Ads platform
        </Grid>
        <Button fullWidth variant="contained" onClick={handleGoAudienceList}>
          GO TO AUDIENCE LIST
        </Button>
      </Grid>
    </Dialog>
  );

  const QuotaCheckDialog = (content, exportCount, channelCount) => (
    <Dialog
      open
      onClose={hideModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid
        display={"grid"}
        padding={1}
        marginTop={2}
        justifyContent={"center"}
        rowGap={2}
      >
        <IconButton
          aria-label="close"
          onClick={hideModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>

        <Grid display={"grid"} justifyContent={"center"}>
          <Error color="warning" fontSize="large" />
        </Grid>
        <Grid display={"grid"} alignItems={"center"}>
          <Typography variant="h6" textAlign={"center"}>
            WARNING
          </Typography>
        </Grid>
        <Grid display={"grid"} alignItems={"center"}>
          {content}
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={hideModal}>
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                hideModal();
                performAudiencePush(exportCount, channelCount);
              }}
            >
              OK
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );

  const FailDialog = (content, reachQuota = true) => (
    <Dialog
      open
      onClose={hideModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
    >
      <Grid
        display={"grid"}
        paddingTop={1}
        justifyContent={"center"}
        rowGap={2}
      >
        <IconButton
          aria-label="close"
          onClick={hideModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>

        <Grid display={"grid"} justifyContent={"center"}>
          <Error color="warning" fontSize="large" />
        </Grid>

        {reachQuota && (
          <Grid display={"grid"} alignItems={"center"}>
            <Typography variant="h6" textAlign={"center"}>
              Quota Limit Reached
            </Typography>
          </Grid>
        )}

        <Grid display={"grid"} alignItems={"center"}>
          {content}
        </Grid>
        <Grid display={"grid"} alignItems={"center"}>
          <Button variant="contained" fullWidth onClick={hideModal}>
            CLOSE
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );

  return (
    <>
      {loading && <GlobalLoading />}
      <div className="inner-wrapper">
        <Grid container>
          <Grid item xs={12}>
            <Navbar />
          </Grid>
          <Grid item xs={1}>
            <Sidebar />
          </Grid>
          <Grid item xs={11} padding={2}>
            <div className="content-card ">
              <h2>Perform Audience Push</h2>
              <div className="sub-title">To set audience segment</div>
              <div className="back-text" onClick={handleGoAudienceList}>
                <ArrowBack />
                <span>Back to Audience List</span>
              </div>

              <Grid marginTop={5}>
                <h4>Choose Your Channel</h4>
                <Grid>Selecting the channel to push the Audience to</Grid>
                <Grid className="content-card" marginTop={3}>
                  <Grid padding={3}>
                    <Grid
                      display={"flex"}
                      alignItems={"center"}
                      marginBottom={2}
                    >
                      <CustomSwitch
                        checked={status.google}
                        disabled={!googleAdsAccounts?.length}
                        onClick={() => checkStatus("google")}
                      />
                      <Grid marginX={1}>
                        <ChannelLogoLable channelName={"Google"} />
                      </Grid>
                    </Grid>
                    <Grid fontSize={14}>Choose A Google Ads Account</Grid>
                    <Grid mt={1}>
                      <CustomSelect
                        size={"small"}
                        value={selectedAccounts.googleAds}
                        disabled={!status.google}
                        fullWidth
                        options={googleAdsAccounts}
                        onChange={(data) => {
                          setSelectedAccounts({
                            ...selectedAccounts,
                            googleAds: data.target.value,
                          });
                        }}
                        placeholder="Choose A Google Ads Account"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid className="content-card" marginTop={3}>
                  <Grid padding={3}>
                    <Grid
                      display={"flex"}
                      alignItems={"center"}
                      marginBottom={2}
                    >
                      <CustomSwitch
                        checked={status.meta}
                        disabled={!metaBusinessAccounts?.length}
                        onClick={() => checkStatus("meta")}
                      />
                      <Grid marginX={1}>
                        <ChannelLogoLable channelName={"Meta"} />
                      </Grid>
                    </Grid>
                    <Grid fontSize={14}>
                      Choose A Meta Business Manager Account
                    </Grid>
                    <Grid mt={1}>
                      <CustomSelect
                        value={selectedAccounts.meta}
                        disabled={!status.meta}
                        size={"small"}
                        fullWidth
                        variant="outlined"
                        onChange={(data) => {
                          setSelectedAccounts({
                            ...selectedAccounts,
                            meta: data.target.value,
                          });
                        }}
                        options={getMetaBusinessAccounts()?.map((acccount) => ({
                          label: acccount,
                          value: acccount,
                        }))}
                        placeholder="Choose a Meta Business Manager Account"
                      />
                    </Grid>
                    <Grid fontSize={14} mt={1}>
                      Choose A Meta Ads Account
                    </Grid>
                    <Grid mt={1}>
                      <CustomSelect
                        value={selectedAccounts.metaAds}
                        disabled={!status.meta}
                        size={"small"}
                        fullWidth
                        onChange={(data) => {
                          setSelectedAccounts({
                            ...selectedAccounts,
                            metaAds: data.target.value,
                          });
                        }}
                        options={getMetaAdsAccounts()?.map((acccount) => ({
                          label: acccount.ads_account_id,
                          value: acccount.ads_account_id,
                        }))}
                        placeholder="Choose a Meta Ads Account"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="content-card" marginTop={3}>
                  <Grid padding={3}>
                    <Grid
                      display={"flex"}
                      alignItems={"center"}
                      marginBottom={2}
                    >
                      <CustomSwitch
                        checked={status.tiktok}
                        disabled={!tiktokAdsAccounts?.length}
                        onClick={() => {
                          checkStatus("tiktok");
                        }}
                      />
                      <Grid marginX={1}>
                        <ChannelLogoLable channelName={"Tiktok"} />
                      </Grid>
                    </Grid>
                    <Grid fontSize={14}>Choose A Tiktok Ads Account</Grid>
                    <Grid mt={1}>
                      <CustomSelect
                        size={"small"}
                        value={selectedAccounts.tiktokAds}
                        disabled={!status.tiktok}
                        fullWidth
                        options={tiktokAdsAccounts?.map((acccount) => ({
                          value: acccount.advertiser_id,
                          label: acccount.advertiser_name,
                        }))}
                        onChange={(data) => {
                          setSelectedAccounts({
                            ...selectedAccounts,
                            tiktokAds: data.target.value,
                          });
                        }}
                        placeholder="Choose a TikTok Ads Account"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid display={"flex"} marginTop={2} gap={2} justifyContent={"end"}>
              <Button variant="outlined" onClick={handleGoAudienceList}>
                Back
              </Button>
              <Button
                variant="contained"
                onClick={() => showModal(<ConfirmDialog />)}
                disabled={isButtonDisabled()}
              >
                Perform Audience Push
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <SuccessDialog />
        <Snackbar
          open={error ? true : false}
          autoHideDuration={6000}
          onClose={handleCloseSanckBar}
          message={error}
          action={action}
        />
      </div>
    </>
  );
};

export default AudiencePerformPush;
