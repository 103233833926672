import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AudienceFooter from "./AudienceFooter";
import APIS from "./APIS";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { IconButton, Snackbar } from "@mui/material";
import Close from "@mui/icons-material/Close";

const LocationCheckboxes = ({
  locationObj,
  selectedRegions,
  setSelectedRegions,
  selectedProvinces,
  setSelectedProvinces,
  selectedDistricts,
  setSelectedDistricts,
  provinceDistrictCount,
  setProvinceDistrictCount,
  regionProvinceCount,
  setRegionProvinceCount,
  handleNextTab,
  handleTotalCount,
  audTotalCount,
  handleClearAll,
  audTotalCategories,
  handleCalculateTotalCategories,
  handleAddCondition
}) => {
  const [regionFilter, setRegionFilter] = useState("");
  const [provinceFilter, setProvinceFilter] = useState("");
  const [districtFilter, setDistrictFilter] = useState("");
  const [errorSnackbar, setErrorSnackbar] = useState("");

  useEffect(() => {
    // Set initial state onload
    loadSelectedRegionsData();
    totalSelectCount();
  }, [selectedRegions, selectedProvinces, selectedDistricts]);

  const filteredRegions = locationObj?.Location?.filter((region) =>
    region?.Region?.toLowerCase().includes(regionFilter.toLowerCase())
  );

  const totalSelectCount = () => {
    handleCalculateTotalCategories();
  };

  const loadSelectedRegionsData = () => {
    locationObj?.Location?.forEach((region) => {
      const regionName = region.Region;
      const provinceCount = region.Province.length;
      regionProvinceCount[regionName] = provinceCount;

      region.Province.forEach((province) => {
        const provinceName = province.value;
        const districtCount = province.district.length;
        provinceDistrictCount[provinceName] = districtCount;
      });
    });
    setProvinceDistrictCount(provinceDistrictCount);
    setRegionProvinceCount(regionProvinceCount);
  };

  const handleRegionChange = (region) => {
    const newSelectedRegions = [...selectedRegions];
    const regionIndex = newSelectedRegions.indexOf(region.toLowerCase());
    let newSelectedDistricts = [...selectedDistricts];
    let newSelectedProvinces = [...selectedProvinces];

    if (regionIndex === -1) {
      // Adding new Region
      newSelectedRegions.push(region.toLowerCase());
      newSelectedDistricts.push({
        region,
        province: null,
        district: null,
      });
    } else {
      // Removing a Region
      newSelectedRegions.splice(regionIndex, 1);
      newSelectedProvinces = newSelectedProvinces.filter(
        (province) => getRegionForProvince(province) !== region
      );
      newSelectedDistricts = newSelectedDistricts.filter(
        (item) => item.region !== region
      );
    }

    setSelectedProvinces(newSelectedProvinces);
    setSelectedDistricts(newSelectedDistricts);
    setSelectedRegions(newSelectedRegions);
  };

  const handleProvinceChange = (province) => {
    const newSelectedProvinces = [...selectedProvinces];
    const provinceIndex = newSelectedProvinces.indexOf(province);
    const region = getRegionForProvince(province);

    let newSelectedDistricts = [...selectedDistricts];

    if (provinceIndex === -1) {
      newSelectedProvinces.push(province);

      newSelectedDistricts = newSelectedDistricts.filter(
        (item) =>
          item.region !== region || (item.region == region && item.province)
      );

      newSelectedDistricts.push({ region, province, district: null });
    } else {
      newSelectedProvinces.splice(provinceIndex, 1);

      const sameRegionCount = newSelectedDistricts.filter(
        (item) => item.region == region
      ).length;

      if (sameRegionCount > 1) {
        newSelectedDistricts = newSelectedDistricts.filter(
          (item) => item.province !== province
        );
      } else {
        newSelectedDistricts = newSelectedDistricts.map((item) =>
          item.province === province ? { ...item, province: null } : item
        );
      }
    }

    setSelectedProvinces(newSelectedProvinces);
    setSelectedDistricts(newSelectedDistricts);
  };

  const handleDistrictChange = (districtObj) => {
    let newSelectedDistricts = [...selectedDistricts];

    const sameProvinces = newSelectedDistricts.filter(
      (item) =>
        item.province === districtObj.province &&
        item.region === districtObj.region
    );

    // if(newSelectedDistricts.filter)

    const districtIndex = newSelectedDistricts.findIndex(
      (dist) =>
        dist.region === districtObj.region &&
        dist.district === districtObj.district &&
        dist.province === districtObj.province
    );

    if (districtIndex === -1) {
      if (sameProvinces.length == 1 && !sameProvinces[0].district) {
        newSelectedDistricts = newSelectedDistricts.map((item) =>
          item.region == districtObj.region &&
          item.province == districtObj.province
            ? { ...item, district: districtObj.district }
            : item
        );
      } else {
        newSelectedDistricts.push(districtObj);
      }
    } else {
      if (sameProvinces.length == 1) {
        newSelectedDistricts = newSelectedDistricts.map((item) =>
          item.region == districtObj.region &&
          item.province == districtObj.province
            ? { ...item, district: null }
            : item
        );
      } else {
        newSelectedDistricts.splice(districtIndex, 1);
      }
    }

    setSelectedDistricts(newSelectedDistricts);
  };

  const handleSelectAllRegions = () => {
    const allRegions = locationObj?.Location.map((region) =>
      region.Region.toLowerCase()
    );
  
    if (selectedRegions.length === allRegions.length) {
      // Deselect all
      setSelectedRegions([]);
      setSelectedProvinces([]);
      setSelectedDistricts([]);
    } else {
      // Select all
      setSelectedRegions(allRegions);
  
      const existingProvinces = selectedProvinces.filter((province) =>
        allRegions.includes(getRegionForProvince(province))
      );
  
      const existingDistricts = selectedDistricts.filter((districtObj) =>
        allRegions.includes(districtObj.region.toLowerCase())
      );
  
      // Create selected districts with retained provinces and districts
      const newSelectedDistricts = allRegions.map((region) => {
        const provincesForRegion = locationObj?.Location.find(
          (reg) => reg.Region.toLowerCase() === region
        )?.Province;
  
        const provincesToAdd = provincesForRegion
          ? provincesForRegion.map((prov) => prov.value)
          : [];
  
        // Keep existing districts if they belong to the selected region
        const districtsForRegion = existingDistricts.filter(
          (districtObj) => districtObj.region.toLowerCase() === region
        );
  
        return provincesToAdd.map((province) => {
          if (districtsForRegion.length > 0) {
            return districtsForRegion.filter(
              (districtObj) => districtObj.province === province
            );
          } else {
            return { region, province, district: null };
          }
        });
      }).flat(2);
  
      setSelectedProvinces(existingProvinces);
      setSelectedDistricts(newSelectedDistricts);
    }
  };
  

  const handleSelectAllProvinces = () => {
    const allProvinces = getSelectedProvinces();
  
    if (selectedProvinces.length === allProvinces.length) {
      setSelectedProvinces([]);
      const newSelectedDistricts = selectedRegions.map((region) => {
        return { region, province: null, district: null };
      });
      setSelectedDistricts(newSelectedDistricts);
    } else {
      setSelectedProvinces(allProvinces);
  
      const existingDistricts = selectedDistricts.filter((districtObj) =>
        allProvinces.includes(districtObj.province)
      );
  
      const newSelectedDistricts = allProvinces.map((province) => {
        const region = getRegionForProvince(province);
  
        // Check if there are existing districts for this province
        const districtsForProvince = existingDistricts.filter(
          (districtObj) => districtObj.province === province
        );
  
        if (districtsForProvince.length > 0) {
          // Retain existing districts
          return districtsForProvince;
        } else {
          // Otherwise, add the province as a selected item with no district
          return { region, province, district: null };
        }
      }).flat();
  
      setSelectedDistricts(newSelectedDistricts);
    }
  };
  

  const handleSelectAllDistricts = () => {
    let allDistricts = getSelectedDistricts();

    if (
      selectedDistricts.filter((item) => item.district).length ===
      allDistricts.length
    ) {
      allDistricts = selectedProvinces.map((province) => {
        const region = getRegionForProvince(province);
        return { region, province, district: null };
      });
    }

    for (const region of selectedRegions) {
      if (!allDistricts.some((district) => district.region == region)) {
        allDistricts.push({ region, district: null, province: null });
      }
    }
    setSelectedDistricts(allDistricts);
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleClear = () => {
    setRegionFilter("");
    setProvinceFilter("");
    setDistrictFilter("");
    handleClearAll();
  };

  return (
    <div>
      <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "5000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <Grid container spacing={2} className="checklist-card">
        <Grid item xs={4}>
          <span>Region</span>
          <br />
          <br />

          <div>
            <TextField
              sx={{ width: "100%" }}
              // variant="standard"
              size="small"
              placeholder="Select..."
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
              value={regionFilter}
              onChange={(e) => setRegionFilter(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <br></br>
          <div>
            {!regionFilter && (
              <label>
                <input
                  type="checkbox"
                  value="selectAllRegions"
                  checked={
                    selectedRegions.length === locationObj?.Location?.length
                  }
                  onChange={handleSelectAllRegions}
                />
                <span className="checkmark"></span>
                <label>All</label>
              </label>
            )}
            <span className="checkmark"></span>
          </div>
          {filteredRegions?.map((region) => (
            <div key={region.Region} className="mtb-4">
              <label>
                <input
                  type="checkbox"
                  value={region.Region.toLowerCase()}
                  checked={selectedRegions.includes(
                    region.Region.toLowerCase()
                  )}
                  onChange={() => handleRegionChange(region.Region)}
                />

                <span className="checkmark"></span>
                <label> {region.Region.toLowerCase()}</label>
              </label>
            </div>
          ))}
        </Grid>

        {selectedRegions.length > 0 && (
          <Grid item xs={4}>
            <span>Province</span>
            <br />
            <br />
            <div>
              <TextField
                sx={{ width: "100%" }}
                // variant="standard"
                size="small"
                placeholder="Select..."
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
                value={provinceFilter}
                onChange={(e) => setProvinceFilter(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <br></br>
            <div>
              {!provinceFilter && (
                <label>
                  <input
                    type="checkbox"
                    value="selectAllRegions"
                    checked={
                      selectedProvinces.length === getSelectedProvinces().length
                    }
                    onChange={handleSelectAllProvinces}
                  />
                  <span className="checkmark"></span>
                  <label>All</label>
                </label>
              )}
              <span className="checkmark"></span>
            </div>
            {getSelectedProvinces().map((province) => (
              <div key={province} className="mtb-2">
                <label>
                  <input
                    type="checkbox"
                    value={province}
                    checked={selectedProvinces.includes(province)}
                    onChange={() => handleProvinceChange(province)}
                  />
                  <span className="checkmark"></span>
                  <label>
                    {province?.toLowerCase()}
                    <span className="text-sub-title">
                      <ArrowForwardIcon /> {getRegionForProvince(province)}
                    </span>
                  </label>
                </label>
              </div>
            ))}
          </Grid>
        )}

        {selectedProvinces.length > 0 && (
          <Grid item xs={4}>
            <span>District</span>
            <br />
            <br />
            <div>
              <TextField
                sx={{ width: "100%" }}
                // variant="standard"
                size="small"
                placeholder="Select..."
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
                value={districtFilter}
                onChange={(e) => setDistrictFilter(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <br></br>
            <div>
              {!districtFilter && (
                <label>
                  <input
                    type="checkbox"
                    value="selectAllRegions"
                    checked={
                      selectedDistricts.filter((item) => item.district)
                        .length === getSelectedDistricts().length
                    }
                    onChange={handleSelectAllDistricts}
                  />
                  <span className="checkmark"></span>
                  <label>All</label>
                </label>
              )}
              <span className="checkmark"></span>
            </div>
            {getSelectedDistricts().map((districtObj) => (
              <div
                key={districtObj.district + districtObj.province}
                className="mtb-2"
              >
                <label>
                  <input
                    type="checkbox"
                    value={districtObj.district}
                    checked={selectedDistricts.some(
                      (selected) =>
                        selected.district === districtObj.district &&
                        selected.province === districtObj.province
                    )}
                    onChange={() => handleDistrictChange(districtObj)}
                  />

                  <span className="checkmark"></span>
                  <label>
                    {districtObj.district}
                    <span className="text-sub-title">
                      {" "}
                      <ArrowForwardIcon /> {districtObj.province?.toLowerCase()}
                    </span>
                  </label>
                </label>
              </div>
            ))}
          </Grid>
        )}
      </Grid>
      <div>
        <AudienceFooter
          count={audTotalCount}
          onCalculate={handleTotalCount}
          totalSlectionCount={audTotalCategories}
          onChildClick={handleClear}
          onNextClick={handleNextTab}
          handleAddCondition={handleAddCondition}
        />
      </div>
    </div>
  );

  function getSelectedProvinces() {
    return locationObj?.Location?.filter((region) =>
      selectedRegions.includes(region.Region.toLowerCase())
    )
      .flatMap((region) => region.Province.map((province) => province.value))
      .filter((province) =>
        province.toLowerCase().includes(provinceFilter.toLowerCase())
      );
  }

  function getSelectedDistricts() {
    const selectedDistrictsArray = locationObj?.Location?.filter((region) =>
      selectedRegions.includes(region.Region.toLowerCase())
    )
      .flatMap((region) =>
        region.Province.filter((prov) =>
          selectedProvinces.includes(prov.value)
        ).flatMap((prov) =>
          prov.district.map((district) => ({
            region: region.Region,
            province: prov.value,
            district: district.value,
          }))
        )
      )
      .filter((districtObj) =>
        districtObj.district
          .toLowerCase()
          .includes(districtFilter.toLowerCase())
      );

    return selectedDistrictsArray || [];
  }

  function getRegionForProvince(province) {
    const region = locationObj?.Location?.find((region) =>
      region.Province.some((prov) => prov.value === province)
    );
    return region ? region.Region.toLowerCase() : "";
  }

  function getProvinceForDistrict(districtName) {
    for (const region of locationObj?.Location) {
      for (const province of region.Province) {
        for (const district of province.district) {
          if (district.value.toLowerCase() === districtName.toLowerCase()) {
            return province.value;
          }
        }
      }
    }
    return "";
  }

  function getRegionClassName(region, selectedProvinces) {
    const countForRegion = regionProvinceCount[region];
    const currentCount = findRegionCounts(selectedProvinces);
    if (countForRegion > currentCount[region]) {
      return "partial";
    } else if (countForRegion == currentCount[region]) {
      return "";
    } else {
      return "";
    }
  }

  function findRegionCounts(province) {
    const regionCounts = {};

    selectedProvinces.forEach((province) => {
      const region = getRegionForProvince(province, locationObj);
      if (region) {
        regionCounts[region] = (regionCounts[region] || 0) + 1;
      }
    });

    return regionCounts;
  }

  function getProvinceClassName(province) {
    const countForProvince = provinceDistrictCount[province];

    const currentCount = findProvinceCounts(selectedDistricts);

    if (
      selectedDistricts.filter(
        (dis) => dis.province === province && dis.district
      ).length > 0
    ) {
      return "partial";
    } else if (
      countForProvince === currentCount[province] &&
      countForProvince > 0
    ) {
      return "selected"; // Assuming this is the fully selected class
    } else {
      return "";
    }
  }

  function findProvinceCounts(districtsToFind) {
    const provinceCounts = {};

    districtsToFind.forEach((districtObj) => {
      const provinceName = districtObj.province;
      provinceCounts[provinceName] = (provinceCounts[provinceName] || 0) + 1;
    });

    return provinceCounts;
  }
};
export default LocationCheckboxes;
