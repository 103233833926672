import { useState } from "react";
import {
  Dialog,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";
import FeaturedIconError from "../../Assets/FeaturedIconError.svg";

const ErrorMessageDialog = ({ title, description }) => {
  const { hideModal, hideAllModal } = useGlobalModalContext();

  const handleSubmit = (e) => {
    e.preventDefault();

    hideModal();
    //onSuccess();
  };
  return (
    <Dialog
    open
    onClose={hideModal}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth={"xs"}
  >
    <Grid
      display={"grid"}
      padding={"24px"}
      justifyContent={"center"}
      rowGap={2}
    >
      <Grid display={"grid"} justifyContent={"center"}>
        <img src={FeaturedIconError} />
      </Grid>

      <Typography
        display={"grid"}
        variantMapping={{ h2: 'h2' }}
        justifyContent={"center"}
        fontWeight={"bold"}
      >
        {title}
      </Typography>
      <Grid display={"grid"} justifyContent={"center"}>
        {description}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button variant="contained" fullWidth onClick={hideModal}>
            CLOSE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </Dialog>
  );
};

export default ErrorMessageDialog;
