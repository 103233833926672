import CustomTable from "../ui/Table/CustomTable";
import { formatDate, formatDatetime } from "../../helper/util";
import { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";

const AudSummaryReportTable = ({
  count,
  data,
  rowPerPage,
  page,
  handlePageChange,
  handleRowsPerPageChange,
  onSuccess,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [id, setId] = useState("");

  const getStatusDot = (status) => {
    const dotStyle = {
      marginRight: "5px",
    };

    if (status?.toLocaleLowerCase() === "completed") {
      return <span style={{ ...dotStyle, color: "green" }}>●</span>;
    } else if (status?.toLocaleLowerCase() === "in progress") {
      return <span style={{ ...dotStyle, color: "#FFA500" }}>●</span>;
    } else if (status?.toLocaleLowerCase() === "failed") {
      return <span style={{ ...dotStyle, color: "red" }}>●</span>;
    }
  };

  const columns = [
    {
      name: "No.",
      id: "number",
    },
    {
      name: "Company Name",
      id: "account_name",
    },
    {
      name: "Audience Name",
      id: "audience_name",
    },
    {
      name: "Created Date",
      id: "created_date",
      element: (row) => `${formatDate(row.created_date?.value)}`,
      minWidth: 100,
    },
    {
      name: "Created By",
      id: "created_by",
    },
    {
      name: "Push Date-Time",
      id: "pushed_date",
      element: (row) => `${formatDatetime(row.pushed_date?.value)}`,
      minWidth: 150,
    },
    {
      name: "Pushed By",
      id: "pushed_by",
    },
    {
      name: "Audience Size",
      id: "size",
    },
    {
      name: "Channel",
      id: "channel",
    },
    {
      name: "Ads Account",
      id: "ads_account",
    },
    {
      name: "Push Count",
      id: "push_count",
    },
    {
      name: "Status",
      id: "status",
      element: (row) => (
        <>
          {getStatusDot(row.status)}
          {`${row.status}`}
        </>
      ),
    },
  ];
  return (
    <CustomTable
      columns={columns}
      data={data}
      count={count}
      rowPerPage={rowPerPage}
      page={page}
      handlePageChange={handlePageChange}
      handleRowsPerPageChange={handleRowsPerPageChange}
    />
  );
};

export default AudSummaryReportTable;
