import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Grid,
  Box,
  CircularProgress,
  Button,
  IconButton,
  Snackbar,
} from "@mui/material";
import { Search, Close } from "@mui/icons-material";
import Navbar from "../Components/navbar";
import Sidebar from "../Components/sidebar";
import { useNavigate } from "react-router-dom";
import APIS from "./APIS";
import { useGlobalModalContext } from "../Components/GlobalModalContext/GlobalModalContext";
import GlobalLoading from "./GlobalLoading";
import MainPackageTable from "../Components/PackageManagement/MainPackageTable";
import AddNewPackageDialog from "../Components/PackageManagement/AddNewPackageDialog";
import { CustomSelect } from "../Components/ui";
import TopupPackageTable from "../Components/PackageManagement/TopupPackageTable";

const PackageManagementList = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [globalLoading, setGlobalLoading] = useState(false);
  const { hideModal, showModal } = useGlobalModalContext();
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [packageType, setPackageType] = useState("All");
  const [type, setType] = useState("");
  const isInitialMount = useRef(true);
  const menuItems = [
    { value: "All", label: "All"},
    { value: "Main", label: "Main" },
    { value: "Topup", label: "Topup" },
  ];
  const [query, setQuery] = useState({
    packageName: "",
    status: "All",
    startDate: "",
    endDate: "",
  });
  const [paginationMain, setPaginationMain] = useState({
    page: 0,
    limit: 10,
    totalCount: 0,
  });

  const statusOptions = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      //if (packageType) {
        fetchPackages();
        //fetchMainPackages();
      //}
    }
  }, [
    paginationMain.page,
    paginationMain.limit,
  ]);

  const fetchPackages = async () => {
    const urlParams = new URLSearchParams({
      ...query,
      status: query.status == 'All' ? '' : query.status,
      type: packageType == 'All' ? '' : packageType,
      page: paginationMain.page + 1,
      limit: paginationMain.limit,
    });
    const queryString = urlParams.toString();
    try {
      setLoading(true); // Set loading to true when fetching data
      const token = localStorage.getItem("token");
      const response = await APIS.getPackageList(token, queryString);
      if (response.status === "success") {
        setPackages(response.packages ?? []); // Update projects state with fetched data
        setPaginationMain({
          ...paginationMain,
          totalCount: response.totalCount,
        });
        setType(packageType);
        setLoading(false); // Set loading to false after fetching data
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false); // Set loading to false in case of error
    }
  };
  const handleChangePageMain = (event, newPage) => {
    setPaginationMain({ ...paginationMain, page: newPage });
  };

  const handleChangeRowsPerPageMain = (event) => {
    setPaginationMain({
      ...paginationMain,
      limit: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleAddNewPackage = () => {
    showModal(
      <AddNewPackageDialog
        createMainPackage={createMainPackage}
        createTopupPackage={createTopupPackage}
      />
    );
  };

  const createMainPackage = async (formData) => {
    const payload = {
      name: formData.name,
      description: formData.description,
      price: formData.price + "THB",
      duration: Number(formData.duration),
      export_count: Number(formData.exportCount),
      //audience_size: 1000,
      audience_size_per_export: Number(formData.maxAudienceSize),
      connectors_count: Number(formData.connectorsCount),
    };
    try {
      setGlobalLoading(true);
      const token = localStorage.getItem("token");
      const response = await APIS.createMainPackage(payload, token);
      if (response.status === "success") {
        hideModal();
        fetchPackages();
        setGlobalLoading(false);
        setErrorSnackbar(response?.message);
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
        hideModal();
        setGlobalLoading(false);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const createTopupPackage = async (formData) => {
    const payload = {
      name: formData.name,
      description: formData.description,
      price: formData.price + "THB",
      duration: Number(formData.duration),
      export_count: Number(formData.exportCount),
    };
    try {
      setGlobalLoading(true);
      const token = localStorage.getItem("token");
      const response = await APIS.createTopupPackage(payload, token);
      if (response.status === "success") {
        hideModal();
        setGlobalLoading(false);
        setErrorSnackbar(response?.message);
        fetchPackages();
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
        hideModal();
        setGlobalLoading(false);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleDeactivatePackage = async (packageId, packageType) => {
    const packageTypeString = packageType.toLocaleLowerCase();
    const formData = {
      type: packageType.toLocaleLowerCase(), //packageTypeString,
      id: packageId,
    };
    try {
      setGlobalLoading(true);
      const token = localStorage.getItem("token");
      const response = await APIS.deactivatePackage(formData, token);
      if (response.status === "success") {
        hideModal();
        setGlobalLoading(false);
        fetchPackages();
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
        hideModal();
        setGlobalLoading(false);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="inner-wrapper">
      {globalLoading && <GlobalLoading />}
      <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "5000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <Grid container>
        <Grid item xs={12}>
          <Navbar />
        </Grid>
        <Grid item xs={1}>
          <Sidebar />
        </Grid>
        <Grid item xs={11}>
          <div className="content-wrapper">
            <h2>Package Management</h2>
            <div className="sub-title">To manage package</div>
            <Grid className="search-card" mt={2}>
              <Box
                sx={{
                  width: "60%",
                  display: "inline-flex",
                }}
                columnGap={1}
              >
                <TextField
                  onChange={(e) => {
                    setQuery({ ...query, packageName: e.target.value });
                  }}
                  sx={{ width: "100%" }}
                  // variant="standard"
                  size="small"
                  placeholder="Search by Package Name"
                  InputProps={{
                    startAdornment: <Search />,
                  }}
                />
                <CustomSelect
                  options={menuItems}
                  placeholder="Package Type"
                  onChange={(e) => {
                    setPackageType(e.target.value);
                  }}
                  value={packageType}
                />
                <CustomSelect
                  options={statusOptions}
                  placeholder="Status"
                  onChange={(e) => {
                    setQuery({ ...query, status: e.target.value });
                  }}
                  value={query.status}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    if (paginationMain.page > 0) {
                      setPaginationMain({ ...paginationMain, page: 0 });
                    } else {
                      fetchPackages();
                    }
                  }}
                  // disabled={!packageType}
                  sx={{
                    backgroundColor: "#202529",
                    color: "#FFFFFF !important",
                    fontSize: "12px",
                    minWidth: "80px",
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: "#F24822",
                      borderColor: "#0062cc",
                      boxShadow: "none",
                    },
                  }}
                >
                  Search
                </Button>
              </Box>

              <Box
                sx={{
                  width: "40%",
                  display: "inline-flex",
                }}
              >
                <Button
                  onClick={handleAddNewPackage}
                  sx={{
                    backgroundColor: "#202529",
                    color: "#FFFFFF !important",
                    fontSize: "12px",
                    minWidth: "160px",
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: "#F24822",
                      borderColor: "#0062cc",
                      boxShadow: "none",
                    },
                  }}
                >
                  Add new package
                </Button>
              </Box>
            </Grid>
            {(loading ? ( // Show loader if loading is true
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
            ) : (
            <MainPackageTable
              data={packages}
              count={paginationMain.totalCount}
              rowPerPage={paginationMain.limit}
              page={paginationMain.page}
              isLoading={loading}
              handlePageChange={handleChangePageMain}
              handleRowsPerPageChange={handleChangeRowsPerPageMain}
              handleDeactivatePackage={handleDeactivatePackage}
              type={type}
            />
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PackageManagementList;
