import React, { Component } from "react";
import ReactDOM from "react-dom";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { capitalizeFirstLetter } from "../helper/util";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <label style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ fontSize: '14px' }}>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />{" "}
            {props.value}
          </div>
          <span style={{ fontSize: '12px', color: '#888', marginLeft: 'auto' }}>{props.data.sublabel}</span>
        </label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => {
  return (
    <components.MultiValue {...props}>
      <span>{capitalizeFirstLetter(props.data.label)}</span>
    </components.MultiValue>
  );
};

export default class Example extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: props.optionSelected || null
    };
  }

  componentDidUpdate(prevProps) {
    // Check if the optionSelected prop has changed
    if (prevProps.optionSelected !== this.props.optionSelected) {
      this.setState({
        optionSelected: this.props.optionSelected
      });
    }
  }

  handleChange = (selected) => {
    const { onOptionChange } = this.props;

    this.setState({
      optionSelected: selected
    });

    // Call the onOptionChange function with the selected values
    if (onOptionChange) {
      onOptionChange(selected);
    }
  };

  render() {
    const { colourOptions } = this.props;

    return (
      <span
        className="d-inline-block"
        data-toggle="popover"
        data-trigger="focus"
        data-content="Please selecet account(s)"
      >
        <ReactSelect
          options={colourOptions}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
            MultiValue
          }}
          onChange={this.handleChange}
          allowSelectAll={true}
          value={this.state.optionSelected}
        />
      </span>
    );
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Example />, rootElement);
