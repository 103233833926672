import React from "react";
import Grid from "@mui/material/Grid";
import TabPanel from "../Components/TabPanel";
import ChannelSetupList from "./ChannelSetupList";

const ChannelSetup = () => {
  const [value, setValue] = React.useState('channel-setup');
  return (
    <div className='inner-wrapper'>
      <Grid container>
        <Grid item xs={12}>
          <TabPanel value={value} index="channel-setup">
            <ChannelSetupList />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChannelSetup;
